//drawer
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

//dialog
export const SET_DIALOG = 'SET_DIALOG';

//menu
export const SET_MENU = 'SET_MENU';

//links
export const SET_LINKS = 'SET_LINKS';
export const LINKS_UPDATE_REQUEST = 'LINKS_UPDATE_REQUEST'
export const LINKS_UPDATE_DONE = 'LINKS_UPDATE_DONE';

//import
export const SET_IMPORT_FILES = 'SET_IMPORT_FILES';
export const IMPORT_FILES_UPDATE_REQUEST = 'IMPORT_FILES_UPDATE_REQUEST'
export const IMPORT_FILES_UPDATE_DONE = 'IMPORT_FILES_UPDATE_DONE';
export const SET_IMPORT_FILE_REQUESTED = 'SET_IMPORT_FILE_REQUESTED';

//importStatus
export const SET_IMPORT_STATUS = 'SET_IMPORT_STATUS';

//snack
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

//schemas
export const SET_SCHEMAS = 'SET_SCHEMAS';
export const SET_EDITING = 'SET_EDITING';
export const REQUEST_DEFINITION = 'REQUEST_DEFINITION';
export const REQUEST_DEFINITION_ERROR = 'REQUEST_DEFINITION_ERROR';
export const REQUEST_DEFINITION_SUCCESS = 'REQUEST_DEFINITION_SUCCESS';

//export
export const EXPORT_REQUEST = 'EXPORT_REQUEST';
export const EXPORT_REQUEST_SUCCESSFULL = 'EXPORT_REQUEST_SUCCESSFULL';
export const EXPORT_REQUEST_ERROR = 'EXPORT_REQUEST_ERROR';

//exportList
export const SET_FILES_LIST = 'SET_FILES_LIST';
export const LOAD_FILES_LIST_ERROR = 'LOAD_FILES_LIST_ERROR';
export const INIT_FILES_LIST = 'INIT_FILES_LIST';

//title
export const SET_TITLE = 'SET_TITLE';

//reports
export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

//calcMeasure
export const OPEN_EDITOR = 'SHOW_EDITOR';
export const CLOSE_EDITOR = 'CLOSE_EDITOR';

//common
export const SET_PROCESS_STATUS = 'SET_PROCESS_STATUS';

//market
export const SET_MARKET_LIST = 'SET_MARKET_LIST';
export const SET_MARKET_TAG_LIST = 'SET_MARKET_TAG_LIST';
export const LOAD_MARKET_LIST_ERROR = 'LOAD_MARKET_LIST_ERROR';
export const MARKET_REQUEST_DEFINITION = 'MARKET_REQUEST_DEFINITION';
export const MARKET_REQUEST_DEFINITION_ERROR = 'MARKET_REQUEST_DEFINITION_ERROR';
export const SET_MARKET_EDITING = 'SET_MARKET_EDITING';

//businessUnit
export const SET_BUSINESS_UNIT_LIST = 'SET_BUSINESS_UNIT_LIST';
export const LOAD_BUSINESS_UNIT_LIST_ERROR = 'LOAD_BUSINESS_UNIT_LIST_ERROR';
export const BUSINESS_UNIT_REQUEST_DEFINITION = 'BUSINESS_UNIT_REQUEST_DEFINITION';
export const BUSINESS_UNIT_REQUEST_DEFINITION_ERROR = 'BUSINESS_UNIT_REQUEST_DEFINITION_ERROR';
export const SET_BUSINESS_UNIT_EDITING = 'SET_BUSINESS_UNIT_EDITING';

//group
export const SET_GROUP_LIST = 'SET_GROUP_LIST';
export const LOAD_GROUP_LIST_ERROR = 'LOAD_GROUP_LIST_ERROR';
export const GROUP_REQUEST_DEFINITION = 'GROUP_REQUEST_DEFINITION';
export const GROUP_REQUEST_DEFINITION_ERROR = 'GROUP_REQUEST_DEFINITION_ERROR';
export const SET_GROUP_EDITING = 'SET_GROUP_EDITING';

//globalConfig
export const SET_GLOBAL_CONFIG = 'SET_GLOBAL_CONFIG';
export const GLOBAL_CONFIG_LOAD_ERR = 'GLOBAL_CONFIG_LOAD_ERR';

//numberFormatEditor
export const OPEN_NUMBER_FORMAT_EDITOR = 'OPEN_NUMBER_FORMAT_EDITOR';
export const CLOSE_NUMBER_FORMAT_EDITOR = 'CLOSE_NUMBER_FORMAT_EDITOR';

//products
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ERROR_LOADING_PRODUCTS = 'ERROR_LOADING_PRODUCTS';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';

//saikuAnalysis
export const SET_SAIKU_FILES = 'SET_SAIKU_FILES';

//yesno
export const ASK_YES_NO = 'ASK_YES_NO';
export const CLOSE_YES_NO = 'CLOSE_YES_NO';

//countrychooser
export const ASK_COUNTRY = 'ASK_COUNTRY';
export const CLOSE_COUNTRY_CHOOSER = 'CLOSE_COUNTRY_CHOOSER';

//rankings
export const GET_RANKINGS_REQUEST = 'GET_RANKINGS_REQUEST';
export const GET_RANKINGS_SUCCESS = 'GET_RANKINGS_SUCCESS';
export const GET_RANKINGS_ERROR = 'GET_RANKINGS_ERROR';
export const SET_RANKING_PARAMS = 'SET_RANKING_PARAMS';
export const SET_RANKING_INIT_PARAMS = 'SET_RANKING_INIT_PARAMS';

//locations
export const SET_LOCATION_LIST = 'SET_LOCATION_LIST';
export const SET_LOCATION_LOADING = 'SET_LOCATION_LOADING';
export const LOAD_LOCATION_LIST_ERROR = 'LOAD_LOCATION_LIST_ERROR';

//capacities
export const SET_CAPACITY_LIST = 'SET_CAPACITY_LIST';
export const SET_CAPACITY_LOADING = 'SET_CAPACITY_LOADING';
export const LOAD_CAPACITY_LIST_ERROR = 'LOAD_CAPACITY_LIST_ERROR';

// performance_month_year
export const SET_PERFORMANCE_MONTH_YEAR = 'SET_PERFORMANCE_MONTH_YEAR';
export const LOAD_PERFORMANCE_MONTH_YEAR_ERROR = 'LOAD_PERFORMANCE_MONTH_YEAR_ERROR';

// analysis_month_year
export const SET_ANALYSIS_MONTH_YEAR = 'SET_ANALYSIS_MONTH_YEAR';
export const LOAD_ANALYSIS_MONTH_YEAR_ERROR = 'LOAD_ANALYSIS_MONTH_YEAR_ERROR';

//sections
export const SET_SECTION_LIST = 'SET_SECTION_LIST';
export const SET_SECTION_LOADING = 'SET_SECTION_LOADING';
export const LOAD_SECTION_LIST_ERROR = 'LOAD_SECTION_LIST_ERROR';

//routes
export const SET_ROUTE_LIST = 'SET_ROUTE_LIST';
export const SET_ROUTE_LOADING = 'SET_ROUTE_LOADING';
export const LOAD_ROUTE_LIST_ERROR = 'LOAD_ROUTE_LIST_ERROR';

//yield_curve
export const YIELD_CURVE_INIT = 'YIELD_CURVE_INIT';
export const GET_YIELD_CURVE_REQUEST = 'GET_YIELD_CURVE_REQUEST';
export const GET_YIELD_CURVE_SUCCESS = 'GET_YIELD_CURVE_SUCCESS';
export const GET_YIELD_CURVE_ERROR = 'GET_YIELD_CURVE_ERROR';

//analysis GET
export const GET_ANALYSIS_REQUEST = 'GET_ANALYSIS_REQUEST';
export const GET_ANALYSIS_ERROR = 'GET_ANALYSIS_ERROR';
export const GET_ANALYSIS_SUCCESS = 'GET_ANALYSIS_SUCCESS';

//analysis POST
export const POST_ANALYSIS_REQUEST = 'POST_ANALYSIS_REQUEST';
export const POST_ANALYSIS_SUCCESS = 'POST_ANALYSIS_SUCCESS';
export const POST_ANALYSIS_ERROR = 'POST_ANALYSIS_ERROR';

//performance GET
export const GET_PERFORMANCE_REQUEST = 'GET_PERFORMANCE_REQUEST';
export const GET_PERFORMANCE_ERROR = 'GET_PERFORMANCE_ERROR';
export const GET_PERFORMANCE_SUCCESS = 'GET_PERFORMANCE_SUCCESS';

//hostile_zone GET
export const GET_HOSTILE_ZONE_REQUEST = 'GET_HOSTILE_ZONE_REQUEST';
export const GET_HOSTILE_ZONE_ERROR = 'GET_HOSTILE_ZONE_ERROR';
export const GET_HOSTILE_ZONE_SUCCESS = 'GET_HOSTILE_ZONE_SUCCESS';

//hostile_zone POST
export const POST_HOSTILE_ZONE_REQUEST = 'POST_HOSTILE_ZONE_REQUEST';
export const POST_HOSTILE_ZONE_SUCCESS = 'POST_HOSTILE_ZONE_SUCCESS';
export const POST_HOSTILE_ZONE_ERROR = 'POST_HOSTILE_ZONE_ERROR';

//hostile_zone DELETE
export const DELETE_HOSTILE_ZONE_REQUEST = 'DELETE_HOSTILE_ZONE_REQUEST';
export const DELETE_HOSTILE_ZONE_SUCCESS = 'DELETE_HOSTILE_ZONE_SUCCESS';
export const DELETE_HOSTILE_ZONE_ERROR = 'DELETE_HOSTILE_ZONE_ERROR';