import styles from './Performance.styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, withStyles, Card, Typography } from '@material-ui/core';
import PerformanceList from '../PerformanceList/PerformanceList';
import { getPerformance } from '../../actions/performance';
import { setRankingParams } from '../../actions/rankings';
import { setDialog } from '../../actions/dialog';
import BaseFormDialog from '../BaseFormDialog/BaseFormDialog';
import Chart from '../Chart/Chart';
import {
  TOTAL_PROFIT_REAL_IA,
  TOTAL_PROFIT_REAL_ANT,
  QTD_TOTAL_CHANGES,
  QTD_TOTAL_CHANGES_BY_LOCATION,
} from '../../constants';
import Header from '../Header/Header';
import { formatMoney } from '../../utils/misc';
import PlusIcon from 'react-feather/dist/icons/plus-circle';

const defaultPadding = '1.2%';

class Performance extends Component {
  state = {
    individualUseData: [],
  };

  componentWillMount() {
    let query;
    const { params } = this.props.rankings;
    const { meta } = this.props.performance;

    if (meta) return;

    query = this.getQuery(params);
    this.props.getPerformance(query);
  }

  getQuery = params => {
    let match = {};
    let query = [];

    let sections = [];
    let performanceMonthYears = [];

    if (params.locationId) {
      match.locationId = params.locationId;
    }

    if (params.section && params.section.length) {
      sections = params.section.map(item => item.value);

      match.sectionId = { $in: sections };
    }

    if (params.performanceMonthYear && params.performanceMonthYear.length) {
      performanceMonthYears = params.performanceMonthYear.map(item => item.value);

      match.monthYear = { $in: performanceMonthYears };
    }

    query.push({ $match: match });

    return query;
  };

  onSubmitAnalysis = params => {
    const query = this.getQuery(params);

    this.props.setRankingParams(params);
    this.props.getPerformance(query);
    this.props.setDialog({ field: 'isPerformanceForm', open: false });
    this.setState({ individualUseData: [] });
  };

  onClickNewAnalysis = (action, event) => {
    this.props.setDialog({ field: action, open: true });
  };

  getTotalizers = meta => {
    let totalizers = [];

    if (meta) {
      totalizers.push({
        label: 'Mês/Ano',
        field: 'monthYearReference',
        from: 'meta',
      });
      totalizers.push({
        label: 'Localidade',
        field: 'location',
        from: 'meta',
      });
      totalizers.push({
        label: 'Setores',
        field: 'section',
        from: 'meta',
      });
    }

    return totalizers;
  };

  getTotalBilling = data => {
    return `R$ ${formatMoney(data.total_billing)}`;
  };

  getTotalChanges = data => {
    return data.total_changes;
  };

  getTotalProfit = data => {
    return `R$ ${formatMoney(data.total_profit)}`;
  };

  getCards = data => (
    <Grid
      container
      item
      style={{
        textAlign: 'center',
        color: '#2C4E77',
        gap: '24px',
        paddingRight: defaultPadding,
      }}
      xs={6}
    >
      <Grid item xs>
        <Card>
          <p style={{ fontWeight: '700', fontSize: 20 }}>{this.getTotalBilling(data)}</p>
          <p style={{ fontWeight: '400' }}>Total faturado</p>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <p style={{ fontWeight: '700', fontSize: 20 }}>{this.getTotalProfit(data)}</p>
          <p style={{ fontWeight: '400' }}>Total ganho</p>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <p style={{ fontWeight: '700', fontSize: 20 }}>{this.getTotalChanges(data)}</p>
          <p style={{ fontWeight: '400' }}>Total de trocas</p>
        </Card>
      </Grid>
    </Grid>
  );

  render() {
    const { params } = this.props.rankings;
    const { classes } = this.props;
    const { loading, meta, data, error } = this.props.performance;
    const errorComponent = (
      <Typography className={classes.noDataMsg}>
        Há muitos dados para buscar. Tente adicionar mais filtros à sua pesquisa.
      </Typography>
    );
    const noDataComponent = (
      <Typography className={classes.noDataMsg}>
        Sem dados disponíveis para sua pesquisa. Em caso de dúvidas, contate a Oncase.
      </Typography>
    );
    let availableMeta = meta || params;

    if (!meta && !params.locationId) {
      availableMeta = {
        ...availableMeta,
        location: 'Todas',
      };
    }
    if (!meta && (!params.section || (params.section !== undefined && params.section.length <= 0))) {
      availableMeta = {
        ...availableMeta,
        section: 'Todos',
      };
    }
    if (
      !meta &&
      (!params.performanceMonthYear ||
        (params.performanceMonthYear !== undefined && params.performanceMonthYear.length <= 0))
    ) {
      availableMeta = {
        ...availableMeta,
        monthYearReference: 'Todos',
      };
    } else {
      availableMeta = {
        ...availableMeta,
        monthYearReference: params.performanceMonthYear.sort((a, b) => a.value - b.value),
      };
    }
    const actions = [
      {
        name: 'Nova Análise',
        icon: <PlusIcon size={20} />,
        handle: this.onClickNewAnalysis.bind(this, 'isPerformanceForm'),
      },
    ];
    const totalizers = this.getTotalizers(meta);

    return (
      <>
        <Header
          loading={loading}
          title="Monitoramento de performance das trocas"
          data={data}
          meta={[availableMeta]}
          location={availableMeta.location}
          section={availableMeta.section}
          actions={actions}
          monthYearReference={availableMeta.monthYearReference}
          totalizers={totalizers}
        />

        {!loading ? (
          !error ? (
            !data || !data.length ? (
              noDataComponent
            ) : (
              <>
                <Grid container style={{ paddingTop: defaultPadding }}>
                  {data.at(-1) ? this.getCards(data.at(-1)) : null}
                </Grid>

                <Grid container direction="row" classes={{ container: classes.container }}>
                  <Grid item xs={6} style={{ paddingRight: defaultPadding }}>
                    <Chart params={availableMeta} data={data} viewMode={TOTAL_PROFIT_REAL_IA} />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: defaultPadding }}>
                    <Chart params={availableMeta} data={data} viewMode={TOTAL_PROFIT_REAL_ANT} />
                  </Grid>
                </Grid>
                <Grid container direction="row" classes={{ container: classes.container }}>
                  <Grid item xs={12} className={classes.list}>
                    <PerformanceList />
                  </Grid>
                </Grid>
                <Grid container direction="row" classes={{ container: classes.container }}>
                  <Grid item xs={6} style={{ paddingRight: defaultPadding }}>
                    <Chart params={availableMeta} data={data} viewMode={QTD_TOTAL_CHANGES} />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: defaultPadding }}>
                    <Chart params={availableMeta} data={data} viewMode={QTD_TOTAL_CHANGES_BY_LOCATION} />
                  </Grid>
                </Grid>
              </>
            )
          ) : (
            errorComponent
          )
        ) : (
          <CircularProgress className={classes.progress} />
        )}

        <BaseFormDialog onSubmit={params => this.onSubmitAnalysis(params)} />
      </>
    );
  }
}

const mapStateToProps = ({ performance, rankings }) => ({
  performance,
  rankings,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getPerformance, setDialog, setRankingParams })
)(Performance);
