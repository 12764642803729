import { combineReducers } from 'redux';
import drawer from './drawer';
import links from './links';
import snack from './snack';
import title from './title';
import yesno from './yesno';
import rankings from './rankings';
import locations from './locations';
import capacities from './capacities';
import sections from './sections';
import yield_curve from './yield_curve';
import dialog from './dialog';
import performance_month_year from './performance_month_year';
import analysis_month_year from './analysis_month_year';
import analysis from './analysis';
import menu from './menu';
import performance from './performance';
import routes from './routes';
import hostile_zone from './hostile_zone';

const rootReducer = combineReducers({
  drawer, links, snack, title, yesno, rankings, locations, capacities,
  sections, yield_curve, dialog, performance_month_year,
  analysis, menu, analysis_month_year, performance, routes, hostile_zone
});

export default rootReducer;
