import { SET_LOCATION_LIST, SET_LOCATION_LOADING, LOAD_LOCATION_LIST_ERROR } from '../constants/actions';
import { doGet } from '../utils/http_functions';

export function getLocations() {
  return dispatch => {
    dispatch(setLocationLoading());
    doGet(`${process.env.REACT_APP_BACK_URL}/api/locations`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(setLocationList(json));
            })
            .catch(e => {
              dispatch(loadLocationListError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(loadLocationListError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(loadLocationListError(`Error list locations: ${e.message}`));
      });
  };
}

function setLocationLoading() {
  return dispatch => {
    dispatch({
      type: SET_LOCATION_LOADING,
      payload: { loading: true },
    });
  };
}

function setLocationList(locations) {
  return dispatch => {
    dispatch({
      type: SET_LOCATION_LIST,
      payload: locations,
    });
  };
}

function loadLocationListError() {
  return dispatch => {
    dispatch({
      type: LOAD_LOCATION_LIST_ERROR,
      payload: { errorLoading: true },
    });
  };
}
