import {
  GET_HOSTILE_ZONE_REQUEST,
  GET_HOSTILE_ZONE_ERROR,
  GET_HOSTILE_ZONE_SUCCESS,
  POST_HOSTILE_ZONE_REQUEST,
  POST_HOSTILE_ZONE_SUCCESS,
  POST_HOSTILE_ZONE_ERROR,
  DELETE_HOSTILE_ZONE_REQUEST,
  DELETE_HOSTILE_ZONE_SUCCESS,
  DELETE_HOSTILE_ZONE_ERROR,
} from '../constants/actions';
import { doPost, doFormPost } from '../utils/http_functions';
import { showMessage } from '../actions/snack';

export const DEFAULT_FILTER = [{ $sort: { location: 1 } }];

export function getQueryHostileZone(params) {
  let match = {};
  let query = [{ $sort: { location: 1 } }];

  if (params.locationId) {
    match.locationId = params.locationId;
  }

  query.push({ $match: match });

  return query;
}

export function getHostileZone(query, callback) {
  return dispatch => {
    dispatch(getHostileZoneRequest());
    doPost(`${process.env.REACT_APP_BACK_URL}/api/hostile_zone`, {
      paramFILTER: query,
    })
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(getHostileZoneSuccess(json));
              if (callback) callback(json);
            })
            .catch(e => {
              dispatch(getHostileZoneError(`Erro ao listar zona hostil: ${e.message}`));
            });
        } else {
          dispatch(getHostileZoneError(`Erro ao listar zona hostil`, res));
        }
      })
      .catch(e => {
        dispatch(getHostileZoneError(`Erro ao listar zona hostil: ${e.message}`));
      });
  };
}

export function postHostileZone(data, defaultFilter) {
  return dispatch => {
    dispatch(postHostileZoneRequest());
    doPost(`${process.env.REACT_APP_BACK_URL}/api/hostile_zone_save`, {
      paramFILTER: data,
    })
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(postHostileZoneSuccess(json));
              dispatch(showMessage('Zona hostil salva com sucesso!'));
              dispatch(getHostileZone(defaultFilter));
            })
            .catch(e => {
              dispatch(postHostileZoneError(`Erro ao salvar zona hostil: ${e.message}`));
            });
        } else {
          dispatch(postHostileZoneError(`Erro ao salvar zona hostil`, res));
        }
      })
      .catch(e => {
        dispatch(postHostileZoneError(`Erro ao salvar zona hostil: ${e.message}`));
      });
  };
}

export function deleteHostileZone(data, defaultFilter) {
  return dispatch => {
    dispatch(deleteHostileZoneRequest());
    doFormPost(`${process.env.REACT_APP_BACK_URL}/api/hostile_zone_delete`, {
      paramFILTER: JSON.stringify(data),
    })
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(deleteHostileZoneSuccess(json));
              dispatch(showMessage('Zona hostil removida com sucesso!'));
              dispatch(getHostileZone(defaultFilter));
            })
            .catch(e => {
              dispatch(deleteHostileZoneError(`Erro ao remover zona hostil: ${e.message}`));
            });
        } else {
          dispatch(deleteHostileZoneError(`Erro ao remover zona hostil`, res));
        }
      })
      .catch(e => {
        dispatch(deleteHostileZoneError(`Erro ao remover zona hostil: ${e.message}`));
      });
  };
}

/**
 * GET dispatchers
 */

const getHostileZoneRequest = () => {
  return dispatch => {
    dispatch({
      type: GET_HOSTILE_ZONE_REQUEST,
    });
  };
};

const getHostileZoneSuccess = data => {
  return dispatch => {
    dispatch({
      type: GET_HOSTILE_ZONE_SUCCESS,
      payload: data,
    });
  };
};

const getHostileZoneError = () => {
  return dispatch => {
    dispatch({
      type: GET_HOSTILE_ZONE_ERROR,
      payload: { errorLoading: true },
    });
  };
};

/**
 * POST dispatchers
 */

const postHostileZoneRequest = () => {
  return dispatch => {
    dispatch({
      type: POST_HOSTILE_ZONE_REQUEST,
    });
  };
};

const postHostileZoneSuccess = data => {
  return dispatch => {
    dispatch({
      type: POST_HOSTILE_ZONE_SUCCESS,
    });
  };
};

const postHostileZoneError = () => {
  return dispatch => {
    dispatch({
      type: POST_HOSTILE_ZONE_ERROR,
    });
  };
};

/**
 * DELETE dispatchers
 */

const deleteHostileZoneRequest = () => {
  return dispatch => {
    dispatch({
      type: DELETE_HOSTILE_ZONE_REQUEST,
    });
  };
};

const deleteHostileZoneSuccess = data => {
  return dispatch => {
    dispatch({
      type: DELETE_HOSTILE_ZONE_SUCCESS,
    });
  };
};

const deleteHostileZoneError = () => {
  return dispatch => {
    dispatch({
      type: DELETE_HOSTILE_ZONE_ERROR,
    });
  };
};
