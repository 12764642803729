import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import * as constants from './constants/routes';
import Home from './components/Home/Home';
import NotFound from './components/NotFound/NotFound';
import Ranking from './components/Ranking/Ranking';
import Analysis from './components/Analysis/Analysis';
import HostileZone from './components/HostileZone/HostileZone';
import Performance from './components/Performance/Performance';

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={constants.HOME} component={Home} />
        <Route path={constants.RANKING} component={Ranking} />
        <Route path={constants.ANALYSIS} component={Analysis} />
        <Route path={constants.HOSTILE_ZONE} component={HostileZone} />
        <Route path={constants.PERFORMANCE} component={Performance} />
        <Redirect exact from={constants.ROOT} to={constants.HOME} />
        <Route component={NotFound} />
      </Switch>
    );
  }

}


export default Routes;
