import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  padding-bottom: 0;
`;

export const OptionsCardText = styled.div`
  width: 12vw;
  text-align: center;
  font-size: 1.1rem;
`;

export const ChangeCardContainer = styled.div`
  background: rgba(19, 142, 217, 0.04);
  border: 2px solid #5BBEFE;
  box-sizing: border-box;
  border-radius: 5px;
  color: #5BBEFE;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
  width: 20vw;
  transition: 0.4s ease;

  &:hover {
    color: #FFF;
    border: none;
    cursor: pointer;
    background: #5BBEFE;
    border: 2px solid #5BBEFE;
  }  
`;

export const MonitoringCardContainer = styled.div`
  background: #F7FFFF;
  border: 2px solid #4DD0D0;
  box-sizing: border-box;
  border-radius: 5px;
  color: #4DD0D0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
  width: 20vw;
  transition: 0.4s ease;
  margin-left: 1vw;

  &:hover {
    color: #FFF;
    border: none;
    cursor: pointer;
    background: #4DD0D0;
    border: 2px solid #4DD0D0;
  }  
`;

export const AnalysisCardContainer = styled.div`
  background: #E9EDF6;
  border: 2px solid #7B84F3;
  box-sizing: border-box;
  border-radius: 5px;
  color: #7B84F3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
  width: 20vw;
  transition: 0.4s ease;
  margin-left: 1vw;

  &:hover {
    color: #FFF;
    border: none;
    cursor: pointer;
    background: #7B84F3;
    border: 2px solid #7B84F3;
  }  
`;

export const HostileZoneCardContainer = styled.div`
  background: #E9EDF6;
  border: 2px solid #8D6E63;
  box-sizing: border-box;
  border-radius: 5px;
  color: #8D6E63;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
  width: 20vw;
  transition: 0.4s ease;
  margin-left: 1vw;

  &:hover {
    color: #FFF;
    border: none;
    cursor: pointer;
    background: #8D6E63;
    border: 2px solid #8D6E63;
  }  
`;

export const OptionsTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
  flex-direction: column;
`;

export const OptionsText = styled.div`
  font-size: 1.1rem;
  line-height: 20px;
  letter-spacing: 0.09em;
  color: #30619D;
  font-weight: bold;
`;

export const Version = styled.div`
  font-size: .7rem;
  padding-top: 2vh;
  color: #30619D;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1rem;
  color: #30619D;
  padding-top: 2vh;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;