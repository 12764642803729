import { Auth } from 'aws-amplify';
import {
  LDAP_GROUPS,
  LDAP_USERNAME,
  LDAP_PASSWORD,
  LDAP_IS_MUST_SELECT_GROUP,
  LDAP_GROUP_SELECTED,
} from '../constants';
import { doPost } from '../utils/http_functions';

const setGroups = (response, username, password, group) => {
  if (group) {
    localStorage.removeItem(LDAP_GROUPS);
    localStorage.removeItem(LDAP_USERNAME);
    localStorage.removeItem(LDAP_PASSWORD);
    localStorage.removeItem(LDAP_IS_MUST_SELECT_GROUP);
    localStorage.setItem(LDAP_GROUP_SELECTED, group);

    return;
  }

  if (
    response.data.data &&
    response.data.data.allowed_groups &&
    typeof Array.isArray(response.data.data.allowed_groups) &&
    response.data.data.allowed_groups.length > 0
  ) {
    localStorage.setItem(LDAP_GROUPS, JSON.stringify(response.data.data.allowed_groups));
  }

  localStorage.removeItem(LDAP_GROUP_SELECTED);
  localStorage.setItem(LDAP_USERNAME, username);
  localStorage.setItem(LDAP_PASSWORD, password);
  localStorage.setItem(LDAP_IS_MUST_SELECT_GROUP, 'true');
};

export const login = async (username, password, group) => {
  let response = null;
  const genericError = 'Erro ao realizar login. Por favor, entre em contato com o admnistrador do sistema.';

  try {
    let isLdap = false;
    
    // if (username && username.indexOf('\\') !== -1) {
    //   isLdap = true;
    // }

    if (!isLdap) return Auth.signIn({ username, password });

    response = await doPost(`${process.env.REACT_APP_BACK_URL}/api/login`, { username, password, group });

    if (response.data.status === 'error') {
      if (response.data.msg) {
        throw response.data.msg;
      }

      throw genericError;
    }

    setGroups(response, username, password, group);

    return Auth.signIn({ username, password });
  } catch (err) {
    throw err;
  }
};
