import {
  SET_TITLE
} from '../constants/actions';
import { appBase } from '../actions/title';

export default function(state = {title: appBase }, action) {
  switch(action.type) {
      case SET_TITLE :
        return { title: action.payload };
      default :
        return state;
  }
}