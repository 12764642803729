import { defaultCyan, defaultBlue, moneySign, fontFamily } from '../App/theme';
import styled from 'styled-components';

export default (theme) => ({
  list: {
    backgroundColor: '#fff',
    padding: '1rem',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)'
  },
  chart: {
    backgroundColor: '#fff',
    padding: '1rem',
    height: '41.6rem',
    paddingLeft: '2rem',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)'
  },
  textFieldRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  textFieldInput: {
    borderRadius: 29,
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    height: 24,
    width: 'calc(100% - 24px)',
  },
  container: {
    marginTop: '2rem'
  },
  containerButtonRanking: {
    marginTop: '2.5rem'
  },
  labelService: {
    fontWeight: 600,
    fontSize: '1rem',
    paddingBottom: '0.5rem'
  },
  labelRanking: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  labelLocation: {
    fontWeight: 600,
    fontSize: '1rem',
    paddingBottom: '0.5rem'
  },
  rankingButton: {
    borderRadius: '2.5px',
    backgroundColor: defaultBlue,
    color: 'white',
    textTransform: 'none',
    "&:hover": {
      backgroundColor: defaultBlue,
      color: 'white'
    }
  },
  textFieldFormLabel: {
    fontSize: 18
  },
  formTitle: {
    padding: '1rem',
    fontSize: '1.5rem',
    fontWeight: '500'
  },
  formSubTitle: {
    padding: '0 1rem 0 1rem',
  },
  alignButton: {
    '& > span > svg': {
      marginRight: '1rem'
    }
  },
  gridButton: {
    margin: 'auto'
  },
  disabled: { '&  input': { background: '#fafafa !important', border: 'none' } },
  locationInput: {
    padding: 12,
    fontSize: 14,
    width: '100%'
  },
  investiment: {
    ...moneySign
  },
  containerInvestiment: {
    backgroundColor: '#fff',
    width: '90%',
    maxWidth: '500px',
    padding: '1rem',
    paddingTop: 0,
    paddingBottom: 0
  },
  rootNumberInput: {
    width: '3rem',
    marginLeft: '.2rem',
    backgroundColor: '#FFF'
  },
  numberInput: {
    padding: 8,
    paddingLeft: 10,
    fontSize: 12
  },
  investimentInput: {
    padding: 8,
    width: '80%',
    paddingRight: 0,
    fontSize: '1.2rem',
    color: 'rgba(146, 146, 146, 0.95)',
    fontWeight: 'bold',
    border: 'solid 0.3px #cbcbcb',
    '&:hover, &:focus': {
      borderColor: '#cbcbcb'
    }
  },
  chip: {
    padding: '.5rem'
  },
  activeChip: {
    color: 'white',
    backgroundColor: `${defaultCyan} !important`
  },
  inactiveChip: {
    backgroundColor: 'transparent !important',
    border: '.8px solid #363753',
  },
  sliderRoot: {
    // zIndex: 0
  },
  sliderTrackBefore: {
    backgroundColor: defaultCyan
  },
  sliderThumb: {
    backgroundColor: defaultCyan
  },
  cPointer: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  serviceType: {
    fontWeight: 600,
    fontSize: '1.2rem'
  },
  investimentLabel: {
    fontWeight: 500,
    fontSize: '1rem'
  },
  investimentValue: {
    fontWeight: 500,
    fontSize: '1.4rem',
    color: 'rgba(146, 146, 146, 0.95)'
  },
  downloadScoreContent: {
    textAlign: 'right',
    display: 'contents'
  },
  progress: {
    marginLeft: '50%',
    marginTop: '20%',
    color: defaultCyan
  },
  chartPointHover: {
    background: 'black',
    fontSize: 12,
    fontFamily: fontFamily
  },
  chartLabelX: {
    fontSize: 12,
    textAlign: 'center'
  },
  fieldsetQtdRanking: {
    borderColor: '#000 !important'
  }
});

export const TableActionsText = styled.div`
  font-size: .8rem;
  font-weight: 600;
  color: #363753;
`;