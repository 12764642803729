import { defaultCyan, moneySign, defaultBlue } from '../App/theme';

export default () => ({
  form: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '500px',
    padding: '1rem',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)'
  },
  formDialog: {
    backgroundColor: '#fff',
  },
  container: {
    marginTop: '2rem',
    width: '90%'
  },
  containerButtonRanking: {
    marginTop: '2.5rem'
  },
  defaultLabel: {
    fontWeight: 600,
    fontSize: '1rem',
    paddingBottom: '0.5rem'
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '.5rem',
    color: '#363753',
    paddingBottom: '2vh'
  },
  title: {
    marginLeft: '.5rem',
    fontWeight: 600,
    fontSize: '1.3rem'
  },
  labelBudget: {
    fontWeight: 600,
    fontSize: '1rem',
    paddingTop: '.5rem'
  },
  labelLocation: {
    fontWeight: 600,
    fontSize: '1rem',
    paddingBottom: '0.5rem'
  },
  rankingButton: {
    fontWeight: 600,
    borderRadius: '2.5px',
    backgroundColor: defaultBlue,
    color: 'white',
    fontSize: '1rem',
    padding: '.8rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    "&:hover": {
      textDecoration: 'underline',
      backgroundColor: defaultBlue,
      color: 'white'
    }
  },
  gridButton: {
    margin: 'auto'
  },
  cancelButton: {
    fontSize: '1rem',
    textTransform: 'none',

    "&:hover": {
      background: 'transparent',
      textDecoration: 'underline'
    }
  },
  investiment: {
    ...moneySign
  },
  containerInvestiment: {
    backgroundColor: '#fff',
    width: '90%',
    maxWidth: '500px',
    padding: '1rem',
    paddingTop: 0,
    paddingBottom: 0
  },
  numberInput: {
    padding: 12,
    fontSize: 14
  },
  numberInputFocused: {
    border: '1px solid red'
  },
  investimentInput: {
    padding: 8,
    width: '80%',
    paddingRight: 0,
    fontSize: '1.2rem',
    color: 'rgba(146, 146, 146, 0.95)',
    fontWeight: 'bold',
    border: 'solid 0.3px #cbcbcb',
    '&:hover, &:focus': {
      borderColor: '#cbcbcb'
    }
  },
  chip: {
    borderRadius: 'none',
    padding: '.5rem'
  },
  activeChip: {
    color: 'white',
    backgroundColor: `${defaultCyan} !important`
  },
  inactiveChip: {
    backgroundColor: 'transparent !important',
    border: '.8px solid #363753',
  },
  sliderRoot: {
    zIndex: 0
  },
  sliderTrackBefore: {
    backgroundColor: defaultCyan
  },
  sliderThumb: {
    backgroundColor: defaultCyan
  },
  containerLogo: {
    width: '100%',
    maxWidth: '400px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo: {
    width: '100%'
  },
  logoDescription: {
    fontSize: '1.2rem',
    color: '#363753'
  }
});