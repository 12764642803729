import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component, Fragment } from 'react';
import styles from './HostileZoneList.style';
import {
  withStyles, TableHead, TableRow,
  TableCell, Table, TableBody,
  TableFooter, TablePagination, TableSortLabel
} from '@material-ui/core';
import _orderBy from 'lodash/orderBy';
import * as moment from 'moment';
import MoreIcon from 'react-feather/dist/icons/more-horizontal';
import MenuActions from '../MenuActions/MenuActions';
import { setMenu } from '../../actions/menu';
import EditIcon from 'react-feather/dist/icons/edit';
import ActivateIcon from 'react-feather/dist/icons/power';
import InactivateIcon from 'react-feather/dist/icons/eye-off';
import { setDialog } from '../../actions/dialog';
import { setRankingParams } from '../../actions/rankings';

class HostileZoneList extends Component {

  state = {
    page: 0,
    orderBy: 'location',
    order: 'asc',
    rowsPerPage: 10,
    currentList: [],
    paginatedList: []
  };

  componentDidMount() {
    const { data } = this.props.hostile_zone;

    this.updatePaginatedListAfterSlide(data);
  }

  updatePaginatedListAfterSlide(data) {
    const { page, rowsPerPage } = this.state;
    const paginatedList = data.slice(page, rowsPerPage);
    this.setState({ currentList: data, paginatedList });
  }

  initPagination(data) {
    const { rowsPerPage } = this.state;
    const paginatedList = data.slice(0, rowsPerPage);

    this.setState({ page: 0, paginatedList });
  }

  handleChangePage = (event, page) => {
    const { currentList, rowsPerPage } = this.state;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ page, paginatedList });
  };

  handleChangeRowsPerPage = event => {
    const { page, currentList } = this.state;
    const rowsPerPage = event.target.value;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ paginatedList, rowsPerPage });
  };

  handleRequestSort = (property) => {
    const { order, orderBy, currentList, page, rowsPerPage } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    const offset = page * rowsPerPage;

    const newCurrentList = _orderBy(currentList, property, newOrder);
    const newPaginatedList = newCurrentList.slice(offset, offset + rowsPerPage);

    this.setState({
      order: newOrder,
      orderBy: property,
      currentList: newCurrentList,
      paginatedList: newPaginatedList
    });
  }

  getSortLabel = (property, label) => {
    const { order, orderBy } = this.state;

    return (
      <TableSortLabel
        active={orderBy === property}
        direction={order}
        onClick={this.handleRequestSort.bind(this, property)}>
        {label}
      </TableSortLabel>
    )
  }

  getTableHeadRow() {
    const { classes } = this.props;

    return (
      <TableRow>
        <TableCell padding="none" className={classes.tableCell}>{this.getSortLabel('location', 'Localidade')}</TableCell>
        <TableCell padding="none" className={classes.tableCell}>{this.getSortLabel('createdAt', 'Criado Em')}</TableCell>
        <TableCell padding="none" className={classes.tableCell}>{this.getSortLabel('updatedAt', 'Atualizado Em')}</TableCell>
        <TableCell padding="none" className={classes.tableCell}>{this.getSortLabel('active', 'Ativo')}</TableCell>
        <TableCell padding="none" className={classes.tableCell}><div className={classes.actionsContainer}>Ações</div></TableCell>
      </TableRow>
    );
  }

  getTableRow(data) {
    const { classes } = this.props;

    return (
      data.map((row, i) => (
        <TableRow key={i} style={i % 2 ? { background: "#F9F9F9" } : { background: "white" }} className={classes.tableRow}>
          <TableCell padding="none" className={classes.tableRow}>{row.location}</TableCell>
          <TableCell padding="none" className={classes.tableRow}>{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
          <TableCell padding="none" className={classes.tableRow}>{moment(row.updatedAt).format('DD/MM/YYYY')}</TableCell>
          <TableCell padding="none" className={classes.tableRow}>{row.active ? 'Sim' : 'Não'}</TableCell>
          <TableCell padding="none" onClick={this.showMenuActions.bind(this, row)} className={classes.tableRow}>
            <div className={classes.actionsContainer}>
              <MoreIcon />
            </div>
          </TableCell>
        </TableRow>
      ))
    )
  }

  showMenuActions = (row, e) => {
    let payload = {
      anchorEl: e.currentTarget,
      meta: row,
      actions: [{
        type: 'edit',
        label: 'Editar',
        icon: <EditIcon size={16} />
      }]
    };

    if (row.active) {
      payload.actions.push({
        type: 'inactivate',
        label: 'Inativar',
        icon: <InactivateIcon size={16} />
      });
    } else {
      payload.actions.push({
        type: 'activate',
        label: 'Ativar',
        icon: <ActivateIcon size={16} />
      });


    }

    this.props.setMenu(payload);
  }

  onClickMenuAction = (res, data) => {

    if (res && res.type === 'edit') {
      this.props.setRankingParams(data);
      this.props.setDialog({ field: 'isHostileZoneForm', open: true, data });
    }

    if (res && res.type === 'activate') {
      this.props.onUpdateStatus({ ...data, active: true, is_edit: true });
    }

    if (res && res.type === 'inactivate') {
      this.props.onUpdateStatus({ ...data, active: false, is_edit: true });
    }
  }

  render() {
    const { classes } = this.props;
    const { page, rowsPerPage, paginatedList, currentList } = this.state;

    return (
      <Fragment>
        <Table>
          <TableHead>
            {this.getTableHeadRow()}
          </TableHead>
          <TableBody>
            {this.getTableRow(paginatedList)}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={currentList.length}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                labelRowsPerPage="Linhas por página:"
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                classes={{ caption: classes.paginationCaption, toolbar: classes.paginationToolbar, selectIcon: classes.paginationSelectIcon }}
              />
            </TableRow>

          </TableFooter>
        </Table>
        <MenuActions onClick={(res, meta) => this.onClickMenuAction(res, meta)} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ menu, hostile_zone }) => ({ menu, hostile_zone });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { setMenu, setDialog, setRankingParams })
)(HostileZoneList);