import {
  GET_RANKINGS_REQUEST,
  GET_RANKINGS_SUCCESS,
  GET_RANKINGS_ERROR,
  SET_RANKING_PARAMS,
  SET_RANKING_INIT_PARAMS,
} from '../constants/actions';
import { doGet } from '../utils/http_functions';
import { urlParam } from '../utils/misc';

export function getRankings(filter, callback) {
  return dispatch => {
    dispatch(getRankingRequest());
    doGet(`${process.env.REACT_APP_BACK_URL}/api/ranking?${urlParam(filter)}`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(getRankingSuccess(json));
              if (callback) callback(json);
            })
            .catch(e => {
              dispatch(getRankingsError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(getRankingsError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(getRankingsError(`Error list rankings: ${e.message}`));
      });
  };
}

function getRankingRequest() {
  return dispatch => {
    dispatch({
      type: GET_RANKINGS_REQUEST,
      payload: null,
    });
  };
}

export function getRankingSuccess(rankings) {
  return dispatch => {
    dispatch({
      type: GET_RANKINGS_SUCCESS,
      payload: rankings,
    });
  };
}

export function setRankingInitParams() {
  return dispatch => {
    dispatch({
      type: SET_RANKING_INIT_PARAMS,
      payload: {},
    });
  };
}

export function setRankingParams(params) {
  return dispatch => {
    dispatch({
      type: SET_RANKING_PARAMS,
      payload: params,
    });
  };
}

function getRankingsError(msg) {
  return dispatch => {
    dispatch({
      type: GET_RANKINGS_ERROR,
      payload: { errorLoading: true },
    });
  };
}
