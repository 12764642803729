import React from 'react';
import { connect } from 'react-redux';
import { closeMessage } from '../../actions/snack';
import { withSnackbar } from 'notistack';

class UserMessaage extends React.Component {

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.closeMessage();
  };

  state = {
    displayed: [],
  };

  storeDisplayed = (key) => {
    this.setState(({ displayed }) => ({
      displayed: [...displayed, key],
    }));
  };

  render() {
    const { notifications, enqueueSnackbar, closeMessage } = this.props;
    const { displayed } = this.state;
    notifications.forEach((notification) => {
      // setTimeout(() => {
        
        if (displayed.indexOf(notification.key) > -1) return;
        
        enqueueSnackbar(notification.message, notification.options);
        
        this.storeDisplayed(notification.key);
        
        closeMessage(notification.key);
      // }, 1);
    });

    return null;
  }
}


function mapStateToProps({ snack }) {
  return { notifications: snack.notifications };
}

export default connect(
  mapStateToProps,
  { closeMessage }
)(withSnackbar(UserMessaage));
