import * as moment from 'moment';
import styles from './HostileZone.styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, withStyles } from '@material-ui/core';
import HostileZoneList from '../HostileZoneList/HostileZoneList';
import { getHostileZone, postHostileZone, getQueryHostileZone, DEFAULT_FILTER } from '../../actions/hostile_zone';
import { setRankingInitParams } from '../../actions/rankings';
import { setDialog } from '../../actions/dialog';
import FilterIcon from 'react-feather/dist/icons/sliders';
import BaseFormDialog from '../BaseFormDialog/BaseFormDialog';
import PlusIcon from 'react-feather/dist/icons/plus-circle';
import Header from '../Header/Header';

class HostileZone extends Component {

  componentWillMount() {
    const query = DEFAULT_FILTER;

    this.props.getHostileZone(query);
  }

  componentWillUnmount() {
    this.props.setRankingInitParams();
  }

  handleFilter = () => {
    this.props.setDialog({ field: 'isHostileZoneFilterForm', open: true });
  }

  onFilter = (params) => {
    const query = getQueryHostileZone(params);

    this.props.getHostileZone(query);
  }

  onSave = (params) => {
    let newData = {};
    const dateNow = moment();
    const { data } = this.props.dialog;

    newData = {
      location: params.location,
      locationId: params.locationId,
      createdAt: dateNow.toISOString(),
      updatedAt: dateNow.toISOString(),
      route: params.route,
      section: params.section,
      active: params.active === undefined ? true : params.active
    }

    // From dialog
    if (params.is_edit && data && data.createdAt) {
      newData.createdAt = data.createdAt;
    }

    // From menu actions
    if (params.is_edit && params.createdAt) {
      newData.createdAt = params.createdAt;
    }

    this.props.postHostileZone(newData, DEFAULT_FILTER);
  }


  onSubmitDialog = (params) => {

    if (params.origin_dialog === 'isHostileZoneFilterForm') {
      return this.onFilter(params);
    }

    if (params.origin_dialog === 'isHostileZoneForm') {
      return this.onSave(params);
    }

    this.props.setDialog({ field: params.origin_dialog, open: false });
  }

  onClickHeaderAction = (action, event) => {
    this.props.setRankingInitParams();
    this.props.setDialog({ field: action, open: true });
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.props.hostile_zone;
    const actions = [{
      name: 'Nova Zona Hostil',
      icon: <PlusIcon size={20} />,
      handle: this.onClickHeaderAction.bind(this, 'isHostileZoneForm')
    }, {
      name: 'Filtros',
      icon: <FilterIcon size={20} />,
      handle: this.onClickHeaderAction.bind(this, 'isHostileZoneFilterForm')
    }];

    return (
      <div className={classes.mainContainer}>
        <Header
          loading={loading}
          title='Zonas Hostis'
          actions={actions} />

        {!loading ?
          <Grid container direction="row" classes={{ container: classes.container }}>
            <Grid item xs={12} className={classes.list}>

              <Grid item xs={12}>
                <HostileZoneList
                  onUpdateStatus={(item) => this.onSave(item)}
                  history={this.props.history} />
              </Grid>
            </Grid>
          </Grid>
          : <div><CircularProgress className={classes.progress} /></div>}
        <BaseFormDialog onSubmit={(params) => this.onSubmitDialog(params)} />
      </div>
    );
  }
}

const mapStateToProps = ({ hostile_zone, dialog }) => ({ hostile_zone, dialog });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getHostileZone, postHostileZone,
    setDialog, setRankingInitParams
  })
)(HostileZone);
