import { defaultCyan } from '../App/theme';

export default () => ({
  title: {
    fontWeight: 400,
    fontSize: '1.1rem',
  },
  container: {
    backgroundColor: '#fff',
    padding: '1rem',
    height: '41.6rem',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)'
  },
  chartPointHover: {
    background: 'black',
    fontSize: 12,
  },
  chartLabelX: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center'
  },
  progress: {
    marginLeft: '50%',
    color: defaultCyan
  },
  msgEmptyState: {
    fontSize: 12,
    textAlign: 'center'
  },
});