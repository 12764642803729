import { SET_LINKS, LINKS_UPDATE_REQUEST, LINKS_UPDATE_DONE } from '../constants/actions';

import { doGet } from '../utils/http_functions';

/* Dashboad listing
 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

export function loadDashboards(subdomain) {
  loadDashboardsRequested();
  return dispatch => {
    doGet(`/api/${subdomain}/links`)
      .then(res => {
        res
          .json()
          .then(json => {
            dispatch(loadDashboardsSuccess());
            dispatch({
              type: SET_LINKS,
              payload: json,
            });
          })
          .catch(e => {
            dispatch(loadDashboardsError(`Error loading links from the server: ${e.message}`));
          });
      })
      .catch(e => {
        dispatch(loadDashboardsError(`Error loading links from the server: ${e.message}`));
      });
  };
}

export function setDashboards(dashboards) {
  return dispatch => {
    dispatch({
      type: SET_LINKS,
      payload: dashboards,
    });
  };
}

export function loadDashboardsRequested() {
  return {
    type: LINKS_UPDATE_REQUEST,
  };
}

export function loadDashboardsSuccess() {
  return {
    type: LINKS_UPDATE_DONE,
    payload: {
      loading: false,
      error: false,
      errorMsg: null,
    },
  };
}

export function loadDashboardsError(errorMsg) {
  return {
    type: LINKS_UPDATE_DONE,
    payload: {
      loading: false,
      error: true,
      errorMsg,
    },
  };
}
