import {
  SET_CAPACITY_LIST,
  SET_CAPACITY_LOADING,
  LOAD_CAPACITY_LIST_ERROR,
} from "../constants/actions";

const INITIAL_STATE = {
  list: [
    { value: "1,5 M3 / HORA", label: "1,5 M3 / HORA" },
    { value: "3 M3 / HORA", label: "3 M3 / HORA" },
  ],
  loading: false,
  errorLoading: false,
  errorLoadingMsg: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CAPACITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CAPACITY_LIST:
      return {
        ...state,
        loading: false,
        errorLoading: false,
        list: action.payload,
      };
    case LOAD_CAPACITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorLoading: true,
      };
    default:
      return state;
  }
}
