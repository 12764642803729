import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import styles from './Footer.styles';

class Footer extends Component {
  render() {
    return (
      <Typography variant="caption" align="center" style={styles.wrapper}>
        Desenvolvido pela&nbsp;<a target="_blank" href="https://oncase.com.br" rel="noopener noreferrer">Oncase</a>
      </Typography>
    );
  }
}

export default Footer;
