import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { setDialog } from '../../actions/dialog';
import BaseForm from '../BaseForm/BaseForm';
import { Dialog, DialogContent } from '@material-ui/core';

class BaseFormDialog extends Component {

    closeDialog = () => {
        const { dialog } = this.props;

        Object.keys(dialog).forEach(key => {
            if (key === 'data') return;

            if (dialog[key] === true) {
                this.props.setDialog({ field: key, open: false, data: null });
            }
        });
    };

    isShowDialog = () => {
        let isShowDialog = false;
        const { dialog } = this.props;

        Object.keys(dialog).forEach(key => {
            if (key === 'data') return;

            if (dialog[key] === true) {
                isShowDialog = true;
            }
        });

        return isShowDialog;
    };

    onSubmit = (params) => {
        this.closeDialog();
        this.props.onSubmit(params);
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={this.isShowDialog() ? true : false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <BaseForm
                        onCancel={this.closeDialog}
                        onSubmit={params => this.onSubmit(params)} />
                </DialogContent>
            </Dialog>
        )
    }

}

const mapStateToProps = ({ dialog }) => ({ dialog });

export default compose(
    connect(mapStateToProps, { setDialog })
)(BaseFormDialog);
