import React, { Component } from 'react';

import {
  Title,
  TitleContainer,
  HeaderActionsContainer,
  HeaderActionText,
  ParamTextContainer,
  ParamText,
  ParamTextValue,
} from './Header.style';
import { FORMAT_CURRENCY } from '../../constants';
import { formatMoney, getMonthYearReference, getMonthYearFormatted, getStrFromArray } from '../../utils/misc';

class Header extends Component {
  getAggregatorsHeader = ({ data, totalizers, isMeta }) => {
    let header = {};
    let availableTotalizers = [];

    if (!data || !data.length || !totalizers || !totalizers.length) {
      return header;
    }

    if (isMeta) {
      availableTotalizers = totalizers.filter(totalizer => totalizer.from === 'meta');
    } else {
      availableTotalizers = totalizers.filter(totalizer => totalizer.from !== 'meta');
    }

    availableTotalizers.forEach(totalizer => {
      if (totalizer.field) {
        header[totalizer.field] = 0;
      }
    });

    data.forEach(item => {
      Object.keys(item).forEach(field => {
        if (header[field] !== undefined) {
          header[field] += item[field];
        }
      });
    });

    return header;
  };

  getAggHeaderParsed = (aggHeader, totalizer) => {
    if (totalizer && totalizer.field && !aggHeader[totalizer.field]) {
      return null;
    }

    if (!totalizer.format) {
      return aggHeader[totalizer.field];
    }

    if (totalizer.format === FORMAT_CURRENCY) {
      return `R$ ${formatMoney(aggHeader[totalizer.field])}`;
    }
  };

  getCapacities = capacities => {
    let capacitiesFormatted = [];

    if (!capacities || !capacities.length) {
      return null;
    }

    capacities.forEach(capacity => {
      let capacityTemp = capacity.value
        .trim()
        .toLowerCase()
        .replace(',', '.')
        .replace('m3 / hora', '')
        .replace('m3 / dia', '')
        .trim();

      capacitiesFormatted.push(capacityTemp);
    });

    if (capacitiesFormatted.length) {
      capacitiesFormatted = `${capacitiesFormatted.join(', ')} (m³/h)`;
    } else {
      capacitiesFormatted = '';
    }

    return capacitiesFormatted;
  };

  render() {
    const {
      title,
      loading,
      actions,
      data,
      totalizers,
      location,
      section,
      capacity,
      route,
      investiment,
      monthYearReference,
      meta,
    } = this.props;
    const aggHeaderDefault = this.getAggregatorsHeader({
      data,
      totalizers,
      isMeta: false,
    });
    const aggHeaderMeta = this.getAggregatorsHeader({
      data: meta,
      totalizers,
      isMeta: true,
    });
    const aggHeader = { ...aggHeaderDefault, ...aggHeaderMeta };

    return (
      <>
        <TitleContainer>
          <Title loading={loading}>{title}</Title>

          {actions && actions.length ? (
            <HeaderActionsContainer loading={loading}>
              {actions.map((action, index) => (
                <div key={index} style={{ display: 'flex' }}>
                  <div>{action.icon}</div>
                  <HeaderActionText
                    key={`text-${index}`}
                    style={{
                      paddingRight: actions.length - 1 === index ? 0 : '2rem',
                    }}
                    loading={loading}
                    onClick={!loading ? action.handle : null}
                  >
                    {action.name}
                  </HeaderActionText>
                </div>
              ))}
            </HeaderActionsContainer>
          ) : null}
        </TitleContainer>

        <ParamTextContainer loading={loading}>
          {location ? (
            <ParamText>
              Localidade: <ParamTextValue>{location}</ParamTextValue>
            </ParamText>
          ) : null}
          {section && section.length ? (
            <ParamText title={getStrFromArray(section)}>
              {section && section.length > 1 ? 'Setores: ' : 'Setor: '}
              <ParamTextValue> {getStrFromArray(section)}</ParamTextValue>
            </ParamText>
          ) : null}
          {route && route.length ? (
            <ParamText title={getStrFromArray(route)}>
              {route && route.length > 1 ? 'Rotas: ' : 'Rota: '}
              <ParamTextValue> {getStrFromArray(route)}</ParamTextValue>
            </ParamText>
          ) : null}
          {capacity && capacity.length ? (
            <ParamText title={this.getCapacities(capacity)}>
              {capacity && capacity.length > 1 ? 'Capacidades: ' : 'Capacidade: '}
              <ParamTextValue>{this.getCapacities(capacity)}</ParamTextValue>
            </ParamText>
          ) : null}
          {investiment !== undefined ? (
            <ParamText title={`R$ ${formatMoney(investiment)}`}>
              Investimento:
              <ParamTextValue> {`R$ ${formatMoney(investiment)}`}</ParamTextValue>
            </ParamText>
          ) : null}
          {monthYearReference ? (
            <ParamText
              title={
                monthYearReference === 'Todos'
                  ? 'Todos'
                  : Array.isArray(monthYearReference) && monthYearReference.length
                  ? monthYearReference.map(element => ' ' + getMonthYearFormatted(element.value))
                  : getMonthYearReference({ type: 'parsed' })
              }
            >
              Mês/Ano:
              <ParamTextValue>
                {' '}
                {monthYearReference === 'Todos'
                  ? 'Todos'
                  : Array.isArray(monthYearReference) && monthYearReference.length
                  ? monthYearReference.map((element, index) => {
                      if (index >= monthYearReference.length - 1) return getMonthYearFormatted(element.value);
                      return getMonthYearFormatted(element.value) + ' - ';
                    })
                  : getMonthYearReference({ type: 'parsed' })}
              </ParamTextValue>
            </ParamText>
          ) : null}

          {!loading && totalizers && totalizers.length
            ? totalizers.map((item, idx) => (
                <ParamText key={idx}>
                  {item.label}: <ParamTextValue>{this.getAggHeaderParsed(aggHeader, item)}</ParamTextValue>
                </ParamText>
              ))
            : null}
        </ParamTextContainer>
      </>
    );
  }
}

export default Header;
