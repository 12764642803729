import {
  TOGGLE_DRAWER
} from '../constants/actions';

export default function(state = {open: true}, action) {
  switch(action.type) {
      case TOGGLE_DRAWER :
        return { open: !state.open };
      default :
        return state;
  }
}