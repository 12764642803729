import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../constants/actions';

const defaultState = {
  notifications: [],
};

const snackPosition = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  }
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
            options: {
              ...snackPosition,
              ...action.notification.options,
            }
          },
        ],
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };

    default:
      return state;
  }
}