import styles from './Analysis.styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, withStyles } from '@material-ui/core';
import AnalysisList from '../AnalysisList/AnalysisList';
import { getAnalysis } from '../../actions/analysis';
import { setRankingParams } from '../../actions/rankings';
import { setDialog } from '../../actions/dialog';
import FilterIcon from 'react-feather/dist/icons/sliders';
import BaseFormDialog from '../BaseFormDialog/BaseFormDialog';
import Header from '../Header/Header';
import { FORMAT_CURRENCY } from '../../constants';

class Analysis extends Component {

  componentWillMount() {
    const query = [
      { $sort: { createdAt: -1 } }
    ];

    this.props.getAnalysis(query);
  }

  handleFilter = () => {
    this.props.setDialog({ field: 'isSavedAnalysisFilterForm', open: true });
  }

  getQueryAnalysis = (params) => {
    let match = {};
    let query = [{ $sort: { createdAt: -1 } }];

    let sections = [];
    let analysisMonthYears = [];

    if (params.locationId) {
      match.locationId = params.locationId;
    }

    if (params.section && params.section.length) {
      sections = params.section.map((item) => item.value);

      match.sectionId = { $in: sections };
    }

    if (params.analysisMonthYear && params.analysisMonthYear.length) {
      analysisMonthYears = params.analysisMonthYear.map((item) => item.value);

      match.createdAtMonthYear = { $in: analysisMonthYears };
    }

    query.push({ $match: match });

    return query;
  }

  onSubmitFilter = (params) => {
    const query = this.getQueryAnalysis(params);

    this.props.setRankingParams(params);
    this.props.getAnalysis(query);
    this.props.setDialog({ field: 'isSavedAnalysisFilterForm', open: false });
  }

  render() {
    const { classes } = this.props;
    const { data, loading } = this.props.analysis;
    const actions = [{
      name: 'Filtros',
      icon: <FilterIcon size={20} />,
      handle: this.handleFilter.bind(this)
    }];
    const totalizers = [
      { label: 'Total de trocas', field: 'qtdHidrChanged' },
      { label: 'Total faturado', field: 'totalBilled', format: FORMAT_CURRENCY },
      { label: 'Total ganho', field: 'totalGain', format: FORMAT_CURRENCY },
    ]

    return (
      <div className={classes.mainContainer}>
        <Header
          loading={loading}
          title='Análises Salvas'
          actions={actions}
          data={data}
          totalizers={totalizers} />

        {!loading ?
          <Grid container direction="row" classes={{ container: classes.container }}>
            <Grid item xs={12} className={classes.list}>

              <Grid item xs={12}>
                <AnalysisList history={this.props.history} />
              </Grid>
            </Grid>
          </Grid>
          : <div><CircularProgress className={classes.progress} /></div>}
        <BaseFormDialog onSubmit={(params) => this.onSubmitFilter(params)} />
      </div>
    );
  }
}

const mapStateToProps = ({ analysis }) => ({ analysis });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getAnalysis, setDialog, setRankingParams })
)(Analysis);
