import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import styles from './PerformanceList.style';
import {
  withStyles,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import _orderBy from 'lodash/orderBy';
import { formatMoney } from '../../utils/misc';

class PerformanceList extends Component {
  state = {
    page: 0,
    orderBy: 'total_location_changes',
    order: 'desc',
    rowsPerPage: 10,
    currentList: [],
    paginatedList: [],
    idxClickedRow: null,
  };

  componentDidMount() {
    const { data } = this.props.performance;
    if (data) {
      data.splice(-1); // o último elemento contém os dados de total de trocas, faturamento e ganho de TODAS as localidades da pesquisa
      this.updatePaginatedListAfterSlide(data);
    }
  }

  updatePaginatedListAfterSlide(data) {
    const { page, rowsPerPage } = this.state;
    const paginatedList = data.slice(page, rowsPerPage);
    this.setState({ currentList: data, paginatedList });
  }

  initPagination(data) {
    const { rowsPerPage } = this.state;
    const paginatedList = data.slice(0, rowsPerPage);

    this.setState({ page: 0, paginatedList });
  }

  handleChangePage = (event, page) => {
    const { currentList, rowsPerPage } = this.state;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ page, paginatedList });
  };

  handleChangeRowsPerPage = event => {
    const { page, currentList } = this.state;
    const rowsPerPage = event.target.value;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ paginatedList, rowsPerPage });
  };

  handleRequestSort = property => {
    const { order, orderBy, currentList, page, rowsPerPage } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    const offset = page * rowsPerPage;

    const newCurrentList = _orderBy(currentList, property, newOrder);
    const newPaginatedList = newCurrentList.slice(offset, offset + rowsPerPage);

    this.setState({
      order: newOrder,
      orderBy: property,
      currentList: newCurrentList,
      paginatedList: newPaginatedList,
    });
  };

  getSortLabel = (property, label) => {
    const { order, orderBy } = this.state;

    return (
      <TableSortLabel
        active={orderBy === property}
        direction={order}
        onClick={this.handleRequestSort.bind(this, property)}
      >
        {label}
      </TableSortLabel>
    );
  };

  getTableRowStyle = index => {
    let style = { background: '#FFF' };

    if (index % 2) {
      style.background = '#F9F9F9';
    }

    return style;
  };

  getTableHeadRow() {
    const { classes } = this.props;

    return (
      <TableRow>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('location', 'Localidade')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_location_changes', 'Total de trocas')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_consumo_ant', 'Consumo total anterior')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_consumo_pos', 'Consumo total posterior')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_before', 'Total anterior as trocas')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_after', 'Total posterior as trocas')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_location_billing', 'Total faturado')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('total_location_profit', 'Total ganho')}
        </TableCell>
      </TableRow>
    );
  }

  getTableRow(data) {
    const { classes } = this.props;

    return data.map((row, i) => (
      <TableRow key={i} style={this.getTableRowStyle(i)} className={classes.tableRow}>
        <TableCell padding="none" className={classes.tableRow}>
          {row.location ? row.location : <strong>LOCALIDADE INDISPONÍVEL</strong>}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_location_changes !== null ? (
            row.total_location_changes
          ) : (
            <strong>TOTAL DE TROCAS INDISPONÍVEL</strong>
          )}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_consumo_ant !== null ? (
            Math.round(row.total_consumo_ant * 100) / 100
          ) : (
            <strong>CONSUMO TOTAL ANTERIOR INDISPONÍVEL</strong>
          )}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_consumo_pos !== null ? (
            Math.round(row.total_consumo_pos * 100) / 100
          ) : (
            <strong>CONSUMO TOTAL POSTERIOR INDISPONÍVEL</strong>
          )}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_before !== null ? (
            'R$ ' + formatMoney(Math.round(row.total_before * 100) / 100)
          ) : (
            <strong>TOTAL ANTERIOR AS TROCAS INDISPONÍVEL</strong>
          )}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_after !== null ? (
            'R$ ' + formatMoney(Math.round(row.total_after * 100) / 100)
          ) : (
            <strong>TOTAL POSTERIOR AS TROCAS INDISPONÍVEL</strong>
          )}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_location_billing !== null ? (
            'R$ ' + formatMoney(Math.round(row.total_location_billing * 100) / 100)
          ) : (
            <strong>TOTAL FATURADO INDISPONÍVEL</strong>
          )}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.total_location_profit !== null ? (
            'R$ ' + formatMoney(Math.round(row.total_location_profit * 100) / 100)
          ) : (
            <strong>TOTAL GANHO INDISPONÍVEL</strong>
          )}
        </TableCell>
      </TableRow>
    ));
  }

  render() {
    const { classes } = this.props;
    const { page, rowsPerPage, paginatedList, currentList } = this.state;

    return (
      <Table>
        <TableHead>{this.getTableHeadRow()}</TableHead>
        <TableBody>{this.getTableRow(paginatedList)}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={currentList.length}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              labelRowsPerPage="Linhas por página:"
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              classes={{
                caption: classes.paginationCaption,
                toolbar: classes.paginationToolbar,
                selectIcon: classes.paginationSelectIcon,
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
}

const mapStateToProps = ({ performance }) => ({ performance });

export default compose(withStyles(styles), connect(mapStateToProps, {}))(PerformanceList);
