import styles from './Chart.style';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defaultBlue, orange } from '../App/theme';
import GraphReturnIcon from 'react-feather/dist/icons/trending-up';
import YieldCurveIcon from 'react-feather/dist/icons/bar-chart-2';
import { withStyles, Grid, Typography, CircularProgress } from '@material-ui/core';
import { formatMoney, getMonthYearFormatted } from '../../utils/misc';
import {
  LineSeries,
  XAxis,
  VerticalGridLines,
  AreaSeries,
  FlexibleXYPlot,
  LabelSeries,
  VerticalBarSeries,
  HorizontalBarSeries,
  Crosshair,
  YAxis,
  LineMarkSeries,
} from 'react-vis';
import {
  GRAPH_RETURN,
  YIELD_CURVE,
  GRAPH_PERFORMANCE,
  INDIVIDUAL_USE,
  TOTAL_PROFIT_REAL_IA,
  TOTAL_PROFIT_REAL_ANT,
  QTD_TOTAL_CHANGES,
  QTD_TOTAL_CHANGES_BY_LOCATION,
} from '../../constants';

import { getPerformanceMonthYear } from '../../actions/performance_month_year';

class Chart extends Component {
  state = {
    crosshairValues: [],
  };

  delimiterChart = 20;

  componentDidMount() {
    this.props.getPerformanceMonthYear();
  }

  onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  onNearestX = (data, { index }) => {
    const newData = { ...data, index: index };

    this.setState({ crosshairValues: [newData] });
  };

  getDataChart() {
    const { viewMode } = this.props;

    if (viewMode === GRAPH_RETURN) {
      return this.getGraphReturnData();
    } else if (viewMode === YIELD_CURVE) {
      return this.getYieldCurveData();
    } else if (viewMode === GRAPH_PERFORMANCE) {
      return this.getGraphPerformanceData();
    } else if (viewMode === INDIVIDUAL_USE) {
      return this.getIndividualUseData();
    } else if (viewMode === TOTAL_PROFIT_REAL_IA) {
      return this.getTotalProfitRealIAData();
    } else if (viewMode === TOTAL_PROFIT_REAL_ANT) {
      return this.getTotalProfitRealAntData();
    } else if (viewMode === QTD_TOTAL_CHANGES) {
      return this.getQtdTotalChangesData();
    } else if (viewMode === QTD_TOTAL_CHANGES_BY_LOCATION) {
      return this.getQtdTotalChangesByLocationData();
    }
  }

  getTotalProfitRealIAData() {
    const { params, data } = this.props;
    const mainTitle = 'Comparação ganho total real e projeção de ganho da IA';
    const dataChart = [];
    const dataChart2 = [];
    const titleY = 'R$ (em milhares)';
    let dataParsed = {};
    const legend = [
      {
        type: 'line',
        color: defaultBlue,
        text: 'Ganho total real (R$)',
      },
      {
        type: 'line',
        color: orange,
        text: 'Ganho projetado pela IA (R$)',
      },
    ];

    if (!data || !data.length) {
      return { dataChart };
    }

    if (params.monthYearReference === 'Todos') {
      const { performance_month_year } = this.props;
      performance_month_year.list
        .sort((a, b) => a.value - b.value)
        .map(item => {
          dataParsed[item.label] = [];
          return item.label;
        });
    } else if (params.monthYearReference && params.monthYearReference.length) {
      params.monthYearReference.forEach(item => {
        dataParsed[item.label] = [];
      });
    }

    data.forEach(element => {
      if (element.changes && element.changes.length)
        element.changes.forEach(change => {
          const mY = getMonthYearFormatted(change.mes_ano);
          return mY in dataParsed ? dataParsed[mY].push(change) : null;
        });
    });

    const dataParsedArray = Object.keys(dataParsed);

    dataParsedArray.forEach(monthYear => {
      let totalRealGain = 0;
      let totalIAGain = 0;

      dataParsed[monthYear].forEach(item => {
        if (item.fatura_total) totalRealGain += item.fatura_total;
        if (item.fatura_projetada) totalIAGain += item.fatura_projetada;
      });

      const x =
        dataParsedArray.length > this.delimiterChart ? monthYear.substring(monthYear.length - 4) : monthYear;
      const check = dataChart.find(value => value.x === x);

      if (!check) {
        dataChart.push({
          x,
          y: totalRealGain,
          label: 'Ganho total real',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
        dataChart2.push({
          x,
          y: totalIAGain,
          label: 'Ganho projetado pela IA',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
      } else {
        dataChart.map(value => {
          if (value.x === x) {
            value.y += totalRealGain;
          }

          return value;
        });
        dataChart2.map(value => {
          if (value.x === x) {
            value.y += totalIAGain;
          }

          return value;
        });
      }
    });

    return {
      mainTitle,
      titleY,
      dataChart,
      dataChart2,
      legend,
      showLabelSeries: false,
      xAxisAngle: dataParsedArray.length > 8 && dataParsedArray.length <= this.delimiterChart ? -30 : 0,
      YHideTicks: false,
      YHideLine: false,
      tickFormatY: true,
    };
  }

  getTotalProfitRealAntData() {
    const { params, data } = this.props;
    const mainTitle = 'Comparação ganho total após a troca e ganho total anterior a troca';
    const dataChart = [];
    const dataChart2 = [];
    const titleY = 'R$ (em milhares)';
    let dataParsed = {};
    const legend = [
      {
        type: 'line',
        color: defaultBlue,
        text: 'Ganho total após (R$)',
      },
      {
        type: 'line',
        color: '#7B84F3',
        text: 'Ganho total anterior (R$)',
      },
    ];

    if (!data || !data.length) {
      return { dataChart };
    }

    if (params.monthYearReference === 'Todos') {
      const { performance_month_year } = this.props;

      performance_month_year.list
        .sort((a, b) => a.value - b.value)
        .map(item => {
          dataParsed[item.label] = [];
          return item.label;
        });
    } else if (params.monthYearReference && params.monthYearReference.length) {
      params.monthYearReference.forEach(item => {
        dataParsed[item.label] = [];
      });
    }

    data.forEach(element => {
      if (element.changes && element.changes.length)
        element.changes.forEach(change => {
          const mY = getMonthYearFormatted(change.mes_ano);
          return mY in dataParsed ? dataParsed[mY].push(change) : null;
        });
    });

    const dataParsedArray = Object.keys(dataParsed);

    dataParsedArray.forEach(monthYear => {
      let totalRealGain = 0;
      let totalAnt = 0;

      dataParsed[monthYear].forEach(item => {
        if (item.fatura_total) totalRealGain += item.fatura_pos;
        if (item.fatura_ant) totalAnt += item.fatura_ant;
      });

      const x =
        dataParsedArray.length > this.delimiterChart ? monthYear.substring(monthYear.length - 4) : monthYear;
      const check = dataChart.find(value => value.x === x);

      if (!check) {
        dataChart.push({
          x,
          y: totalRealGain,
          label: 'Ganho total após a troca',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
        dataChart2.push({
          x,
          y: totalAnt,
          label: 'Ganho total anterior a troca',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
      } else {
        dataChart.map(value => {
          if (value.x === x) {
            value.y += totalRealGain;
          }

          return value;
        });
        dataChart2.map(value => {
          if (value.x === x) {
            value.y += totalAnt;
          }

          return value;
        });
      }
    });

    return {
      mainTitle,
      titleY,
      dataChart,
      dataChart2,
      legend,
      showLabelSeries: false,
      xAxisAngle: dataParsedArray.length > 8 && dataParsedArray.length <= this.delimiterChart ? -30 : 0,
      YHideTicks: false,
      YHideLine: false,
      tickFormatY: true,
    };
  }

  getQtdTotalChangesData() {
    const { params, data } = this.props;
    const dataChart = [];
    let dataParsed = {};
    const legend = [
      {
        type: 'line',
        color: '#74DD8B',
        text: 'Número total de trocas dos hidrômetros',
      },
    ];

    if (!data || !data.length) {
      return { dataChart };
    }

    if (params.monthYearReference === 'Todos') {
      const { performance_month_year } = this.props;
      performance_month_year.list
        .sort((a, b) => a.value - b.value)
        .map(item => {
          dataParsed[item.label] = 0;
          return item.label;
        });
    } else if (params.monthYearReference && params.monthYearReference.length) {
      params.monthYearReference.forEach(item => {
        dataParsed[item.label] = 0;
      });
    }

    data.forEach(element => {
      if (element.changes && element.changes.length)
        element.changes.forEach(change => {
          const mY = getMonthYearFormatted(change.mes_ano);

          if (mY in dataParsed) dataParsed[mY] += change.changes;
        });
    });

    const dataParsedArray = Object.keys(dataParsed);
    const mainTitle = `Quantidade total de trocas dos hidrômetros por ${
      dataParsedArray.length > this.delimiterChart ? 'ano' : 'mês/ano'
    }`;

    dataParsedArray.forEach(monthYear => {
      const x =
        dataParsedArray.length > this.delimiterChart ? monthYear.substring(monthYear.length - 4) : monthYear;
      const check = dataChart.find(value => value.x === x);

      if (!check) {
        dataChart.push({
          x,
          y: dataParsed[monthYear],
          label: 'Quantidade total de trocas',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
      } else {
        dataChart.map(value => {
          if (value.x === x) {
            value.y += dataParsed[monthYear];
          }

          return value;
        });
      }
    });

    return {
      mainTitle,
      dataChart,
      legend,
      showLabelSeries: false,
      xAxisAngle: dataParsedArray.length > 8 && dataParsedArray.length <= this.delimiterChart ? -30 : 0,
      YHideTicks: false,
      YHideLine: false,
      tickFormatY: true,
    };
  }

  getQtdTotalChangesByLocationData() {
    const { data } = this.props;
    const mainTitle = 'Quantidade total de trocas dos hidrômetros por localidade (top 10)';
    const dataChart = [];
    const legend = [
      {
        type: 'line',
        color: '#9DD8FE',
        text: 'Número total de trocas dos hidrômetros',
      },
    ];

    if (!data || !data.length) {
      return { dataChart };
    }

    data.forEach(element => {
      if (element.changes && element.changes.length)
        return dataChart.push({
          x: element.changes.reduce((total, change) => total + change.changes, 0),
          y: element.location,
          label: 'Quantidade total de trocas',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
      else
        return dataChart.push({
          x: 0,
          y: element.location,
          label: 'Quantidade total de trocas',
          style: { fontSize: 12, fontWeight: 'bold' },
        });
    });

    // dataChart.sort((a, b) => b.y - a.y);
    // já recebemos o dado ordenado

    return {
      mainTitle,
      dataChart: dataChart.slice(0, 10).reverse(),
      legend,
      showLabelSeries: false,
      YHideTicks: false,
      YHideLine: false,
    };
  }

  getGraphReturnData() {
    let totalRoi = 0;
    let roiMonths = 1;
    let mainTitle = '';
    let dataChart = [];
    let titleY = 'Faturamento';
    const { params, data } = this.props;
    let totalCost = params.investiment * params.maxRanking;

    data.forEach(item => {
      totalRoi = totalRoi + item.roi;
    });

    while (totalCost > 0 && totalRoi > 0) {
      const totalCostTemp = totalCost - totalRoi <= 0 ? 0 : totalCost - totalRoi;

      dataChart.push({
        x: roiMonths,
        y: totalCostTemp,
        label: `R$ ${formatMoney(totalCostTemp, '2', ',', '.')}`,
        style: { fontSize: 12, fontWeight: 'bold' },
      });

      totalCost = totalCostTemp;

      if (totalCostTemp > 0) {
        roiMonths++;
      }
    }

    mainTitle = `Retorno em ${roiMonths} ${roiMonths > 1 ? 'meses' : 'mês'}`;

    return { mainTitle, titleY, dataChart, showLabelSeries: true };
  }

  getIndividualUseData() {
    let imovId = null;
    let highlitedConsume = null;
    const { data } = this.props;
    let mainTitle = 'Consumo do Hidrômetro';
    let dataChart = [];
    let dataChart2 = [];
    let dataChart3 = [];
    let titleY = 'Consumo/Faturamento';
    let titleY2 = 'Faturamento';
    const legend = [
      {
        type: 'line',
        color: defaultBlue,
        text: 'Consumo',
      },
      {
        type: 'line',
        color: '#4DD0D0',
        text: 'Troca do hidrômetro',
      },
      {
        type: 'line',
        color: '#7B84F3',
        text: 'Faturamento',
      },
    ];

    if (!data || !data.length) {
      return { dataChart };
    }

    data.forEach(item => {
      const consumes = item.consumo_arr;
      const monthYears = item.mes_ano_arr;
      const billings = item.fatura_arr;
      imovId = item.imov_id;

      if (!consumes || !monthYears || !consumes.length || !monthYears.length || !billings) {
        return;
      }

      consumes.forEach((consume, index) => {
        const monthYear = monthYears[index];
        const billing = billings[index];
        const monthYearParsed = getMonthYearFormatted(monthYear);

        if (monthYear === item.mes_ano) {
          highlitedConsume = {
            x: monthYearParsed,
            y: consume,
            parentIndex: index,
          };
        }

        dataChart.push({
          x: monthYearParsed,
          y: consume,
          label: consume,
          style: { fontSize: 12, fontWeight: 'bold' },
        });

        dataChart3.push({
          x: monthYearParsed,
          y: billing,
          label: billing,
          style: { fontSize: 12, fontWeight: 'bold' },
        });
      });
    });

    if (highlitedConsume) {
      dataChart2.push({
        x: highlitedConsume.x,
        y: highlitedConsume.y,
        label: highlitedConsume.y,
        parentIndex: highlitedConsume.parentIndex,
        style: { fontSize: 12, fontWeight: 'bold' },
      });
    }

    mainTitle = `${mainTitle} (Mat. ${imovId})`;

    return {
      mainTitle,
      titleY,
      titleY2,
      dataChart,
      dataChart2,
      dataChart3,
      legend,
      xAxisAngle: -45,
      chartHeight: 500,
      YHideTicks: false,
      YHideLine: false,
    };
  }

  getGraphPerformanceData() {
    let mainTitle = 'Gráfico de Performance dos hidrômetros';
    let dataChart = [];
    let dataChart2 = [];
    let dataParsed = {};
    let titleY = 'Consumo anterior/Consumo posterior';
    const { data } = this.props;
    const legend = [
      {
        type: 'line',
        color: defaultBlue,
        text: 'Consumo anterior (média de 3m)',
      },
      {
        type: 'line',
        color: orange,
        text: 'Consumo posterior (média de 3m)',
      },
    ];

    if (!data || !data.length) {
      return { dataChart };
    }

    data.forEach(item => {
      if (!item.mes_ano) {
        return;
      }

      if (!dataParsed[item.mes_ano]) {
        dataParsed[item.mes_ano] = {
          consumo_ant: 0,
          consumo_pos: 0,
        };
      }

      dataParsed[item.mes_ano] = {
        consumo_ant: dataParsed[item.mes_ano].consumo_ant + item.consumo_ant,
        consumo_pos: dataParsed[item.mes_ano].consumo_pos + item.consumo_pos,
      };
    });

    Object.keys(dataParsed).forEach(key => {
      const item = dataParsed[key];
      const dateFormatted = getMonthYearFormatted(key);
      const consumoAntParsed = Math.round(item.consumo_ant * 100) / 100;
      const consumoPosParsed = Math.round(item.consumo_pos * 100) / 100;

      dataChart.push({
        x: dateFormatted,
        y: consumoAntParsed,
        label: consumoAntParsed,
        style: { fontSize: 12, fontWeight: 'bold' },
      });

      dataChart2.push({
        x: dateFormatted,
        y: consumoPosParsed,
        label: consumoPosParsed,
        style: { fontSize: 12, fontWeight: 'bold' },
      });
    });

    return {
      mainTitle,
      titleY,
      dataChart,
      dataChart2,
      legend,
      showLabelSeries: false,
      YHideTicks: false,
      YHideLine: false,
    };
  }

  getYieldCurveData() {
    let mainTitle;
    let dataChart = [];
    let yieldCurveParsed;
    let titleY = 'Rendimento';
    const { params, yieldCurve } = this.props;

    if (!yieldCurve || !yieldCurve.item || !yieldCurve.item.yield_curve) {
      return { dataChart };
    }

    yieldCurveParsed = yieldCurve.item.yield_curve.split('#');

    yieldCurveParsed.forEach((item, index) => {
      dataChart.push({
        x: index,
        y: item,
        label: `${~~(item * 100)}%`,
        style: { fontSize: 12, fontWeight: 'bold' },
      });
    });

    mainTitle = `Curva de Rendimento em ${yieldCurveParsed.length} ${
      yieldCurveParsed.length > 1 ? 'anos' : 'ano'
    } - ${params.priorization} ${yieldCurve.item.id}`;

    return { mainTitle, titleY, dataChart, showLabelSeries: true };
  }

  isLoading = () => {
    let isLoading = false;
    const { viewMode, yieldCurve } = this.props;

    if (viewMode === YIELD_CURVE) {
      isLoading = yieldCurve.loading;
    }

    return isLoading;
  };

  renderMsgEmptyState = () => {
    let msg = '';
    const { classes, viewMode, data } = this.props;

    if (viewMode === INDIVIDUAL_USE && !data.length) {
      msg = 'Selecione um hidrômetro para visualizar os dados de consumo';
    }

    if (msg) {
      return (
        <Grid item xs={12}>
          <Typography className={classes.msgEmptyState}>{msg}</Typography>
        </Grid>
      );
    }

    return null;
  };

  renderLegend = ({ legend }) => {
    const legendStyles = {
      bar: { width: '.7vw', height: '2.5vh' },
      line: { minWidth: '4.5vw', minHeight: '1.5vh' },
    };

    if (!legend || !legend.length) {
      return null;
    }

    return (
      <Grid
        item
        xs={4}
        style={{
          display: 'flex',
          paddingLeft: '2rem',
          flexDirection: 'column',
          fontSize: '13px',
          fontWeight: 400,
        }}
      >
        {legend.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              paddingBottom: '.2vh',
            }}
          >
            <div
              style={{
                backgroundColor: item.color,
                ...legendStyles[item.type],
              }}
            ></div>
            <span style={{ paddingLeft: '.5vw' }}>{item.text}</span>
          </div>
        ))}
      </Grid>
    );
  };

  getMainTitle = ({ dataChart, mainTitle, legend }) => {
    let Icon;
    const { classes, viewMode } = this.props;

    if (viewMode === GRAPH_RETURN || viewMode === GRAPH_PERFORMANCE) {
      Icon = GraphReturnIcon;
    } else if (
      viewMode === YIELD_CURVE ||
      viewMode === INDIVIDUAL_USE ||
      viewMode === TOTAL_PROFIT_REAL_IA ||
      viewMode === TOTAL_PROFIT_REAL_ANT ||
      viewMode === QTD_TOTAL_CHANGES ||
      viewMode === QTD_TOTAL_CHANGES_BY_LOCATION
    ) {
      Icon = YieldCurveIcon;
    }

    return (
      <Grid
        container
        item
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid item xs={8} style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Icon color={'#777777'} />
          &nbsp; &nbsp;
          {dataChart.length ? <Typography className={classes.title}>{mainTitle}</Typography> : null}
        </Grid>

        {this.renderLegend({ legend })}
      </Grid>
    );
  };

  renderChartSeries = ({ dataChart, dataChart2, dataChart3, legend }) => {
    let charts = [];
    const { viewMode } = this.props;

    if (viewMode === INDIVIDUAL_USE) {
      charts.push(
        <LineSeries
          key={charts.length + 1}
          strokeWidth={4}
          data={dataChart}
          color={defaultBlue}
          onNearestX={this.onNearestX}
        />
      );

      charts.push(<LineMarkSeries key={charts.length + 1} strokeWidth={4} data={dataChart2} fill={'#4DD0D0'} />);

      charts.push(
        <LineSeries
          key={charts.length + 1}
          strokeWidth={4}
          data={dataChart3}
          color={'#7B84F3'}
          onNearestX={this.onNearestX}
        />
      );

      return charts;
    }

    if (
      viewMode === GRAPH_PERFORMANCE ||
      viewMode === TOTAL_PROFIT_REAL_IA ||
      viewMode === TOTAL_PROFIT_REAL_ANT
    ) {
      charts.push(
        <VerticalBarSeries key={charts.length + 1} barWidth={0.4} data={dataChart} fill={legend[0].color} />
      );

      charts.push(
        <VerticalBarSeries
          key={charts.length + 1}
          barWidth={0.4}
          data={dataChart2}
          fill={legend[1].color}
          onNearestX={this.onNearestX}
        />
      );

      return charts;
    }

    if (viewMode === QTD_TOTAL_CHANGES) {
      charts.push(
        <VerticalBarSeries
          key={charts.length + 1}
          barWidth={0.2}
          data={dataChart}
          fill={legend[0].color}
          onNearestX={this.onNearestX}
        />
      );

      return charts;
    }

    if (viewMode === QTD_TOTAL_CHANGES_BY_LOCATION) {
      charts.push(
        <HorizontalBarSeries
          key={charts.length + 1}
          barWidth={0.2}
          data={dataChart}
          fill={legend[0].color}
          onNearestX={this.onNearestX}
        />
      );

      return charts;
    }

    charts.push(<VerticalGridLines key={charts.length + 1} />);

    charts.push(
      <AreaSeries
        key={charts.length + 1}
        curve="curveBasis"
        data={dataChart}
        opacity={0.15}
        fill={defaultBlue}
        style={{}}
      />
    );

    charts.push(
      <LineSeries
        key={charts.length + 1}
        curve="curveBasis"
        data={dataChart}
        opacity={0.4}
        stroke={defaultBlue}
        strokeStyle="solid"
        style={{}}
      />
    );

    return charts;
  };

  renderTooltip = ({ dataChart, dataChart2, dataChart3 }) => {
    let parentIndex = null;
    const { crosshairValues } = this.state;
    const { viewMode } = this.props;

    if (!crosshairValues || !crosshairValues.length) {
      return null;
    }

    if (!dataChart || !dataChart.length) {
      return null;
    }

    if (
      dataChart2 &&
      dataChart2.length &&
      (viewMode === GRAPH_PERFORMANCE || viewMode === TOTAL_PROFIT_REAL_IA || viewMode === TOTAL_PROFIT_REAL_ANT)
    ) {
      return (
        <Crosshair values={crosshairValues}>
          <div
            style={{
              boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
              backgroundColor: '#FFF',
              color: '#000',
              width: '100%',
              fontSize: '12px',
              padding: '.2rem',
            }}
          >
            <p>
              <b>{dataChart[crosshairValues[0].index].label}</b>{' '}
              {formatMoney(dataChart[crosshairValues[0].index].y)}
            </p>
            <p>
              <b>{dataChart2[crosshairValues[0].index].label}</b>{' '}
              {formatMoney(dataChart2[crosshairValues[0].index].y)}
            </p>
          </div>
        </Crosshair>
      );
    }

    if (
      dataChart &&
      dataChart.length &&
      (viewMode === QTD_TOTAL_CHANGES || viewMode === QTD_TOTAL_CHANGES_BY_LOCATION)
    ) {
      return (
        <Crosshair values={crosshairValues}>
          <div
            style={{
              boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
              backgroundColor: '#FFF',
              color: '#000',
              width: '100%',
              fontSize: '12px',
              padding: '.2rem',
            }}
          >
            <p>
              <b>
                {viewMode !== QTD_TOTAL_CHANGES_BY_LOCATION
                  ? dataChart[crosshairValues[0].index].label
                  : dataChart[crosshairValues[0].index].y}
              </b>{' '}
              {viewMode !== QTD_TOTAL_CHANGES_BY_LOCATION
                ? dataChart[crosshairValues[0].index].y
                : dataChart[crosshairValues[0].index].x}
            </p>
          </div>
        </Crosshair>
      );
    }

    if (viewMode === INDIVIDUAL_USE) {
      if (dataChart2 && dataChart2.length && dataChart2[0].parentIndex) {
        if (crosshairValues[0].index === dataChart2[0].parentIndex) {
          parentIndex = dataChart2[0].parentIndex;
        }
      }

      return (
        <Crosshair values={crosshairValues}>
          <div
            style={{
              boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
              backgroundColor: '#FFF',
              color: '#000',
              width: '100%',
              fontSize: '12px',
              padding: '.2rem',
            }}
          >
            <p>
              <b>Consumo:</b> {dataChart[crosshairValues[0].index].y}m³/h
            </p>
            <p>
              <b>Faturamento:</b> R$ {formatMoney(dataChart3[crosshairValues[0].index].y)}
            </p>
            {parentIndex ? (
              <p>
                <b>Trocado em:</b> {dataChart[parentIndex].x}
              </p>
            ) : null}
          </div>
        </Crosshair>
      );
    }

    return null;
  };

  render() {
    const loading = this.isLoading();
    const { crosshairValues } = this.state;
    const { classes, viewMode } = this.props;

    const {
      mainTitle,
      titleY,
      dataChart,
      dataChart2,
      dataChart3,
      legend,
      showLabelSeries,
      xAxisAngle,
      YHideTicks,
      YHideLine,
      tickFormatY,
    } = this.getDataChart();

    return (
      <Grid container className={classes.container}>
        {!loading && dataChart && dataChart.length ? this.getMainTitle({ dataChart, mainTitle, legend }) : null}
        <Grid item xs={12} style={{ paddingTop: '2vh', paddingBottom: '2vh' }}>
          {!loading && dataChart && dataChart.length ? (
            <FlexibleXYPlot
              margin={viewMode === QTD_TOTAL_CHANGES_BY_LOCATION ? { left: 180 } : undefined}
              height={540}
              xType={viewMode !== QTD_TOTAL_CHANGES_BY_LOCATION ? 'ordinal' : undefined}
              yType={viewMode === QTD_TOTAL_CHANGES_BY_LOCATION ? 'ordinal' : undefined}
              onMouseLeave={this.onMouseLeave}
              stackBy={viewMode === QTD_TOTAL_CHANGES_BY_LOCATION ? 'x' : undefined}
            >
              {showLabelSeries ? <LabelSeries data={dataChart} animation /> : null}
              <XAxis
                style={{
                  text: { fontWeight: 600 },
                }}
                tickPadding={2}
                tickLabelAngle={xAxisAngle ? xAxisAngle : 0}
              />
              <YAxis
                style={{
                  title: { fontWeight: 600 },
                  text: { fontWeight: 600 },
                }}
                hideTicks={YHideTicks !== undefined ? YHideTicks : true}
                hideLine={YHideLine !== undefined ? YHideLine : true}
                // tickFormat -> deixar o valor inteiro na label y ficaria muito grande
                tickFormat={
                  tickFormatY
                    ? viewMode !== QTD_TOTAL_CHANGES
                      ? v => Math.round(v * 1000) / 1000000
                      : v => v.toString().replace(',', '')
                    : null
                }
                title={titleY}
              />
              {crosshairValues.length ? this.renderTooltip({ dataChart, dataChart2, dataChart3 }) : null}
              {this.renderChartSeries({
                dataChart,
                dataChart2,
                dataChart3,
                legend,
              })}
            </FlexibleXYPlot>
          ) : null}
        </Grid>
        {loading ? <CircularProgress className={classes.progress} /> : null}
        {!loading ? this.renderMsgEmptyState() : null}
      </Grid>
    );
  }
}

const mapStateToProps = ({ performance_month_year }) => ({
  performance_month_year,
});

export default compose(withStyles(styles), connect(mapStateToProps, { getPerformanceMonthYear }))(Chart);
