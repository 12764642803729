

export default (theme) => ({
  SubContainer:{
    backgroundColor: '#F7F7F7',
    color: '#414141',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 9,
    width: '250px',
    padding: '20px 0',
    boxShadow: "5px 0px 4px -4px rgb(218, 218, 218)",
  },

  listItemRoot: {
    color: 'inherit',
    fontSize: '16px',
  },

  listItem: {
    display:'inline-block',
    padding: 0,
    textAlign: 'left',
    fontSize: '10px',
    cursor: 'pointer',
    '&:hover': '#dedede',
  },

  gutters: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'left',
    paddingLeft: 30,
    paddingRight: 30,
  },
  separeteMenu: {
    backgroundImage: 'linear-gradient(to right, #707070 40%, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '.91rem .6px',
    backgroundRepeat: 'repeat-x',
    borderBottom: 'none'
  }
});
