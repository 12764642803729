import { compose } from 'recompose';
import styles from './Hydrometer.style';
import React, { Component } from 'react';
import ReactMapGL, { Marker, FullscreenControl, Popup } from 'react-map-gl';
import HydrometerMapIcon from 'react-feather/dist/icons/map';
import { withStyles, Grid, Typography } from '@material-ui/core';
import blueTap from '../../assets/blue-tap.svg';
import redTap from '../../assets/red-tap.svg';
import { isValidPayback, getPaybackFormatted, getScoreFormatted } from '../../utils/misc';
import { HYDROMETER_MAP } from '../../constants';

const TOKEN = 'pk.eyJ1IjoicmFuaWVsOTAiLCJhIjoiY2p5ZXcxYmM0MTV4cTNjczU0ZndmYTMwOCJ9.6N7AMvhjkGLZEMwpe7mIog';

const fullscreenControlStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px',
};

class HydrometerMap extends Component {
  state = {
    zoom: 12,
    popupInfo: null,
    defaultLatitude: 0,
    defaultLongitude: 0,
    loading: true,
    hasData: false,
    listChecked: false,
  };

  componentDidMount() {
    const { viewMode } = this.props;

    if (viewMode !== HYDROMETER_MAP) this.setState({ listChecked: false });
  }

  componentDidUpdate() {
    let markers = [];
    const { listChecked } = this.state;
    const { list } = this.props;

    if (listChecked || !list || !list.length) {
      return;
    }

    markers = list.filter(
      item => item.latitude !== null && item.longitude !== null && !isNaN(item.latitude) && !isNaN(item.longitude)
    );

    this.setState({
      listChecked: true,
      loading: false,
      hasData: markers && markers.length,
      defaultLatitude: markers[0].latitude,
      defaultLongitude: markers[0].longitude,
    });
  }

  renderMarker = (item, index) => {
    let srcMarker;
    const { params } = this.props;

    if (!item.latitude || !item.longitude || isNaN(item.latitude) || isNaN(item.longitude)) {
      return null;
    }

    if (params.rankingBy === 'score') {
      srcMarker = item.score ? blueTap : redTap;
    } else {
      srcMarker = isValidPayback(item.payback) && item.payback > 0 ? blueTap : redTap;
    }

    return (
      <Marker key={`marker-${index}`} latitude={item.latitude} longitude={item.longitude}>
        <img
          src={srcMarker}
          alt=""
          style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
          onClick={() => this.setState({ popupInfo: item })}
        />
      </Marker>
    );
  };

  renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          {this.renderPopupInfo(popupInfo)}
        </Popup>
      )
    );
  }

  renderPopupInfo = popupInfo => (
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid item>
        <Typography>
          <b>Matrícula:</b> {popupInfo.imov_id}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <b>Score:</b> {getScoreFormatted(popupInfo.score)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <b>Payback:</b> {getPaybackFormatted(popupInfo.payback)}
        </Typography>
      </Grid>
    </Grid>
  );

  renderMsgEmptyState = () => {
    const { classes } = this.props;
    const { loading, hasData } = this.state;

    if (!loading && !hasData) {
      return (
        <Grid item xs={12}>
          <Typography className={classes.msgEmptyState}>
            Sem dados de geolocalização para exibir no mapa.
          </Typography>
        </Grid>
      );
    }

    return null;
  };

  render() {
    const { params, classes, list, viewMode } = this.props;
    const { zoom, defaultLatitude, defaultLongitude, hasData } = this.state;

    if (viewMode !== HYDROMETER_MAP) return <></>;

    return (
      <Grid container className={classes.container}>
        {hasData ? (
          <Grid item xs={12} style={{ display: 'inline-flex', alignItems: 'center' }}>
            <HydrometerMapIcon color={'#777777'} />
            &nbsp; &nbsp;
            <Typography className={classes.title}>Mapa de Hidrômetros em {params.location}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {hasData ? (
            <ReactMapGL
              width="100%"
              height="36.6rem"
              zoom={zoom}
              latitude={defaultLatitude}
              longitude={defaultLongitude}
              mapboxApiAccessToken={TOKEN}
              onViewportChange={viewport => {
                this.setState({
                  zoom: viewport.zoom,
                  defaultLatitude: viewport.latitude,
                  defaultLongitude: viewport.longitude,
                });
              }}
            >
              {hasData ? list.map(this.renderMarker) : null}

              <div className="fullscreen" style={fullscreenControlStyle}>
                <FullscreenControl />
              </div>

              {this.renderPopup()}
            </ReactMapGL>
          ) : null}
        </Grid>
        {this.renderMsgEmptyState()}
      </Grid>
    );
  }
}

export default compose(withStyles(styles))(HydrometerMap);
