import * as moment from 'moment';
import Chart from '../Chart/Chart';
import classNames from 'classnames';
import styles, { TableActionsText } from './Ranking.styles';
import { connect } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import Slider from '@material-ui/lab/Slider';
import { HOME } from '../../constants/routes';
import { showMessage } from '../../actions/snack';
import { postAnalysis } from '../../actions/analysis';
import { exportContentToFile, getAvatar, getMonthYearReference } from '../../utils/misc';
import HydrometerList from '../HydrometerList/HydrometerList';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadIcon from 'react-feather/dist/icons/download-cloud';
import FolderPlusIcon from 'react-feather/dist/icons/folder-plus';
import PlusIcon from 'react-feather/dist/icons/plus-circle';
import { setRankingParams, getRankings } from '../../actions/rankings';
import { Grid, withStyles, Chip, TextField } from '@material-ui/core';
import {
  getCapacityParsed,
  getTaxParsed,
  getDateParsed,
  getScoreFormatted,
  getPaybackFormatted,
} from '../../utils/misc';
import ViewModeChip from '../ViewModeChip/ViewModeChip';
import HydrometerMap from '../HydrometerMap/HydrometerMap';
import { setDialog } from '../../actions/dialog';
import BaseFormDialog from '../BaseFormDialog/BaseFormDialog';
import { YIELD_CURVE, HYDROMETER_MAP, GRAPH_RETURN } from '../../constants';
import Header from '../Header/Header';

class Ranking extends Component {
  state = {
    page: 0,
    taxErrorBilled: 0,
    taxErrorModel: 0,
    orderBy: '',
    order: 'desc',
    totalTaxErrorBilled: 0,
    totalTaxErrorModel: 0,
    rowsPerPage: 10,
    currentList: [],
    paginatedList: [],
    viewMode: GRAPH_RETURN,
  };

  componentWillMount() {
    const { params } = this.props.rankings;
    this.getRankings(params);
  }

  componentDidMount() {
    const { params } = this.props.rankings;

    this.setState({
      orderBy: params.rankingBy,
      viewMode: params.investiment > 0 ? GRAPH_RETURN : HYDROMETER_MAP,
    });
  }

  getRankings(params) {
    if (!params.locationId) {
      this.props.history.push({ pathname: HOME });
      return;
    }
    this.props.getRankings(
      {
        type: params.rankingBy,
        investiment: params.investiment,
        query: this.getQueryRankings(params),
        document: 'ranking_perfil',
      },
      data => {
        this.initPagination(data);
        this.setState(
          {
            currentList: data,
            orderBy: params.rankingBy,
            order: params.rankingBy === 'payback' ? 'asc' : 'desc',
            viewMode: params.investiment > 0 ? GRAPH_RETURN : HYDROMETER_MAP,
          },
          () => this.setTaxError(data, true)
        );
      }
    );
  }

  getQueryRankings = params => {
    let routes = [];
    let sections = [];
    let capacities = [];
    let hostileZone = {};
    let sectionsSelectedByKey = {};
    let routesSelectedByKey = {};
    const { hostile_zone } = this.props;

    if (hostile_zone.data && hostile_zone.data.length) {
      hostileZone = hostile_zone.data[0];
    }

    let query = {
      localidade_id: params.locationId,
      score: { $gt: 0 },
    };

    if (params.capacity && params.capacity.length) {
      capacities = params.capacity.map(item => item.value);

      query.hidrometro_capacidade_raw = { $in: capacities };
    }

    if (params.section && params.section.length) {
      params.section.forEach(item => {
        sections.push(item.value);
        sectionsSelectedByKey[item.value] = true;
      });

      query.setor_comercial_id = { $in: sections };
    }

    if (params.route && params.route.length) {
      params.route.forEach(item => {
        routes.push(item.value);
        routesSelectedByKey[item.value] = true;
      });

      query.rota_id = { $in: routes };
    }

    if (hostileZone.section && hostileZone.section.length && !hostileZone.route.length) {
      query.setor_comercial_id = {
        ...query.setor_comercial_id,
        $nin: hostileZone.section.map(item => item.value).filter(item => !sectionsSelectedByKey[item]),
      };
    }

    if (hostileZone.route && hostileZone.route.length) {
      query.rota_id = {
        ...query.rota_id,
        $nin: hostileZone.route.map(item => item.value).filter(item => !routesSelectedByKey[item]),
      };
    }

    return JSON.stringify(query);
  };

  handleClick = rankingBy => {
    let newParams = {};
    const { params } = this.props.rankings;

    if (!params.investiment && rankingBy === 'payback') {
      this.props.showMessage('Não é possível listar rankings por payback sem informar o valor de investimento!');
      return;
    }

    newParams = { ...this.props.rankings.params, rankingBy };
    this.props.setRankingParams(newParams);
    this.setState({ viewMode: GRAPH_RETURN });
    this.getRankings(newParams);
  };

  handleViewMode = viewMode => {
    this.setState({ viewMode });
  };

  setTaxError(currentList, isSetTotal) {
    let taxErrorBilled = 0;
    let taxErrorModel = 0;
    let countItemsWithErrorBilled = 0;
    let countItemsWithErrorModel = 0;

    if (!currentList || !currentList.length) {
      if (isSetTotal) {
        this.setState({ totalTaxErrorBilled: 0, taxErrorBilled: 0, totalTaxErrorModel: 0, taxErrorModel: 0 });
      } else {
        this.setState({ taxErrorBilled: 0, taxErrorModel: 0 });
      }

      return;
    }

    currentList.forEach(item => {
      if (item.error_billed) {
        taxErrorBilled += Math.abs(item.error_billed) || 0;
        countItemsWithErrorBilled++;
      }

      if (item.error_model) {
        taxErrorModel += Math.abs(item.error_model) || 0;
        countItemsWithErrorModel++;
      }
    });

    taxErrorBilled = Math.ceil(taxErrorBilled / countItemsWithErrorBilled) || 0;
    taxErrorModel = Math.ceil(taxErrorModel / countItemsWithErrorModel) || 0;

    if (isSetTotal) {
      this.setState({
        totalTaxErrorBilled: taxErrorBilled,
        totalTaxErrorModel: taxErrorModel,
        taxErrorBilled,
        taxErrorModel,
      });
    } else {
      this.setState({ taxErrorBilled, taxErrorModel });
    }
  }

  handleSlideDragEnd(e) {
    let currentList;
    const { params } = this.props.rankings;
    const { list } = this.props.rankings;

    currentList = list.filter((item, index) => index < params.maxRanking);
    this.setState({ currentList });
    this.setTaxError(currentList, false);
    this.updatePaginatedListAfterSlide(currentList);
  }

  handleSlideChange(e, value, isFromInput) {
    let currentList;
    const { list } = this.props.rankings;
    let slideValue = e.target.value ? e.target.value : value;

    if (slideValue > list.length) {
      slideValue = list.length;
    }

    if (slideValue < 0) {
      slideValue = 0;
    }

    if (isFromInput) {
      currentList = list.filter((item, index) => index < slideValue);
      this.setState({ currentList });
      this.setTaxError(currentList, false);
      this.updatePaginatedListAfterSlide(currentList);
    }

    this.props.setRankingParams({
      ...this.props.rankings.params,
      maxRanking: slideValue ? Math.ceil(slideValue) : 0,
    });
  }

  handleSaveAnalysis = () => {
    let data = {};
    let monthYear;
    const { currentList } = this.state;
    const { params, list } = this.props.rankings;
    const dateNow = moment();

    monthYear = getMonthYearReference({ type: 'default' });

    if (!currentList.length) {
      this.props.showMessage('Não é possível salvar uma análise sem dados!');
      return;
    }

    data = {
      ...params,
      maxRanking: list.length,
      monthYear: ~~monthYear,
      createdAt: dateNow.toISOString(),
      createdAtMonthYear: ~~dateNow.format('YYYYMM'),
      hidrIds: currentList.map(item => item.hidr_id),
      changes: [],
    };

    this.props.postAnalysis(data);
  };

  handleExportAnalysis() {
    const { list, params } = this.props.rankings;
    let header = [
      'Score',
      'Matricula',
      'Mês/Ano',
      'Idade (em anos)',
      'Taxa de Rendimento',
      'Capacidade',
      'Payback (em meses)',
    ];

    if (params.rankingBy === 'payback') {
      header = [
        'Payback (em meses)',
        'Matricula',
        'Mês/Ano',
        'Idade (em anos)',
        'Taxa de Rendimento',
        'Capacidade',
        'Score',
      ];
    }

    if (!params.investiment && params.rankingBy === 'score') {
      header = ['Score', 'Matricula', 'Mês/Ano', 'Idade (em anos)', 'Taxa de Rendimento', 'Capacidade'];
    }

    let csvList = list.map(item => {
      let itemParsed;

      if (params.rankingBy === 'score') {
        itemParsed = {
          score: getScoreFormatted(item.score),
          imov_id: item.imov_id,
          month_year: getDateParsed(item.month_year),
          age_year: item.age_year,
          income_rate: getTaxParsed(item.income_rate),
          capacity: getCapacityParsed(item.capacity),
        };

        if (params.investiment) {
          itemParsed.payback = getPaybackFormatted(item.payback);
        }
      }

      if (params.rankingBy === 'payback') {
        itemParsed = {
          payback: getPaybackFormatted(item.payback),
          imov_id: item.imov_id,
          month_year: getDateParsed(item.month_year),
          age_year: item.age_year,
          income_rate: getTaxParsed(item.income_rate),
          capacity: getCapacityParsed(item.capacity),
          score: getScoreFormatted(item.score),
        };
      }

      return itemParsed;
    });

    exportContentToFile(header, csvList, 'rankings');
  }

  handleExportEnrollments() {
    const { list } = this.props.rankings;

    let items = list.map(item => {
      let itemParsed;

      itemParsed = {
        imov_id: item.imov_id,
      };
      return itemParsed;
    });

    exportContentToFile(null, items, 'imoveis', 'txt');
  }

  updatePaginatedListAfterSlide(currentList) {
    const { page, rowsPerPage } = this.state;
    const paginatedList = currentList.slice(page, rowsPerPage);
    this.setState({ paginatedList });
  }

  initPagination(list) {
    const { rowsPerPage } = this.state;
    const paginatedList = list.slice(0, rowsPerPage);

    this.setState({ page: 0, paginatedList });
  }

  handleChangePage = (event, page) => {
    const { currentList, rowsPerPage } = this.state;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ page, paginatedList });
  };

  handleChangeRowsPerPage = event => {
    const { page, currentList } = this.state;
    const rowsPerPage = event.target.value;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ paginatedList, rowsPerPage });
  };

  handleGenerateRanking = params => {
    this.props.setRankingParams(params);
    this.props.setDialog({ field: 'isRankingForm', open: false });
    this.getRankings(params);
  };

  handleRequestSort = property => {
    const { order, orderBy, currentList, page, rowsPerPage } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    const offset = page * rowsPerPage;
    const newCurrentList = _orderBy(currentList, property, newOrder);
    const newPaginatedList = newCurrentList.slice(offset, offset + rowsPerPage);

    this.setState({
      order: newOrder,
      orderBy: property,
      currentList: newCurrentList,
      paginatedList: newPaginatedList,
    });
  };

  onClickNewAnalysis = (action, event) => {
    this.props.setDialog({ field: action, open: true });
  };

  render() {
    const { classes, analysis } = this.props;
    const params = this.props.rankings.params;
    const { list, loading } = this.props.rankings;
    const {
      page,
      paginatedList,
      currentList,
      order,
      orderBy,
      rowsPerPage,
      viewMode,
      taxErrorBilled,
      totalTaxErrorBilled,
      taxErrorModel,
      totalTaxErrorModel,
    } = this.state;
    const isLoading = loading || analysis.loading;
    const actions = [
      {
        name: 'Nova Análise',
        icon: <PlusIcon size={20} />,
        handle: this.onClickNewAnalysis.bind(this, 'isRankingForm'),
      },
      {
        name: 'Baixar Análise',
        icon: <DownloadIcon size={20} />,
        handle: this.handleExportAnalysis.bind(this),
      },
      {
        name: 'Baixar Matrículas',
        icon: <DownloadIcon size={20} />,
        handle: this.handleExportEnrollments.bind(this),
      },
      {
        name: 'Salvar Análise',
        icon: <FolderPlusIcon size={20} />,
        handle: this.handleSaveAnalysis.bind(this),
      },
    ];

    return (
      <React.Fragment>
        <div className={classes.mainContainer}>
          <Header
            loading={loading}
            title={`Substituição de hidrômetros (${params.priorization})`}
            location={params.location}
            section={params.section}
            investiment={params.investiment}
            capacity={params.capacity}
            route={params.route}
            monthYearReference
            actions={actions}
          />

          {!isLoading && (
            <Grid container style={{ paddingTop: '1rem' }}>
              <Grid container alignItems="center" item xs={6}>
                <Grid item xs={6} style={{ paddingRight: '.5rem', display: 'inline-flex', alignItems: 'center' }}>
                  <TableActionsText>Ordenar Ranking por:</TableActionsText>
                  &nbsp;
                  <Chip
                    avatar={params.rankingBy === 'score' ? getAvatar() : null}
                    clickable={!loading}
                    label="Score"
                    className={classNames(
                      classes.chip,
                      params.rankingBy === 'score' ? classes.activeChip : classes.inactiveChip
                    )}
                    onClick={!isLoading ? this.handleClick.bind(this, 'score') : null}
                  />
                  &nbsp;
                  <Chip
                    avatar={params.rankingBy === 'payback' ? getAvatar() : null}
                    clickable={!loading}
                    label="Payback"
                    className={classNames(
                      classes.chip,
                      params.rankingBy === 'payback' ? classes.activeChip : classes.inactiveChip
                    )}
                    onClick={!isLoading ? this.handleClick.bind(this, 'payback') : null}
                  />
                </Grid>

                <Grid xs={6} item>
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid container justify="flex-start" alignItems="center">
                      <Grid item xs={4}>
                        <TableActionsText>&nbsp;&nbsp;Qtd do ranking</TableActionsText>
                      </Grid>
                      <Grid item xs={6}>
                        <Slider
                          disabled={loading}
                          max={list.length}
                          classes={{
                            root: classes.sliderRoot,
                            trackBefore: classes.sliderTrackBefore,
                            thumb: classes.sliderThumb,
                          }}
                          value={params.maxRanking}
                          aria-labelledby="label"
                          onDragEnd={e => this.handleSlideDragEnd(e)}
                          onChange={(e, val) => this.handleSlideChange(e, val)}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Grid container justify="center">
                          <Grid item xs={10}>
                            <TextField
                              classes={{ root: classes.rootNumberInput }}
                              type="number"
                              value={params.maxRanking}
                              variant="outlined"
                              onChange={(e, val) => this.handleSlideChange(e, val, true)}
                              InputProps={{
                                classes: {
                                  input: classes.numberInput,
                                  notchedOutline: classes.fieldsetQtdRanking,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <ViewModeChip params={params} viewMode={viewMode} handleViewMode={this.handleViewMode} />
            </Grid>
          )}

          {!isLoading ? (
            <Grid container direction="row" classes={{ container: classes.container }}>
              <Grid item xs={6} className={classes.list}>
                <Grid item xs={12}>
                  <HydrometerList
                    page={page}
                    order={order}
                    orderBy={orderBy}
                    params={params}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleRequestSort={this.handleRequestSort}
                    paginatedList={paginatedList}
                    totalTaxErrorBilled={totalTaxErrorBilled}
                    taxErrorBilled={taxErrorBilled}
                    totalTaxErrorModel={totalTaxErrorModel}
                    taxErrorModel={taxErrorModel}
                    currentList={currentList}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '2rem' }}>
                {[YIELD_CURVE, GRAPH_RETURN].includes(viewMode) ? (
                  <Chart
                    viewMode={viewMode}
                    params={params}
                    yieldCurve={this.props.yield_curve}
                    data={currentList}
                  />
                ) : null}
                <HydrometerMap params={params} list={currentList} viewMode={viewMode} />
              </Grid>
            </Grid>
          ) : (
            <div>
              <CircularProgress className={classes.progress} />
            </div>
          )}
        </div>
        <BaseFormDialog onSubmit={params => this.handleGenerateRanking(params)} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ locations, rankings, yield_curve, dialog, analysis, hostile_zone }) => ({
  locations,
  rankings,
  yield_curve,
  dialog,
  analysis,
  hostile_zone,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setRankingParams,
    showMessage,
    getRankings,
    setDialog,
    postAnalysis,
  })
)(Ranking);
