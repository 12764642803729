import _ from 'lodash';
import moment from 'moment';
import * as JSZip from 'jszip';
import { doGet } from '../utils/http_functions';
import { Avatar } from '@material-ui/core';
import React from 'react';
import HydrometerMapIcon from 'react-feather/dist/icons/map';
import GraphReturnIcon from 'react-feather/dist/icons/trending-up';
import YieldCurveIcon from 'react-feather/dist/icons/bar-chart-2';
import CheckIcon from 'react-feather/dist/icons/check';
import {
  YIELD_CURVE,
  GRAPH_RETURN,
  GRAPH_PERFORMANCE,
  INDIVIDUAL_USE,
  HYDROMETER_MAP,
  ADMIN,
  USER,
} from '../constants';
import { Auth } from 'aws-amplify';

export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function objToArrays(obj) {
  const arr = Object.keys(obj).map(k => {
    if (obj[k].children) {
      obj[k].children = objToArrays(obj[k].children);
    }
    return obj[k];
  });
  return arr;
}

export function filesListToTree(list) {
  let pmap = {};

  list.forEach(entry => {
    const isFolder = !!entry.match(/\/$/);
    const parts = entry.replace(/\/$/, '').split('/');
    let childrensMount = pmap;
    parts.forEach((part, idx) => {
      if (!childrensMount[part]) {
        childrensMount[part] = {
          label: part,
          value: parts.slice(0, idx + 1).join('/'),
        };
      }

      if (idx < parts.length - 1 && !childrensMount[part].children) {
        childrensMount[part].children = {};
      }

      childrensMount[part].isFolder = !!childrensMount[part].children || (idx === parts.length - 1 && isFolder);

      childrensMount = childrensMount[part].children;
    });
  });

  return objToArrays(pmap);
}

export const unescapeEntities = str =>
  new DOMParser().parseFromString(str, 'text/html').documentElement.textContent;

export const flattenObject = data => {
  let result = {};
  const recurse = (cur, prop) => {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      let l;

      for (let i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop ? prop + '.' + i : '' + i);
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (let p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty) result[prop] = {};
    }
  };
  recurse(data, '');
  return result;
};

export const formatSaikuBrowserFile = data => {
  let browserTemp = {
    ids: {},
    paths: {},
    dates: {},
    fileSizes: {},
    extensions: {},
    names: {},
    is_folders: {},
  };
  let filesList = [];
  let flattened = flattenObject(data);
  const BASE_PATH = '/pentaho/api/repo/files/';

  Object.keys(flattened).forEach(key => {
    let customKey;

    if (key.includes('.id')) {
      customKey = key.replace('.id', '.');
      browserTemp.ids[customKey] = flattened[key];
    }

    if (key.includes('path')) {
      customKey = key.replace('path', '');
      browserTemp.paths[customKey] = flattened[key].substr(1);
    }

    if (key.includes('lastModifiedDate')) {
      customKey = key.replace('lastModifiedDate', '');
      browserTemp.dates[customKey] = flattened[key];
    }

    if (key.includes('fileSize')) {
      customKey = key.replace('fileSize', '');
      browserTemp.fileSizes[customKey] = flattened[key];
    }

    if (key.includes('name')) {
      customKey = key.replace('name', '');
      browserTemp.names[customKey] = flattened[key];
    }

    if (key.includes('folder')) {
      customKey = key.replace('folder', '');
      browserTemp.is_folders[customKey] = flattened[key];
    }
  });

  Object.keys(browserTemp.paths).forEach(key => {
    let extension = null;
    let id = browserTemp.ids[key];
    let path = browserTemp.paths[key];
    let date = browserTemp.dates[key];
    let name = browserTemp.names[key];
    let fileSize = browserTemp.fileSizes[key];
    let isFolder = browserTemp.is_folders[key] === 'true' ? true : false;

    path = `${path}${isFolder ? '/' : ''}`;
    fileSize = isFolder ? null : fileSize;

    if (!isFolder && name) {
      extension = name.split('.');

      if (extension && extension.length) {
        extension = extension[extension.length - 1];
      }
    }

    filesList.push({
      id: id,
      extension: extension,
      key: path,
      modified: date,
      relativeKey: path,
      size: fileSize,
      url: isFolder ? null : `${BASE_PATH}${path.replace(/\//g, ':')}/download`,
    });
  });

  return filesList;
};

export const getFilenameOrFoldernameFromPath = path => {
  const pathCopy = path.split('/');

  if (pathCopy && pathCopy.length) {
    if (pathCopy[pathCopy.length - 1]) {
      return pathCopy[pathCopy.length - 1];
    }

    if (pathCopy[pathCopy.length - 2]) {
      return pathCopy[pathCopy.length - 2];
    }
  }

  return path;
};

export const getFoldername = path => {
  const pathCopy = path.split('/');

  if (pathCopy && pathCopy.length) {
    return pathCopy[pathCopy.length - 1];
  }

  return path;
};

export const getFilenameWithoutExtension = filename => {
  return filename.substr(0, filename.lastIndexOf('.'));
};

export const getPathWithoutFilename = (path, isUseOnAPI) => {
  let formatted = path.substring(0, path.lastIndexOf('/'));

  if (isUseOnAPI) {
    formatted = formatted.replace(/\//g, ':');
  }

  return formatted;
};

async function readZipFile(file) {
  let unzippedFiles = [];
  const zip = new JSZip();
  const zipStream = await zip.loadAsync(file);
  const qtdFiles = Object.keys(zipStream.files).length;

  return new Promise((resolve, reject) => {
    zipStream.forEach(filePath => {
      if (filePath.includes('__MACOSX/')) {
        return;
      }

      zip
        .file(filePath)
        .async('text')
        .then(data => {
          unzippedFiles.push(JSON.parse(data));

          if (qtdFiles === unzippedFiles.length) {
            resolve(unzippedFiles);
          }
        })
        .catch(e => {
          console.log('error on unzip files', JSON.stringify(e));
          reject(null);
        });
    });
  });
}

async function readFile(file) {
  if (file && file.name && getExtensionFile(file.name) === 'zip') {
    return readZipFile(file);
  }

  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = e => {
      try {
        resolve([JSON.parse(e.target.result)]);
      } catch (e) {
        console.log('error parse file on import', e);
      }
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}

export async function validateSelectedFilters(file, entityName) {
  let itemsObj = {};
  let validationRes = {
    is_valid: true,
  };
  const files = await readFile(file);
  const itemsInUse = await getItemsInUse(entityName);

  if (!itemsInUse.resultset || !itemsInUse.resultset.length || !files || !files.length) {
    return validationRes;
  }

  itemsInUse.resultset.forEach(item => {
    itemsObj[item[1]] = {
      entity_id: item[0],
      filter_id: item[1],
      filter_name: item[2],
      entity_name: item[3],
    };
  });

  files.forEach(file => {
    if (!file.selectedFilters || !file.selectedFilters.length) {
      return;
    }

    file.selectedFilters.forEach(filter => {
      const itemInUse = itemsObj[filter.id];

      if (itemInUse) {
        validationRes = {
          is_valid: false,
          msg: `The ${itemInUse.filter_name} filter is being used in the ${itemInUse.entity_name} ${entityName}`,
        };
        return;
      }
    });
  });

  return validationRes;
}

async function getItemsInUse(entity) {
  let response = await doGet(`/pentaho/plugin/caern/api/list_all_items_in_use?paramENTITY=${entity}`);
  return await response.json();
}

function getExtensionFile(filename) {
  return filename.split('.').pop();
}

/**
 * Build url parameters key and value pairs from array or object
 * @param obj
 */
export function urlParam(obj) {
  return Object.keys(obj)
    .map(key => `param${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

export function exportContentToFile(header, rows, filename, format = 'csv') {
  let link;
  let content;
  let encodedUri;
  let mimeType = format;

  if (format === 'txt') {
    mimeType = 'plain';
  }

  content = `data:text/${mimeType};charset=utf-8,`;

  if (header && header.length) {
    content += header.join(',') + '\r\n';
  }

  rows.forEach(row => {
    const rowParsed = _.values(row).join(',');
    content += rowParsed + '\r\n';
  });

  encodedUri = encodeURI(content);
  link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename}_${moment().unix()}.${format}`);
  document.body.appendChild(link);
  link.click();
}

export function formatMoney(amount, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export function getTaxParsed(tax) {
  let taxParsed = tax;

  taxParsed = tax * 100;
  taxParsed = taxParsed.toFixed(2);

  return `${taxParsed}%`;
}

export function getPaybackFormatted(payback) {
  if (Math.abs(payback) > 1000 && isValidPayback(payback)) {
    return payback.toExponential(2);
  }

  return isValidPayback(payback) ? formatMoney(Math.ceil(payback), 0, ',', '.') : 'SEM RETORNO';
}

export function getScoreFormatted(score) {
  if (Math.abs(score) > 1000) {
    return score.toExponential(2);
  }

  return score;
}

export function getCapacityParsed(capacity) {
  return capacity.replace(/,/g, '.').replace('M3 / HORA', 'm³/h');
}

export function isValidPayback(payback) {
  return !isNaN(payback) && isFinite(payback);
}

export function getDateParsed(date) {
  let dateParsed = date;

  if (!dateParsed) {
    return;
  }

  dateParsed = `${dateParsed.toString().substring(4)}/${dateParsed.toString().substring(0, 4)}`;

  return dateParsed;
}

export function capitalize(name) {
  if (typeof name !== 'string') return '';
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export const getAvatar = (type, viewMode) => {
  let Icon = null;

  if (type === GRAPH_RETURN || type === GRAPH_PERFORMANCE) {
    Icon = GraphReturnIcon;
  } else if (type === HYDROMETER_MAP) {
    Icon = HydrometerMapIcon;
  } else if (type === YIELD_CURVE || type === INDIVIDUAL_USE) {
    Icon = YieldCurveIcon;
  } else {
    Icon = CheckIcon;
  }

  return (
    <Avatar style={{ backgroundColor: 'transparent' }}>
      <Icon style={{ paddingLeft: '.5rem' }} size={16} color={viewMode === type ? '#FFF' : '#777777'} />
    </Avatar>
  );
};

export const getMonthYearFormatted = data => {
  let newData = [];
  let year;
  let month;

  if (typeof data === 'object' || Array.isArray(data)) {
    return (newData = data.map(item => {
      year = item.toString().substring(0, 4);
      month = item.toString().substring(4, 6);

      return {
        value: item,
        label: capitalize(moment(`${year}-${month}-01`).format('MMM/YYYY')),
      };
    }));
  }

  year = data.toString().substring(0, 4);
  month = data.toString().substring(4, 6);
  newData = capitalize(moment(`${year}-${month}-01`).format('MMM/YYYY'));

  return newData;
};

export const getMonthYearReference = ({ type }) => {
  const date = moment().subtract(1, 'months');

  if (type === 'parsed') {
    return capitalize(date.format('MMM/YYYY'));
  }

  if (type === 'default') {
    return date.format('YYYYMM');
  }
};

export async function getUserData() {
  let data = {};
  let role = USER;
  let groups = [];
  let tenant = null;
  let adminList = [];
  let tenantList = [];
  let session = null;

  try {
    session = await Auth.currentSession();
    groups = session.idToken.payload['cognito:groups'];

    if (groups.length) {
      adminList = groups.filter(group => group.trim().toUpperCase() === ADMIN);
      tenantList = groups.filter(group => group.trim().toUpperCase() !== ADMIN);
    }

    if (tenantList && tenantList.length) {
      tenant = tenantList[0];
    }

    if (adminList && adminList.length) {
      role = ADMIN;
    }

    if (tenant) {
      tenant = tenant.toString().replace(`${process.env.REACT_APP_USERPOOLID}_`, '');
    }

    data = {
      name: session.idToken.payload.name,
      email: session.idToken.payload.email,
      tenant,
      role,
    };
  } catch (e) {
    console.error('[getUserData] Error', e);
  }

  return data;
}

export const getStrFromArray = array => {
  if (!array || !array.length) {
    return null;
  }

  if (!Array.isArray(array)) return array;

  return array
    .map(item => item.label)
    .sort()
    .join(', ');
};