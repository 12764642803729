import { GET_PERFORMANCE_REQUEST, GET_PERFORMANCE_ERROR, GET_PERFORMANCE_SUCCESS } from '../constants/actions';
import { doPost } from '../utils/http_functions';

export function getPerformance(filter) {
  return dispatch => {
    dispatch(getPerformanceRequest());
    doPost(`${process.env.REACT_APP_BACK_URL}/api/performance`, {
      paramFILTER: filter,
    })
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              try {
                dispatch(getPerformanceSuccess({ data: json }));
              } catch (e) {
                dispatch(getPerformanceError(`Error list performance: ${e.message}`));
              }
            })
            .catch(e => {
              dispatch(getPerformanceError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(getPerformanceError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        console.log('catch ', e);
        dispatch(getPerformanceError(`Error list performance: ${e.message}`));
      });
  };
}

/**
 * GET dispatchers
 */

const getPerformanceRequest = () => {
  return dispatch => {
    dispatch({
      type: GET_PERFORMANCE_REQUEST,
    });
  };
};

export const getPerformanceSuccess = data => {
  return dispatch => {
    dispatch({
      type: GET_PERFORMANCE_SUCCESS,
      payload: data,
    });
  };
};

const getPerformanceError = msg => {
  console.error(msg);
  return dispatch => {
    dispatch({
      type: GET_PERFORMANCE_ERROR,
      payload: { errorLoading: true },
    });
  };
};
