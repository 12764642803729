export default () => ({
  tableRow: {
    fontSize: '.9rem'
  },
  tableCell: {
    fontSize: '.9rem',
    fontWeight: 'bold'
  },
  avatarBlue: {
    backgroundColor: '#5bbefe'
  },
  avatarRed: {
    backgroundColor: '#e24453'
  },
  rootChip: {
    borderRadius: 32 / 2
  },
  chipBlue: {
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: '500',
    backgroundColor: '#5bbefe'
  },
  chipRed: {
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: '500',
    backgroundColor: '#e24453'
  },
  paginationCaption: {
    fontSize: '.8rem'
  },
  paginationToolbar: {
    fontSize: '.8rem',
    marginLeft: '2rem'
  },
  paginationSelectIcon: {
    right: '-4px'
  },
  cellErrorAnalysis: {
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  taxErrorLabel: {
    fontWeight: 500,
    fontSize: '.9rem',
    paddingLeft: '.8rem'
  },
  taxErrorValue: {
    fontWeight: 500,
    fontSize: '1.1rem',
    color: 'rgba(146, 146, 146, 0.95)'
  },
});