import Select from 'react-select';
import classNames from 'classnames';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import styles from './BaseForm.styles';
import CurrencyInput from 'react-currency-input';
import { showMessage } from '../../actions/snack';
import { getSections } from '../../actions/sections';
import { getRoutes } from '../../actions/routes';
import { getLocations } from '../../actions/locations';
import { getPerformanceMonthYear } from '../../actions/performance_month_year';
import { getAnalysisMonthYear } from '../../actions/analysis_month_year';
import { setRankingParams } from '../../actions/rankings';
import { getCapacities } from '../../actions/capacities';
import DropletIcon from 'react-feather/dist/icons/droplet';
import PerformanceIcon from 'react-feather/dist/icons/activity';
import PlusIcon from 'react-feather/dist/icons/plus-circle';
import EditIcon from 'react-feather/dist/icons/edit';
import { Grid, Button, withStyles, Typography, Chip } from '@material-ui/core';
import { getAvatar } from '../../utils/misc';
import FilterIcon from 'react-feather/dist/icons/sliders';
import { RANKING, PERFORMANCE } from '../../constants/routes';

class BaseForm extends Component {
  state = {
    maxRanking: 0,
    investiment: 0,
    priorization: '',
    locationLabel: '',
    rankingBy: 'score',
    isFormValid: false,
    locationValue: null,
    loadingSearchLocation: false,
    serviceType: 'Substituição de hidrômetros',
    capacity: [],
    section: [],
    isClearSection: false,
    route: [],
    performanceMonthYear: [],
    analysisMonthYear: [],
  };

  typeServiceOptions = [
    {
      label: 'Substituição de hidrômetros',
      value: 'Substituição de hidrômetros',
    },
  ];
  priorizationOptions = [{ label: 'Standard', value: 'Standard' }];

  componentWillMount() {
    const { dialog } = this.props;

    this.mountState();

    if (dialog.isPerformanceForm) {
      this.props.getPerformanceMonthYear();
    }

    if (dialog.isSavedAnalysisFilterForm) {
      this.props.getAnalysisMonthYear();
    }

    if (dialog.isHostileZoneForm && dialog.data) {
      this.loadFiltersToEdit();
    } else {
      this.props.getLocations();
      // this.props.getCapacities();
    }
  }

  loadFiltersToEdit = () => {
    const { params } = this.props.rankings;

    if (params.locationId) {
      this.props.getSections({
        field: 'localidade_id',
        value: params.locationId,
      });
    }

    if (params.section && params.section.length) {
      this.props.getRoutes({
        filter: JSON.stringify({
          setor_comercial_id: {
            $in: params.section.map(item => item.value),
          },
        }),
      });
    }
  };

  mountState() {
    const { params } = this.props.rankings;

    this.setState({
      serviceType: params.serviceType || 'Substituição de hidrômetros',
      priorization: params.priorization || 'Standard',
      locationLabel: params.location || '',
      locationValue: params.locationId || null,
      investiment: params.investiment || 0,
      rankingBy: params.rankingBy || 'score',
      section: params.section || '',
      capacity: params.capacity || '',
      route: params.route || '',
      performanceMonthYear: params.performanceMonthYear || '',
      analysisMonthYear: params.analysisMonthYear || '',
    });
  }

  handleSelectChange = name => e => {
    this.setState({ [name]: e.value });
  };

  handleCapacityChange = value => {
    this.setState({ capacity: value });
  };

  handleRouteChange = value => {
    this.setState({ route: value });
  };

  handleMonthYearChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleLocationChange = e => {
    const rules = this.getViewRules();

    if (!e) {
      this.setState({
        locationValue: null,
        locationLabel: null,
        section: [],
        isClearSection: true,
        route: [],
      });
      return;
    }

    this.setState({
      locationValue: e.value,
      locationLabel: e.label,
      isClearSection: false,
      section: [],
      route: [],
    });

    if (rules.isShowSection) {
      this.props.getSections({
        field: 'localidade_id',
        value: e.value ? e.value : null,
        is_filter_hostile_zone: rules.isFilterHostileZone,
      });
    }
  };

  handleSectionChange = value => {
    if (!value || !value.length) {
      this.setState({ section: value, route: [] });
      return;
    }

    let sections = [];
    const { route } = this.state;
    const rules = this.getViewRules();
    const routesToMaintain = route.filter(r => {
      let found = false;

      value.every(section => {
        if (r.label.indexOf(section.label) !== -1) found = true;
        return !found; // if === false => break
      });

      return found;
    });

    this.setState({ section: value, route: routesToMaintain });

    if (rules.isShowRoute) {
      sections = value.map(item => item.value);

      this.props.getRoutes({
        filter: JSON.stringify({ setor_comercial_id: { $in: sections } }),
        is_filter_hostile_zone: rules.isFilterHostileZone,
      });
    }
  };

  handleTextChange = name => e => {
    this.setState({ [name]: e.target.value });
  };

  handleClick = rankingBy => {
    this.setState({ rankingBy });
  };

  handleSlideChange(e, value) {
    let slideValue = e.target.value ? e.target.value : value;

    if (slideValue > 100) {
      slideValue = 100;
    }

    if (slideValue < 0) {
      slideValue = 0;
    }

    this.setState({ maxRanking: slideValue ? Math.round(slideValue) : 0 });
  }

  isValid(payload) {
    let isValid = true;
    const { dialog, showMessage } = this.props;

    if (dialog.isRankingForm) {
      if (!payload.serviceType) {
        showMessage('Por favor, selecione o tipo de serviço!');
        isValid = false;
      }

      if (!payload.locationId) {
        showMessage('Por favor, selecione a localidade!');
        isValid = false;
      }

      if (!payload.investiment && payload.rankingBy === 'payback') {
        showMessage('O valor de investimento deve ser maior que R$ 0,00 para Payback!');
        isValid = false;
      }
    }

    if (dialog.isHostileZoneForm) {
      if (!payload.locationId) {
        showMessage('Por favor, selecione o tipo de serviço!');
        isValid = false;
      }

      if (!payload.section || !payload.section.length) {
        showMessage('Por favor, selecione ao menos um setor!');
        isValid = false;
      }

      if (!payload.route || !payload.route.length) {
        showMessage('Por favor, selecione ao menos uma rota!');
        isValid = false;
      }
    }

    return isValid;
  }

  getDestinyDialog = () => {
    const { dialog } = this.props;

    if (dialog.isRankingForm) {
      return RANKING;
    }

    if (dialog.isPerformanceForm) {
      return PERFORMANCE;
    }
  };

  getOriginDialog = () => {
    let origin = null;
    const { dialog } = this.props;

    Object.keys(dialog).forEach(key => {
      if (key === 'data') return;

      if (dialog[key]) {
        origin = key;
      }
    });

    return origin;
  };

  onSubmit = () => {
    const { dialog } = this.props;
    const {
      serviceType,
      locationLabel,
      locationValue,
      section,
      route,
      investiment,
      rankingBy,
      priorization,
      capacity,
      performanceMonthYear,
      analysisMonthYear,
    } = this.state;
    const filter = {
      serviceType,
      location: locationLabel,
      locationId: locationValue,
      investiment: investiment ? +investiment.toString().replace('.', '').replace(',', '.') : 0,
      rankingBy,
      maxRanking: 0,
      priorization,
      capacity,
      section,
      route,
      performanceMonthYear,
      analysisMonthYear,
      path: this.getDestinyDialog(),
      origin_dialog: this.getOriginDialog(),
      is_edit: this.isEditForm(),
      active: dialog.data ? dialog.data.active : true,
    };

    if (!this.isValid(filter)) {
      return;
    }

    this.props.onSubmit(filter);
  };

  renderTitle = () => {
    const { classes, dialog } = this.props;
    const isEditForm = this.isEditForm();

    if (dialog.isRankingForm) {
      return (
        <div className={classes.titleContainer}>
          <div>
            <DropletIcon size={28} />
          </div>
          <div className={classes.title}>Substituição de Hidrômetros</div>
        </div>
      );
    }

    if (dialog.isPerformanceForm) {
      return (
        <div className={classes.titleContainer}>
          <div>
            <PerformanceIcon size={28} />
          </div>
          <div className={classes.title}>Análise da perfomance das trocas </div>
        </div>
      );
    }

    if (dialog.isSavedAnalysisFilterForm || dialog.isHostileZoneFilterForm) {
      return (
        <div className={classes.titleContainer}>
          <div>
            <FilterIcon size={28} />
          </div>
          <div className={classes.title}>Filtrar</div>
        </div>
      );
    }

    if (dialog.isHostileZoneForm && !isEditForm) {
      return (
        <div className={classes.titleContainer}>
          <div>
            <PlusIcon size={28} />
          </div>
          <div className={classes.title}>Criar Zona Hostil</div>
        </div>
      );
    }

    if (dialog.isHostileZoneForm && isEditForm) {
      return (
        <div className={classes.titleContainer}>
          <div>
            <EditIcon size={28} />
          </div>
          <div className={classes.title}>Editar Zona Hostil</div>
        </div>
      );
    }
  };

  isEditForm = () => {
    let isEdit = false;
    const { dialog } = this.props;
    const isForm = dialog.isHostileZoneForm;

    if (isForm && dialog.data) {
      isEdit = true;
    }

    return isEdit;
  };

  getViewRules = () => {
    let isEnableLocation = true;
    const {
      isRankingForm,
      isPerformanceForm,
      isSavedAnalysisFilterForm,
      isHostileZoneFilterForm,
      isHostileZoneForm,
    } = this.props.dialog;
    const isShowSection = isRankingForm || isPerformanceForm || isSavedAnalysisFilterForm || isHostileZoneForm;
    const isShowRoute = isRankingForm || isHostileZoneForm;
    const isFilterForm = isSavedAnalysisFilterForm || isHostileZoneFilterForm;
    const isSaveForm = isHostileZoneForm;
    const isFilterHostileZone = isRankingForm;
    const { locations, sections, routes } = this.props;
    const isLoading = locations.loading || sections.loading || routes.loading;

    if (this.isEditForm()) {
      isEnableLocation = false;
    }

    return {
      isLoading,
      isEnableLocation,
      isRankingForm,
      isPerformanceForm,
      isSavedAnalysisFilterForm,
      isShowSection,
      isShowRoute,
      isFilterForm,
      isSaveForm,
      isFilterHostileZone,
    };
  };

  render() {
    const rules = this.getViewRules();
    const { classes, locations, capacities, sections, routes, performance_month_year, analysis_month_year } =
      this.props;
    const {
      serviceType,
      priorization,
      locationLabel,
      route,
      investiment,
      rankingBy,
      section,
      capacity,
      performanceMonthYear,
      analysisMonthYear,
      isClearSection,
    } = this.state;

    return (
      <Grid container item direction="column" spacing={24} className={classes.formDialog}>
        {this.renderTitle()}
        {rules.isRankingForm ? (
          <Grid item>
            <Typography className={classes.defaultLabel}>Modo de Inteligência</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              options={this.priorizationOptions}
              onChange={this.handleSelectChange('priorization')}
              value={{ label: priorization || 'Selecione uma priorização' }}
            />
          </Grid>
        ) : null}
        {rules.isRankingForm ? (
          <Grid item>
            <Typography className={classes.defaultLabel}>Tipo de Serviço</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              options={this.typeServiceOptions}
              onChange={this.handleSelectChange('serviceType')}
              value={{ label: serviceType || 'Selecione um tipo de serviço' }}
            />
          </Grid>
        ) : null}
        <Grid item>
          <Typography className={classes.labelLocation}>Localidade</Typography>
          <Select
            isDisabled={!rules.isEnableLocation || locations.loading}
            isLoading={locations.loading}
            isClearable
            noOptionsMessage={() => 'Sem dados'}
            options={locations.list}
            onChange={this.handleLocationChange}
            value={{
              label: locationLabel || (locations.loading ? 'Carregando...' : 'Selecione uma localidade'),
            }}
          />
        </Grid>
        {rules.isShowSection ? (
          <Grid item>
            <Typography className={classes.labelLocation}>Setor Comercial</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              isMulti
              isClearable
              value={section}
              options={!isClearSection ? sections.list : []}
              isDisabled={sections.loading}
              isLoading={sections.loading}
              placeholder={sections.loading ? 'Carregando...' : 'Selecione um ou mais setores'}
              onChange={this.handleSectionChange}
            />
          </Grid>
        ) : null}
        {rules.isShowRoute ? (
          <Grid item>
            <Typography className={classes.defaultLabel}>Rota</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              isMulti
              isClearable
              isDisabled={routes.loading}
              isLoading={routes.loading}
              placeholder={routes.loading ? 'Carregando...' : 'Selecione uma ou mais rotas'}
              value={route}
              options={routes.list}
              onChange={this.handleRouteChange}
            />
          </Grid>
        ) : null}
        {rules.isPerformanceForm ? (
          <Grid item>
            <Typography className={classes.defaultLabel}>Mês/Ano da Troca</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              isMulti
              placeholder={'Selecione um ou mais Mês/Ano'}
              value={performanceMonthYear}
              options={performance_month_year.list}
              onChange={this.handleMonthYearChange.bind(this, 'performanceMonthYear')}
            />
          </Grid>
        ) : null}
        {rules.isSavedAnalysisFilterForm ? (
          <Grid item>
            <Typography className={classes.defaultLabel}>Mês/Ano da Análise</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              isMulti
              placeholder={'Selecione um ou mais Mês/Ano'}
              value={analysisMonthYear}
              options={analysis_month_year.list}
              onChange={this.handleMonthYearChange.bind(this, 'analysisMonthYear')}
            />
          </Grid>
        ) : null}
        {rules.isRankingForm ? (
          <Grid item>
            <Typography className={classes.defaultLabel}>Capacidade (m³/h)</Typography>
            <Select
              noOptionsMessage={() => 'Sem dados'}
              isDisabled={capacities.loading}
              isLoading={capacities.loading}
              isMulti
              placeholder={capacities.loading ? 'Carregando...' : 'Selecione uma ou mais capacidades'}
              value={capacity}
              options={capacities.list}
              onChange={this.handleCapacityChange}
            />
          </Grid>
        ) : null}
        {rules.isRankingForm ? (
          <Grid item>
            <Typography className={classes.labelBudget}>Valor de investimento por troca</Typography>
          </Grid>
        ) : null}
        {rules.isRankingForm ? (
          <Grid container direction="row" className={classes.containerInvestiment}>
            <Grid item xs={2}>
              <Typography style={{ fontSize: '2rem' }}>R$</Typography>
            </Grid>

            <Grid item xs={4} style={{ marginTop: '0.2rem' }}>
              <CurrencyInput
                decimalSeparator=","
                thousandSeparator="."
                value={investiment}
                className={classes.investimentInput}
                onChangeEvent={this.handleTextChange('investiment')}
              />
            </Grid>
          </Grid>
        ) : null}
        {rules.isRankingForm ? (
          <Grid item>
            <Typography className={classes.labelBudget}>Ordenar ranking por</Typography>
          </Grid>
        ) : null}
        {rules.isRankingForm ? (
          <Grid container direction="row" className={classes.containerInvestiment}>
            <Grid item xs={3}>
              <Chip
                avatar={rankingBy === 'score' ? getAvatar() : null}
                label="Score"
                className={classNames(
                  classes.chip,
                  rankingBy === 'score' ? classes.activeChip : classes.inactiveChip
                )}
                onClick={this.handleClick.bind(this, 'score')}
              />
            </Grid>
            &nbsp;&nbsp;
            <Grid item xs={3}>
              <Chip
                avatar={rankingBy === 'payback' ? getAvatar() : null}
                label="Payback"
                className={classNames(
                  classes.chip,
                  rankingBy === 'payback' ? classes.activeChip : classes.inactiveChip
                )}
                onClick={this.handleClick.bind(this, 'payback')}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          classes={{ container: classes.containerButtonRanking }}
        >
          <Grid item className={classes.gridButton}>
            <Button
              disabled={rules.isLoading}
              justify-content="center"
              className={classNames(classes.rankingButton)}
              onClick={this.onSubmit}
            >
              {rules.isRankingForm ? <span>Gerar Análise</span> : null}
              {rules.isPerformanceForm ? <span>Ver Performance</span> : null}
              {rules.isFilterForm ? <span>Aplicar Filtros</span> : null}
              {rules.isSaveForm ? <span>Salvar</span> : null}
            </Button>
          </Grid>
          &nbsp;
          <Grid item className={classes.gridButton}>
            <Button
              component="span"
              justify-content="center"
              className={classes.cancelButton}
              onClick={this.props.onCancel}
            >
              <span>Cancelar</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  locations,
  rankings,
  capacities,
  sections,
  routes,
  dialog,
  performance_month_year,
  analysis_month_year,
}) => ({
  locations,
  rankings,
  capacities,
  sections,
  routes,
  dialog,
  performance_month_year,
  analysis_month_year,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getLocations,
    getCapacities,
    setRankingParams,
    showMessage,
    getSections,
    getRoutes,
    getPerformanceMonthYear,
    getAnalysisMonthYear,
  })
)(BaseForm);
