import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavLink from 'react-router-dom/NavLink';
import compose from 'recompose/compose';
import { loadDashboards } from '../../actions/links';
import * as constants from '../../constants/routes';
import styles from './Links.styles';
import ExitToApp from 'react-feather/dist/icons/log-out';
import logo from '../../assets/logo.png';
import DropletIcon from 'react-feather/dist/icons/droplet';
import PerformanceIcon from 'react-feather/dist/icons/activity';
import FolderIcon from 'react-feather/dist/icons/folder';
import HostileZoneIcon from 'react-feather/dist/icons/alert-triangle';
import { Menu, MenuItem } from '@material-ui/core';
import { logout } from '../../utils/cognito';

const links = [
  {
    show_in_home: false,
    title: 'Substituição de Hidrômetros',
    href: constants.HOME,
    icon: <DropletIcon />,
  },
  {
    show_in_home: false,
    title: 'Monitoramento de Performance',
    href: constants.HOME,
    icon: <PerformanceIcon />,
  },
  {
    show_in_home: false,
    title: 'Análises Salvas',
    href: constants.ANALYSIS,
    icon: <FolderIcon />,
  },
  {
    show_in_home: false,
    title: 'Zonas Hostis',
    href: constants.HOSTILE_ZONE,
    icon: <HostileZoneIcon />,
  },
];

class Links extends Component {
  state = {
    open: true,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  isLinkSelected = idx => {
    let isLinkSelected = false;
    const path = this.props.location.pathname;
    const listItem = links[idx];

    if (listItem.children && listItem.children && listItem.children.items && listItem.children.items.length) {
      listItem.children.items.forEach(item => {
        if (item.href === path) {
          isLinkSelected = true;
        }
      });
    }

    if (!listItem.chidren && listItem.href && listItem.href === path) {
      isLinkSelected = true;
    }

    return isLinkSelected;
  };

  handleMenuItemClose = () => this.setState({ anchorEl: null });

  handleMenuItemClick = (href, event) => {
    this.handleMenuItemClose();
    this.props.history.push(href);
  };

  handleClickListItem = (index, event) => {
    this.setState({ anchorEl: event.currentTarget, selectedIndex: index });
  };

  renderSubMenu = () => {
    const { anchorEl, selectedIndex } = this.state;
    let listItem = links[selectedIndex];

    if (!listItem || !listItem.children || !listItem.children.header) {
      return;
    }

    if (!listItem.children.items || !listItem.children.items.length) {
      return;
    }

    return (
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={this.handleMenuItemClose}
      >
        <MenuItem style={{ fontWeight: 'bold' }}>{listItem.children.header}</MenuItem>
        {listItem.children.items.map((option, index) => (
          <MenuItem key={index} selected={false} onClick={this.handleMenuItemClick.bind(this, option.href)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  getLink = (opts, classes, idx) => (
    <ListItem
      button
      title={opts.title}
      component={opts.href ? NavLink : null}
      disableRipple={true}
      key={idx}
      onClick={opts.children ? this.handleClickListItem.bind(this, idx) : null}
      to={opts.href ? opts.href : null}
      selected={this.isLinkSelected(idx)}
      classes={{
        button: classes.listTool,
        gutters: classes.gutters,
        root: classes.bgIcon,
      }}
    >
      <ListItemIcon
        classes={{
          root: classes.listToolIcon,
        }}
      >
        {opts.icon}
      </ListItemIcon>
    </ListItem>
  );

  getAvailableLinks = () => {
    let availableLinks = links;
    const path = this.props.location.pathname;

    if (path === constants.HOME) {
      availableLinks = availableLinks.filter(link => link.show_in_home);
    }

    return availableLinks;
  };

  render() {
    const availableLinks = this.getAvailableLinks();
    const { classes } = this.props;

    return (
      <div className={classes.DrawerContainer}>
        <List component="nav" classes={{ root: classes.linksContainer }}>
          <ListItem to={constants.HOME} component={NavLink}>
            <ListItemIcon>
              <div className={classes.logo}>
                <img src={logo} alt="Caern" />
              </div>
            </ListItemIcon>
          </ListItem>

          {availableLinks.map((l, idx) => this.getLink(l, classes, idx))}
        </List>
        <List component="nav" classes={{ root: classes.ToolsContainer }}>
          <ListItem
            button={true}
            disableRipple={true}
            onClick={logout}
            classes={{ button: classes.listTool, gutters: classes.gutters, root: classes.bgIcon }}
          >
            <ListItemIcon classes={{ root: classes.listToolIcon }}>
              <ExitToApp />
            </ListItemIcon>
          </ListItem>
        </List>
        {this.renderSubMenu()}
      </div>
    );
  }
}

const mapStateToProps = ({ links, reports, products }) => ({ links, reports, products });

export default withRouter(compose(withStyles(styles), connect(mapStateToProps, { loadDashboards }))(Links));
