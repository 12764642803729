import { SET_TITLE } from '../constants/actions';

export const appBase = 'Caern';

export function setTitle(title) {
    return function(dispatch) {
      window.document.title = `${appBase} ${title}`
      dispatch({ 
        type: SET_TITLE,
        payload: title
      });
    }
}