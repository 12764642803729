import { SET_DIALOG } from '../constants/actions';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DIALOG:
      return {
        [action.payload.field]: action.payload.open,
        data: action.payload.data
      };
    default:
      return state;
  }
}