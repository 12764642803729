import { SET_PERFORMANCE_MONTH_YEAR, LOAD_PERFORMANCE_MONTH_YEAR_ERROR } from '../constants/actions';
import { doGet } from '../utils/http_functions';
import { getMonthYearFormatted } from '../utils/misc';

export function getPerformanceMonthYear() {
  return dispatch => {
    doGet(`${process.env.REACT_APP_BACK_URL}/api/performance`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              try {
                const dataFormatted = getMonthYearFormatted(json[0].items);
                dispatch(setDataList(dataFormatted));
              } catch (e) {
                dispatch(loadDataListError(`Error list performance_month_year: ${e.message}`));
              }
            })
            .catch(e => {
              dispatch(loadDataListError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(loadDataListError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(loadDataListError(`Error list PerformanceMonthYear: ${e.message}`));
      });
  };
}

function setDataList(data) {
  return dispatch => {
    dispatch({
      type: SET_PERFORMANCE_MONTH_YEAR,
      payload: data,
    });
  };
}

function loadDataListError() {
  return dispatch => {
    dispatch({
      type: LOAD_PERFORMANCE_MONTH_YEAR_ERROR,
      payload: { errorLoading: true },
    });
  };
}
