import {
  GET_RANKINGS_REQUEST,
  GET_RANKINGS_SUCCESS,
  GET_RANKINGS_ERROR,
  SET_RANKING_PARAMS,
  SET_RANKING_INIT_PARAMS
} from '../constants/actions';

const INITIAL_STATE = {
  list: [],
  params: {
    maxRanking: 0
  },
  loading: false,
  errorLoading: false,
  errorLoadingMsg: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_RANKINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_RANKINGS_SUCCESS:
      return {
        ...state,
        params: {
          ...state.params,
          maxRanking: action.payload.length
        },
        list: action.payload,
        loading: false
      };
    case GET_RANKINGS_ERROR:
      return {
        ...state,
        loading: false,
        errorLoading: true
      };
    case SET_RANKING_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    case SET_RANKING_INIT_PARAMS:
      return {
        ...state,
        params: INITIAL_STATE.params
      };
    default:
      return state;
  }
}