import {
  ASK_YES_NO,
  CLOSE_YES_NO,
} from '../constants/actions';

const INITIAL_STATE = {
  active: false,
  question: '',
  title: 'Confirm',
  yesLabel: 'Yes',
  noLabel: 'No',
  yesCallback: () => { },
  noCallback: () => { },
  cancelCallback: () => { }
}

export default function (state = { open: true }, { type, payload }) {
  switch (type) {
    case ASK_YES_NO:
      return {
        active: true,
        question: payload.question || INITIAL_STATE.question,
        title: payload.title || INITIAL_STATE.title,
        yesLabel: payload.yesLabel || INITIAL_STATE.yesLabel,
        noLabel: payload.noLabel || INITIAL_STATE.noLabel,
        yesCallback: payload.yesCallback || INITIAL_STATE.yesCallback,
        noCallback: payload.noCallback || INITIAL_STATE.noCallback,
        cancelCallback: payload.cancelCallback || INITIAL_STATE.cancelCallback,
      };
    case CLOSE_YES_NO:
      return { ...INITIAL_STATE }
    default:
      return state;
  }
}