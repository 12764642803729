import {
  GET_HOSTILE_ZONE_REQUEST,
  GET_HOSTILE_ZONE_SUCCESS,
  GET_HOSTILE_ZONE_ERROR,
  POST_HOSTILE_ZONE_REQUEST,
  POST_HOSTILE_ZONE_SUCCESS,
  POST_HOSTILE_ZONE_ERROR,
  DELETE_HOSTILE_ZONE_REQUEST,
  DELETE_HOSTILE_ZONE_SUCCESS,
  DELETE_HOSTILE_ZONE_ERROR,
} from '../constants/actions';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_HOSTILE_ZONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_HOSTILE_ZONE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_HOSTILE_ZONE_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case POST_HOSTILE_ZONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case POST_HOSTILE_ZONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case POST_HOSTILE_ZONE_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case DELETE_HOSTILE_ZONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_HOSTILE_ZONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case DELETE_HOSTILE_ZONE_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}