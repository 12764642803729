import React from 'react';
import AlertIcon from 'react-feather/dist/icons/alert-circle';

const ForbiddenHost = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      fontSize: '30px',
    }}>
      <div>
        <AlertIcon size={50} color="red" />
        <h1>Acesso não autorizado</h1>
        <p>
          Você está tentando acessar a aplicação de um domínio não autorizado.
        </p>
        <p>
          Por favor, entre em contato com a <b>Oncase</b>.
        </p>
      </div>
    </div>
  );
};

export default ForbiddenHost;
