import {
  SET_ROUTE_LIST, SET_ROUTE_LOADING, LOAD_ROUTE_LIST_ERROR
} from '../constants/actions';

const INITIAL_STATE = {
  list: [],
  loading: false,
  errorLoading: false,
  errorLoadingMsg: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ROUTE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ROUTE_LIST:
      return {
        ...state,
        loading: false,
        errorLoading: false,
        list: action.payload,
      };
    case LOAD_ROUTE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorLoading: true
      };
    default:
      return state;
  }
}