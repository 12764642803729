import { SET_ANALYSIS_MONTH_YEAR, LOAD_ANALYSIS_MONTH_YEAR_ERROR } from '../constants/actions';
import { doGet } from '../utils/http_functions';
import { getMonthYearFormatted } from '../utils/misc';

export function getAnalysisMonthYear() {
  return dispatch => {
    doGet(`${process.env.REACT_APP_BACK_URL}/api/analysis`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              const dataFormatted = getMonthYearFormatted(json);

              dispatch(setDataList(dataFormatted));
            })
            .catch(e => {
              dispatch(loadDataListError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(loadDataListError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(loadDataListError(`Error list AnalysisMonthYear: ${e.message}`));
      });
  };
}

function setDataList(data) {
  return dispatch => {
    dispatch({
      type: SET_ANALYSIS_MONTH_YEAR,
      payload: data,
    });
  };
}

function loadDataListError() {
  return dispatch => {
    dispatch({
      type: LOAD_ANALYSIS_MONTH_YEAR_ERROR,
      payload: { errorLoading: true },
    });
  };
}
