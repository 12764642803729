import { Auth } from 'aws-amplify';

export const jsonAcceptHeaders = {
  Accept: 'application/json;',
};

const jsonPostHeaders = {
  'Content-Type': 'application/json; charset=utf-8',
};

const jsonPostFormHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
};

const textPlainFormHeaders = {
  'Content-Type': 'text/plain',
};

const addHeaders = (opts, headers) => {
  if (headers !== undefined) opts['headers'] = { ...opts['headers'], ...headers };
  return opts;
};

export async function doPost(url, body) {
  const token = await getToken();

  let opts = addHeaders(
    {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
    },
    { ...jsonPostHeaders, Authorization: token }
  );
  return fetch(url, opts);
}
export function doFormPost(url, params, headers) {
  const searchParams = Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    })
    .join('&');

  let opts = addHeaders(
    {
      method: 'POST',
      credentials: 'same-origin',
      body: decodeURIComponent(searchParams),
    },
    { ...headers, ...jsonPostFormHeaders }
  );
  return fetch(url, opts);
}

export function doPostFile(url, body, headers) {
  let opts = addHeaders(
    {
      method: 'POST',
      credentials: 'same-origin',
      body: body,
    },
    { ...headers }
  );
  return fetch(url, opts);
}

export async function doPut(url, body, headers, isPayloadTextPlain) {
  const token = await getToken();
  let extraHeaders = isPayloadTextPlain ? { ...textPlainFormHeaders } : { ...jsonPostFormHeaders };
  let opts = addHeaders(
    {
      method: 'PUT',
      credentials: 'same-origin',
      body: isPayloadTextPlain ? body : JSON.stringify(body),
    },
    { ...headers, ...extraHeaders, Authorization: token }
  );
  return fetch(url, opts);
}

export async function doDelete(url, body, headers) {
  const token = await getToken();

  let opts = addHeaders(
    {
      method: 'DELETE',
      credentials: 'same-origin',
      body: JSON.stringify(body),
    },
    { ...headers, ...jsonPostHeaders, Authorization: token }
  );
  return fetch(url, opts);
}

export async function doGet(url, headers, isS3) {
  const token = await getToken();
  const params = { ...headers };
  let opts = null;

  if (!isS3) {
    params.Authorization = token;

    opts = addHeaders(
      {
        method: 'GET',
        credentials: 'same-origin',
      },
      params
    );
  }

  return fetch(url, opts);
}

async function getToken() {
  try {
    const session = await Auth.currentSession();

    return `Bearer ${session.idToken.jwtToken}`;
  } catch (err) {
    console.error(err);

    return '';
  }
}
