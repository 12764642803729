import {
  YIELD_CURVE_INIT,
  GET_YIELD_CURVE_REQUEST,
  GET_YIELD_CURVE_SUCCESS,
  GET_YIELD_CURVE_ERROR
} from '../constants/actions';

const INITIAL_STATE = {
  item: {},
  initMsg: "Selecione um hidrômetro para visualizar a curva de rendimento.",
  loading: false,
  errorLoading: false,
  errorLoadingMsg: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_YIELD_CURVE_REQUEST:
      return {
        ...state,
        initMsg: null,
        loading: true
      };
    case GET_YIELD_CURVE_SUCCESS:
      return {
        ...state,
        initMsg: null,
        item: action.payload,
        loading: false
      };
    case GET_YIELD_CURVE_ERROR:
      return {
        ...state,
        initMsg: null,
        loading: false,
        errorLoading: true
      };
    case YIELD_CURVE_INIT:
      return INITIAL_STATE;
    default:
      return state;
  }
}