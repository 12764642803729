import {
  SET_PERFORMANCE_MONTH_YEAR,
  LOAD_PERFORMANCE_MONTH_YEAR_ERROR
} from '../constants/actions';

const INITIAL_STATE = {
  list: [],
  loading: false,
  errorLoading: false,
  errorLoadingMsg: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PERFORMANCE_MONTH_YEAR:
      return {
        ...state,
        list: action.payload,
      };
    case LOAD_PERFORMANCE_MONTH_YEAR_ERROR:
      return {
        ...state,
        errorLoading: true
      };
    default:
      return state;
  }
}