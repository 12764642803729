import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class NotFound extends Component {
  render() {
    return (
      <div>
        <Trans>notfound_label</Trans>
      </div>
    );
  }
}

export default NotFound;
