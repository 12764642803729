export const GRAPH_RETURN = 'GRAPH_RETURN';
export const YIELD_CURVE = 'YIELD_CURVE';
export const GRAPH_PERFORMANCE = 'GRAPH_PERFORMANCE';
export const INDIVIDUAL_USE = 'INDIVIDUAL_USE';
export const HYDROMETER_MAP = 'HYDROMETER_MAP';
export const FORMAT_CURRENCY = 'FORMAT_CURRENCY';
export const TOTAL_PROFIT_REAL_IA = 'TOTAL_PROFIT_REAL_IA';
export const TOTAL_PROFIT_REAL_ANT = 'TOTAL_PROFIT_REAL_ANT';
export const QTD_TOTAL_CHANGES = 'QTD_TOTAL_CHANGES';
export const QTD_TOTAL_CHANGES_BY_LOCATION = 'QTD_TOTAL_CHANGES_BY_LOCATION';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const LDAP_GROUP_SELECTED = 'ldapGroupSelected';
export const LDAP_GROUPS = 'ldapGroups';
export const LDAP_USERNAME = 'ldapUsername';
export const LDAP_PASSWORD = 'ldapPassword';
export const LDAP_IS_MUST_SELECT_GROUP = 'ldapIsMustSelectGroup';
export const INVALID_HOSTS = ['dev.caern.scora.ai', 'caern.scora.ai'];
