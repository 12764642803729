import { SET_SECTION_LIST, SET_SECTION_LOADING, LOAD_SECTION_LIST_ERROR } from '../constants/actions';
import { doGet } from '../utils/http_functions';
import { urlParam } from '../utils/misc';
import { getHostileZone, getQueryHostileZone } from './hostile_zone';

function getSectionsWithHostileZone(sections, hostileZones) {
  let hostileZone = {};
  let newSections = [];
  let hostileZoneRoutes = [];
  let hostileZoneSectionsByKey = {};

  if (hostileZones && hostileZones.length) {
    hostileZone = hostileZones[0];
  }

  if (!hostileZone.section || !hostileZone.section.length) {
    return sections;
  }

  if (hostileZone.route && hostileZone.route.length) {
    hostileZoneRoutes = hostileZone.route;
  }

  hostileZone.section.forEach(item => {
    hostileZoneSectionsByKey[item.value] = item;
  });

  sections.forEach(section => {
    let label = section.label;
    let isDisabled = false;

    if (hostileZoneSectionsByKey[section.value] && !hostileZoneRoutes.length) {
      isDisabled = true;
      label = `${section.label} (Em zona hostil)`;
    }

    newSections.push({ ...section, isDisabled, label });
  });

  return newSections;
}

export function getSections({ field, value, is_filter_hostile_zone }) {
  const sectionFilter = { filter: JSON.stringify({ [field]: value }) };

  return dispatch => {
    dispatch(setSectionLoading());
    doGet(`${process.env.REACT_APP_BACK_URL}/api/comercial?${urlParam(sectionFilter)}`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              let hostileZoneQuery;

              dispatch(setSectionList(json));
              if (is_filter_hostile_zone) {
                hostileZoneQuery = getQueryHostileZone({
                  locationId: value,
                  active: true,
                });
                dispatch(
                  getHostileZone(hostileZoneQuery, hostileZones => {
                    json = getSectionsWithHostileZone(json, hostileZones);
                    dispatch(setSectionList(json));
                  })
                );
              } else {
                dispatch(setSectionList(json));
              }
            })
            .catch(e => {
              dispatch(loadSectionListError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(loadSectionListError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(loadSectionListError(`Error list sections: ${e.message}`));
      });
  };
}

function setSectionLoading() {
  return dispatch => {
    dispatch({
      type: SET_SECTION_LOADING,
      payload: { loading: true },
    });
  };
}

function setSectionList(sections) {
  return dispatch => {
    dispatch({
      type: SET_SECTION_LIST,
      payload: sections,
    });
  };
}

function loadSectionListError() {
  return dispatch => {
    dispatch({
      type: LOAD_SECTION_LIST_ERROR,
      payload: { errorLoading: true },
    });
  };
}
