export default {

  logo: {
    width: 48
  },

  wrapper: {
    height: 53,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

};
