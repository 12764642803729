import { defaultCyan } from '../App/theme';
import styled from 'styled-components';

export default () => ({
  activeChip: {
    color: 'white',
    backgroundColor: `${defaultCyan} !important`
  },
  inactiveChip: {
    backgroundColor: 'transparent !important',
    border: '.8px solid #363753',
  },
});

export const Title = styled.div`
  font-size: .8rem;
  font-weight: 600;
  color: #363753;
`;