import { closeUpDarkRed } from '../App/theme';

export const activeLink = {
  backgroundColor: closeUpDarkRed.listBackground,
  color: '#FFF'
};

export default (theme) => ({
  DrawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  linksContainer: {
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: 0,
  },

  listItemRoot: {
    color: 'inherit',
    fontSize: '10px',
  },
  arrowSub: {
    color: '#F7F7F7',
    fontSize: '40px',
    position: 'absolute',
    top: '15px',
    right: '-17px',
  },

  listItem: {
    display: 'inline-block',
    padding: 0,
    textTransform: 'Uppercase',
    fontSize: '10px',
    cursor: 'pointer'
  },

  listItemIcon: {
    display: 'inline-block',
    padding: 0,
    textTransform: 'Uppercase',
    fontSize: '10px',
    cursor: 'pointer'
  },

  listIcon: {
    marginRight: 0,
    color: '#fff',
    fontSize: 35,
  },

  activeLink: activeLink,

  gutters: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
  },

  DataI: {
    backgroundColor: "#dedede",
  },

  ToolsContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
  },

  listTool: {
    display: 'inline-block',
    padding: 0,
    cursor: 'pointer',
  },

  listToolIcon: {
    marginRight: 0,
    color: '#fff',
    fontSize: 35,
  },
  bgIcon: {
    padding: '10px',
    marginBottom: '15px',
    display: 'block',
    textAlign: 'center'
  },

  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    paddingLeft: '0.8rem'
  },
});
