import { SET_ROUTE_LIST, SET_ROUTE_LOADING, LOAD_ROUTE_LIST_ERROR } from '../constants/actions';
import { doGet } from '../utils/http_functions';
import { urlParam } from '../utils/misc';

function getRoutesWithHostileZone(routes, hostileZones) {
  let newRoutes = [];
  let hostileZone = {};
  let hostileZoneRoutesByKey = {};

  if (hostileZones && hostileZones.length) {
    hostileZone = hostileZones[0];
  }

  if (!hostileZone.route || !hostileZone.route.length) {
    return routes;
  }

  hostileZone.route.forEach(item => {
    hostileZoneRoutesByKey[item.value] = item;
  });

  routes.forEach(route => {
    let label = route.label;
    let isDisabled = false;

    if (hostileZoneRoutesByKey[route.value]) {
      isDisabled = true;
      label = `${route.label} (Em zona hostil)`;
    }

    newRoutes.push({ ...route, isDisabled, label });
  });

  return newRoutes;
}

export function getRoutes({ filter, is_filter_hostile_zone }) {
  return (dispatch, getState) => {
    dispatch(setRouteLoading());
    doGet(`${process.env.REACT_APP_BACK_URL}/api/routes?${urlParam({ filter })}`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              if (is_filter_hostile_zone) {
                json = getRoutesWithHostileZone(json, getState().hostile_zone.data);
                dispatch(setRouteList(json));
              } else {
                dispatch(setRouteList(json));
              }
            })
            .catch(e => {
              dispatch(loadSectionListError(`Error parsing route: ${e.message}`));
            });
        } else {
          dispatch(loadSectionListError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(loadSectionListError(`Error list routes: ${e.message}`));
      });
  };
}

function setRouteLoading() {
  return dispatch => {
    dispatch({
      type: SET_ROUTE_LOADING,
      payload: { loading: true },
    });
  };
}

function setRouteList(routes) {
  return dispatch => {
    dispatch({
      type: SET_ROUTE_LIST,
      payload: routes,
    });
  };
}

function loadSectionListError() {
  return dispatch => {
    dispatch({
      type: LOAD_ROUTE_LIST_ERROR,
      payload: { errorLoading: true },
    });
  };
}
