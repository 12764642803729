import {
  SET_LINKS,
  LINKS_UPDATE_REQUEST,
  LINKS_UPDATE_DONE,
} from '../constants/actions';

export default function (state = {
  links: [],
  loading: true,
  error: false,
  errorMsg: null,
}, action) {
  switch (action.type) {
    case SET_LINKS:
      return { ...state, links: action.payload };
    case LINKS_UPDATE_REQUEST:
      return { ...state, loading: true };
    case LINKS_UPDATE_DONE:
      if (action.payload.error)
        console.log(action.payload.errorMsg);
      return { ...state, ...action.payload };
    default:
      return state;
  }
}