import { GET_PERFORMANCE_REQUEST, GET_PERFORMANCE_SUCCESS, GET_PERFORMANCE_ERROR } from '../constants/actions';

const INITIAL_STATE = {
  data: [],
  meta: null,
  loading: false,
  error: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PERFORMANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PERFORMANCE_SUCCESS:
      return {
        ...action.payload,
        loading: false,
        error: false,
      };
    case GET_PERFORMANCE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
