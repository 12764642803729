export default () => ({
  title: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  container: {
    backgroundColor: '#fff',
    padding: '1rem',
    height: '41.6rem',
    paddingLeft: '2rem',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)'
  },
  msgEmptyState: {
    fontSize: 12,
    textAlign: 'center'
  },
});
