import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import logo from '../../assets/logo-scora-acqua.png';
import {
    Container, OptionsTextContainer,
    OptionsContainer, LogoContainer,
    OptionsText, Title, ChangeCardContainer,
    Version, MonitoringCardContainer,
    OptionsCardText, AnalysisCardContainer,
    HostileZoneCardContainer
} from './Home.styles';
import DropletIcon from 'react-feather/dist/icons/droplet';
import PerformanceIcon from 'react-feather/dist/icons/activity';
import { setRankingParams } from '../../actions/rankings';
import { setDialog } from '../../actions/dialog';
import BaseFormDialog from '../BaseFormDialog/BaseFormDialog';
import HostileZoneIcon from 'react-feather/dist/icons/alert-triangle';
import FolderIcon from 'react-feather/dist/icons/folder';
import * as constants from '../../constants/routes';

class Home extends Component {

    onSubmit = (params) => {
        this.props.setRankingParams(params);
        this.props.history.push({ pathname: params.path });
    }

    onClick = (action, event) => {
        this.props.setDialog({ field: action, open: true });
    }

    goToScreen = (pathname) => {
        this.props.history.push({ pathname });
    }

    render() {
        return (
            <Fragment>
                <Container>
                    <LogoContainer>
                        <img src={logo} alt="" style={{ maxWidth: '10vw' }}/>
                        <Title>Scora Acqua Inteligencia Artificial para Micromedição</Title>
                        <Version>Versão 2.0.0</Version>
                    </LogoContainer>

                    <OptionsTextContainer>
                        <OptionsText>ACOMPANHE AS SUAS ANÁLISES</OptionsText>
                    </OptionsTextContainer>

                    <OptionsContainer>
                        <ChangeCardContainer onClick={this.onClick.bind(this, 'isRankingForm')}>
                            <div><DropletIcon size={32} /></div>
                            <OptionsCardText>Substituição de hidrômetros</OptionsCardText>
                        </ChangeCardContainer>

                        <MonitoringCardContainer onClick={this.onClick.bind(this, 'isPerformanceForm')}>
                            <div><PerformanceIcon size={32} /></div>
                            <OptionsCardText>Monitoramento de perfomance das trocas</OptionsCardText>
                        </MonitoringCardContainer>
                    </OptionsContainer>

                    <OptionsContainer style={{ paddingLeft: '1vw', paddingTop: '1vw' }}>
                        <AnalysisCardContainer onClick={this.goToScreen.bind(this, constants.ANALYSIS)}>
                            <div><FolderIcon size={32} /></div>
                            <OptionsCardText>Análises Salvas</OptionsCardText>
                        </AnalysisCardContainer>

                        <HostileZoneCardContainer onClick={this.goToScreen.bind(this, constants.HOSTILE_ZONE)}>
                            <div><HostileZoneIcon size={32} /></div>
                            <OptionsCardText>Zonas Hostis</OptionsCardText>
                        </HostileZoneCardContainer>
                    </OptionsContainer>
                </Container>
                <BaseFormDialog onSubmit={(params) => this.onSubmit(params)} />
            </Fragment>
        )
    }
}

const mapStateToProps = ({ dialog }) => ({ dialog });

export default connect(
    mapStateToProps,
    { setRankingParams, setDialog }
)(Home);
