import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { setMenu } from '../../actions/menu';
import { Menu, MenuItem } from '@material-ui/core';

class MenuActions extends Component {

  handleMenuItemClose = () => this.props.setMenu({ anchorEl: null, actions: [], meta: null });

  handleMenuItemClick = (item, meta, event) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(item, meta);
    }

    this.handleMenuItemClose();
  };

  render() {
    const { anchorEl, actions, meta } = this.props.menu;

    if (!actions || !actions.length || !anchorEl) return null;

    return (
      <Menu
        style={{ list: { paddingTop: 0, paddingBottom: 0 } }}
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={this.handleMenuItemClose}
      >
        {actions.map((item, index) => (
          <Fragment key={index}>
            <MenuItem
              style={{ display: 'flex', alignItems: 'center' }}
              selected={false}
              onClick={this.handleMenuItemClick.bind(this, item, meta)}
            >
              {item.icon}&nbsp;&nbsp;{item.label}
            </MenuItem>
            {index + 1 < actions.length ? <hr style={{ border: '1px dashed #CCCCCC' }} /> : null}
          </Fragment>
        ))}
      </Menu>
    );
  }
}

const mapStateToProps = ({ menu }) => ({ menu });

export default connect(mapStateToProps, { setMenu })(MenuActions);