import {
  GET_ANALYSIS_REQUEST,
  GET_ANALYSIS_ERROR,
  GET_ANALYSIS_SUCCESS,
  POST_ANALYSIS_REQUEST,
  POST_ANALYSIS_SUCCESS,
  POST_ANALYSIS_ERROR,
} from '../constants/actions';
import { doPost } from '../utils/http_functions';
import { showMessage } from '../actions/snack';

export function getAnalysis(filter) {
  return dispatch => {
    dispatch(getAnalysisRequest());
    doPost(`${process.env.REACT_APP_BACK_URL}/api/analysis`, {
      paramFILTER: filter,
    })
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(getAnalysisSuccess(json));
            })
            .catch(e => {
              dispatch(getAnalysisError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(getAnalysisError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(getAnalysisError(`Error list analysis: ${e.message}`));
      });
  };
}

export function postAnalysis(filter) {
  return dispatch => {
    dispatch(postAnalysisRequest());
    doPost(`${process.env.REACT_APP_BACK_URL}/api/analysis_save`, { paramFILTER: JSON.stringify(filter) })
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              dispatch(postAnalysisSuccess(json));
              dispatch(showMessage('Análise salva com sucesso!'));
            })
            .catch(e => {
              dispatch(postAnalysisError(`Error parsing ranking: ${e.message}`));
            });
        } else {
          dispatch(postAnalysisError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(postAnalysisError(`Error save analysis: ${e.message}`));
      });
  };
}

/**
 * GET dispatchers
 */

const getAnalysisRequest = () => {
  return dispatch => {
    dispatch({
      type: GET_ANALYSIS_REQUEST,
    });
  };
};

const getAnalysisSuccess = data => {
  return dispatch => {
    dispatch({
      type: GET_ANALYSIS_SUCCESS,
      payload: data,
    });
  };
};

const getAnalysisError = () => {
  return dispatch => {
    dispatch({
      type: GET_ANALYSIS_ERROR,
      payload: { errorLoading: true },
    });
  };
};

/**
 * POST dispatchers
 */

const postAnalysisRequest = () => {
  return dispatch => {
    dispatch({
      type: POST_ANALYSIS_REQUEST,
    });
  };
};

const postAnalysisSuccess = data => {
  return dispatch => {
    dispatch({
      type: POST_ANALYSIS_SUCCESS,
    });
  };
};

const postAnalysisError = () => {
  return dispatch => {
    dispatch({
      type: POST_ANALYSIS_ERROR,
    });
  };
};
