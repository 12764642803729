import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import Routes from '../../routes';
import Footer from '../Footer/Footer';
import LeftDrawer from '../LeftDrawer/LeftDrawer';
import UserMessage from '../SnackBar/SnackBar';
import LdapGroupDialog from '../LdapGroupDialog';
import styles from './App.styles';
import theme from './theme';
import ListSub from '../ListSub/ListSub';
import authLogo from '../../assets/logo-scora-acqua.png';
import { Authenticator, View, Image, Text, Heading, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter/variable.css';
import './App.css';
import { logout } from '../../utils/cognito';
import { login } from '../../actions/login';
import { INVALID_HOSTS, LDAP_IS_MUST_SELECT_GROUP } from '../../constants';
import ForbiddenHost from '../ForbiddenHost/ForbiddenHost';

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image maxWidth={'65%'} alt="Authentication logo" src={authLogo} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral['80']}>&copy; Scora Acqua | Oncase</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Logue na sua conta
        </Heading>
      );
    },
    Footer() {
      return null;
    },
  },
};

const services = {
  async handleSignIn(formData) {
    const { username, password } = formData;

    return login(username, password);
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: 'Usuário',
      isRequired: true,
    },
    password: {
      labelHidden: true,
      placeholder: 'Senha',
      isRequired: true,
    },
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideMenu: false,
    };
  }

  componentDidUpdate() {
    if (localStorage.getItem('tenant') !== process.env.REACT_APP_COGNITO_GROUP) {
      alert('Sua conta não tem acesso ao Scora Acqua. Por favor, entre em contato com o suporte.');
      logout();
    }
  }

  handleChangeSideMenu(e) {
    if (e) {
      let open = this.state.openSideMenu;
      this.setState({ openSideMenu: !open });
    } else {
      this.setState({ openSideMenu: false });
    }
  }

  handleClickOut = e => {
    if (this.listSubNode != null) {
      if (this.listSubNode.contains(e.target)) {
        return;
      }

      this.setState({ openSideMenu: false });
    }
  };

  render() {
    const { classes, drawer, location } = this.props;
    const isValidHost = INVALID_HOSTS.indexOf(window.location.hostname) === -1;

    if (!isValidHost) {
      return (
        <MuiThemeProvider theme={theme}>
          <ForbiddenHost />
        </MuiThemeProvider>
      );
    }

    return (
      <Authenticator
        services={services}
        formFields={formFields}
        components={components}
        loginMechanisms={['username']}
        hideSignUp
      >
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            {localStorage.getItem(LDAP_IS_MUST_SELECT_GROUP) !== 'true' ? (
              <div className={classNames(classes.appFrame, classes.hiddenDrawerBD)}>
                <LeftDrawer
                  openSide={this.handleChangeSideMenu.bind(this)}
                  open={this.state.openSideMenu}
                  drawerRef={el => (this.leftDrawerNode = el)}
                />
                <main
                  className={classNames(classes.content, classes.contentLeft, classes.contentShaddow, {
                    [classes.contentShift]: drawer.open,
                    [classes.contentShiftLeft]: drawer.open,
                  })}
                >
                  <div>
                    {this.state.openSideMenu && (
                      <ListSub
                        listRef={el => (this.listSubNode = el)}
                        openSide={this.handleChangeSideMenu.bind(this)}
                      />
                    )}
                    <div className={classes.contentBody}>
                      <Routes location={location} />
                    </div>
                    <Footer />
                  </div>
                </main>
              </div>
            ) : (
              <LdapGroupDialog />
            )}
          </div>
          <UserMessage />
        </MuiThemeProvider>
      </Authenticator>
    );
  }
}

const mapStateToProps = ({ drawer, title }) => ({ drawer, title });

export default compose(withRouter, withStyles(styles), connect(mapStateToProps))(App);
