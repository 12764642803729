import { createMuiTheme } from '@material-ui/core/styles';

export const filterableColor = '#f8f8f8';
export const measuresColor = 'rgb(240, 224, 224)';
export const lightGrayContent = '#f4f8fc';
export const defaultCyan = '#4dd0d0';
export const defaultBlue = '#5bbefe';
export const defaultGrey = '#576F72';
export const orange = '#FFAE4F';
export const fontFamily = 'Karla,sans-serif';

export const caern = {
  main: '#363753',
  dark: '#900c1f',
  light: '#ff7771',
  contrastText: '#ffffff',
  listBackground: '#d26a6b',
};

export const closeUpDarkRed = {
  main: '#9B3B3B',
  dark: '#900c1f',
  light: '#ff7771',
  contrastText: '#ffffff',
  listBackground: '#9B3B3B',
};

export const closeUpGrey = {
  main: '#9e9e9e',
  dark: '#707070',
  light: '#cfcfcf',
  contrastText: '#ffffff',
};

export const moneySign = {
  fontWeight: 600,
  fontSize: '2rem',
  color: defaultCyan,
};

export const styledTable = {
  tbRow: {
    '& td': {
      backgroundImage: 'linear-gradient(to right, #707070 40%, rgba(255, 255, 255, 0) 0%)',
      backgroundPosition: 'bottom',
      backgroundSize: '.91rem .6px',
      backgroundRepeat: 'repeat-x',
      padding: '10px 20px 10px',
      borderBottom: 'none',
    },
  },
  tbCell: {
    '& th': {
      textAlign: 'initial',
      borderBottom: '2px solid #707070',
      paddingTop: '1.5rem',
      color: '#000',
      fontSize: '.7rem',
      padding: '0 20px 0 20px',
    },
  },
  tbScroll: {
    '& tbody': {
      display: 'block',
      height: 400,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 8,
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#9D9D9D',
        borderRadius: 10,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#E9E9E9',
        borderRadius: 10,
      },
    },
    '& thead ,& tbody > tr': {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
    },
  },
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
    fontFamily: [fontFamily].join(','),
    h3: {
      '&.appTitle': {
        marginBottom: 24,
      },
    },
  },
  palette: {
    primary: caern,
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.compact': {
          height: 32,
        },
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      // '&$selected': {
      //   borderColor: caern.main,
      //   border: '2px solid'
      // },
      root: {
        border: '2px solid rgba(255,255,255,0)',
        borderRadius: 36,
      },
    },
    MuiChip: {
      root: {
        fontWeight: 600,
        borderRadius: '8px',
      },
    },
    MuiButton: {
      contained: {
        '&$containedPrimary': {
          '&:hover': {
            color: '#fff',
          },
        },
      },
    },
    MuiInput: {
      '&:hover': {
        color: '#fff',
      },
    },
  },
});

export default theme;
