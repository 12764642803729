import classNames from 'classnames';
import compose from 'recompose/compose';
import React, { Component } from 'react';
import styles, { Title } from './ViewModeChip.style';
import { withStyles, Grid, Chip } from '@material-ui/core';
import { getAvatar } from '../../utils/misc';
import { GRAPH_PERFORMANCE, GRAPH_RETURN, HYDROMETER_MAP, INDIVIDUAL_USE } from '../../constants';


class ViewModeChip extends Component {

  render() {
    const { classes, viewMode, params, isPerformance } = this.props;

    return (
      <Grid container alignItems="center" style={{ paddingLeft: '2rem' }} justify="flex-start" item xs={6}>
        <Grid item style={{ paddingRight: '.5rem' }}>
          <Title>Visualização:</Title>
        </Grid>
        {params.investiment > 0 && isPerformance !== true ?
          <Grid item style={{ paddingRight: '.5rem' }}>
            <Chip
              avatar={getAvatar(GRAPH_RETURN, viewMode)}
              label="Retorno de Investimento"
              className={classNames(classes.chip, viewMode === GRAPH_RETURN ? classes.activeChip : classes.inactiveChip)}
              onClick={this.props.handleViewMode.bind(this, GRAPH_RETURN)}
            />
          </Grid>
          : null}
        {isPerformance !== true ?
          <Grid item style={{ paddingRight: '.5rem' }}>
            <Chip
              avatar={getAvatar(HYDROMETER_MAP, viewMode)}
              label="Mapa de Hidrômetros"
              className={classNames(classes.chip, viewMode === HYDROMETER_MAP ? classes.activeChip : classes.inactiveChip)}
              onClick={this.props.handleViewMode.bind(this, HYDROMETER_MAP)}
            />
          </Grid>
          : null}
        {isPerformance ?
          <Grid item style={{ paddingRight: '.5rem' }}>
            <Chip
              avatar={getAvatar(GRAPH_PERFORMANCE, viewMode)}
              label="Gráfico de Performance"
              className={classNames(classes.chip, viewMode === GRAPH_PERFORMANCE ? classes.activeChip : classes.inactiveChip)}
              onClick={this.props.handleViewMode.bind(this, GRAPH_PERFORMANCE)}
            />
          </Grid>
          : null}
        {isPerformance ?
          <Grid item style={{ paddingRight: '.5rem' }}>
            <Chip
              avatar={getAvatar(INDIVIDUAL_USE, viewMode)}
              label="Consumo Individual"
              className={classNames(classes.chip, viewMode === INDIVIDUAL_USE ? classes.activeChip : classes.inactiveChip)}
              onClick={this.props.handleViewMode.bind(this, INDIVIDUAL_USE)}
            />
          </Grid>
          : null}
      </Grid>
    );
  }
}

export default compose(withStyles(styles))(ViewModeChip);