import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'moment/locale/pt.js';
import moment from 'moment';
import App from './components/App/App';
import { unregister } from './registerServiceWorker';
import configureStore from './store';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import '../node_modules/react-vis/dist/style.css';
import { injectGlobal } from 'styled-components';
import font from './assets/fonts/karla.regular.ttf';
import { Auth } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';
import i18nData from './i18n/pt.json';
import { interceptAuthEvents } from './utils/cognito';

const AMPLIFY_CFG = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    userPoolWebClientId: process.env.REACT_APP_APPCLIENTID,
  },
};

Auth.configure(AMPLIFY_CFG);
I18n.setLanguage('pt');
I18n.putVocabularies({ pt: i18nData });
const store = configureStore();
moment.locale('pt');

interceptAuthEvents();

injectGlobal`
  @font-face {
    font-family: 'Karla';
    src: url(${font}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider>
      <Router>
        <App />
      </Router>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);
unregister();

export { store };
