import {
  YIELD_CURVE_INIT,
  GET_YIELD_CURVE_REQUEST,
  GET_YIELD_CURVE_SUCCESS,
  GET_YIELD_CURVE_ERROR,
} from '../constants/actions';
import { doGet } from '../utils/http_functions';
import { urlParam } from '../utils/misc';

export function getYieldCurve(filter) {
  return dispatch => {
    dispatch(getYieldCurveRequest());
    doGet(`${process.env.REACT_APP_BACK_URL}/api/yield_curve?${urlParam(filter)}`)
      .then(res => {
        if (res.ok) {
          res
            .json()
            .then(json => {
              if (json && json.length) json = json[0];
              dispatch(getYieldCurveSuccess(json));
            })
            .catch(e => {
              dispatch(getYieldCurveError(`Error parsing yield_curve: ${e.message}`));
            });
        } else {
          dispatch(getYieldCurveError(`Error on the server response`, res));
        }
      })
      .catch(e => {
        dispatch(getYieldCurveError(`Error list yield_curve: ${e.message}`));
      });
  };
}

export function initYieldCurve() {
  return dispatch => {
    dispatch({
      type: YIELD_CURVE_INIT,
    });
  };
}

function getYieldCurveRequest() {
  return dispatch => {
    dispatch({
      type: GET_YIELD_CURVE_REQUEST,
      payload: null,
    });
  };
}

export function getYieldCurveSuccess(yieldCurve) {
  return dispatch => {
    dispatch({
      type: GET_YIELD_CURVE_SUCCESS,
      payload: yieldCurve,
    });
  };
}

function getYieldCurveError(msg) {
  return dispatch => {
    dispatch({
      type: GET_YIELD_CURVE_ERROR,
      payload: { errorLoading: true },
    });
  };
}
