import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { Component } from 'react';
import { BulletList } from 'react-content-loader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import RootRef from '@material-ui/core/RootRef';
import { loadDashboards } from '../../actions/links';
import styles from './LisSub.styles';


class ListSub extends Component {
  state = { open: true };

  render() {
    const { classes, listRef } = this.props;
    if (this.props.loading === true) {
      return <BulletList />
    }
    return (
      <RootRef rootRef={listRef}>
        <List component="nav" classes={{ root: classes.SubContainer }}>
        </List >
      </RootRef>
    );
  }
}


const mapStateToProps = ({ links, reports, products }) => ({ links, reports, products });

export default withRouter(compose(
  withStyles(styles),
  connect(mapStateToProps, { loadDashboards })
)(ListSub));
