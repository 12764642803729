export default () => ({
  tableRow: {
    fontSize: '.9rem'
  },
  tableCell: {
    fontSize: '.9rem',
    fontWeight: 'bold'
  },
  rootChip: {
    borderRadius: 32 / 2
  },
  chipBlue: {
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: '500',
    backgroundColor: '#5bbefe'
  },
  chipRed: {
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: '500',
    backgroundColor: '#e24453'
  },
  paginationCaption: {
    fontSize: '.8rem'
  },
  paginationToolbar: {
    fontSize: '.8rem',
    marginLeft: '2rem'
  },
  paginationSelectIcon: {
    right: '-4px'
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      cursor: 'pointer'
    }
  }
});