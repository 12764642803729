import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component, Fragment } from 'react';
import styles from './AnalysisList.style';
import {
  withStyles,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import _orderBy from 'lodash/orderBy';
import * as moment from 'moment';
import { formatMoney, getMonthYearFormatted, getStrFromArray } from '../../utils/misc';
import CheckIcon from 'react-feather/dist/icons/check';
import ClockIcon from 'react-feather/dist/icons/clock';
import MoreIcon from 'react-feather/dist/icons/more-horizontal';
import MenuActions from '../MenuActions/MenuActions';
import { setMenu } from '../../actions/menu';
import { setRankingParams } from '../../actions/rankings';
import PerformanceIcon from 'react-feather/dist/icons/activity';
import { PERFORMANCE } from '../../constants/routes';

class AnalysisList extends Component {
  state = {
    page: 0,
    orderBy: 'createdAt',
    order: 'desc',
    rowsPerPage: 10,
    currentList: [],
    paginatedList: [],
  };

  componentDidMount() {
    const { data } = this.props.analysis;

    this.updatePaginatedListAfterSlide(data);
  }

  updatePaginatedListAfterSlide(data) {
    const { page, rowsPerPage } = this.state;
    const paginatedList = data.slice(page, rowsPerPage);
    this.setState({ currentList: data, paginatedList });
  }

  initPagination(data) {
    const { rowsPerPage } = this.state;
    const paginatedList = data.slice(0, rowsPerPage);

    this.setState({ page: 0, paginatedList });
  }

  handleChangePage = (event, page) => {
    const { currentList, rowsPerPage } = this.state;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ page, paginatedList });
  };

  handleChangeRowsPerPage = event => {
    const { page, currentList } = this.state;
    const rowsPerPage = event.target.value;
    const offset = page * rowsPerPage;
    const paginatedList = currentList.slice(offset, offset + rowsPerPage);

    this.setState({ paginatedList, rowsPerPage });
  };

  handleRequestSort = property => {
    const { order, orderBy, currentList, page, rowsPerPage } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    const offset = page * rowsPerPage;

    const newCurrentList = _orderBy(currentList, property, newOrder);
    const newPaginatedList = newCurrentList.slice(offset, offset + rowsPerPage);

    this.setState({
      order: newOrder,
      orderBy: property,
      currentList: newCurrentList,
      paginatedList: newPaginatedList,
    });
  };

  getSortLabel = (property, label) => {
    const { order, orderBy } = this.state;

    return (
      <TableSortLabel
        active={orderBy === property}
        direction={order}
        onClick={this.handleRequestSort.bind(this, property)}
      >
        {label}
      </TableSortLabel>
    );
  };

  getTableHeadRow() {
    const { classes } = this.props;

    return (
      <TableRow>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('createdAt', 'Data')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('location', 'Localidade')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('section', 'Setor')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('maxRanking', 'Hd. Total')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('qtdHidrSuggestion', 'Hd. Sugeridos')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('qtdHidrChanged', 'Hd. Trocados')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('totalInvestiment', 'Investido')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('totalBilled', 'Faturado')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('totalGain', 'Ganho')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          {this.getSortLabel('status', 'Status')}
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          <div className={classes.actionsContainer}>Ações</div>
        </TableCell>
      </TableRow>
    );
  }

  getStatus = status => {
    let Icon = <CheckIcon size={16} color="#3CC863" />;

    if (status === 'Em análise') {
      Icon = <ClockIcon size={16} color="#FFAE4F" />;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ paddingTop: '.2rem' }}>{Icon}</div>
        <div style={{ paddingLeft: '.2rem' }}>{status}</div>
      </div>
    );
  };

  getTableRow(data) {
    const { classes } = this.props;

    return data.map((row, i) => (
      <TableRow
        key={i}
        style={i % 2 ? { background: '#F9F9F9' } : { background: 'white' }}
        className={classes.tableRow}
      >
        <TableCell padding="checkbox" className={classes.tableRow}>
          {moment(row.createdAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.location}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {Array.isArray(row.section) ? getStrFromArray(row.section) : row.section}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.maxRanking}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.qtdHidrSuggestion}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {row.qtdHidrChanged}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          R$ {formatMoney(row.totalInvestiment)}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          R$ {formatMoney(row.totalBilled)}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          R$ {formatMoney(row.totalGain)}
        </TableCell>
        <TableCell padding="none" className={classes.tableRow}>
          {this.getStatus(row.status)}
        </TableCell>
        <TableCell
          padding="none"
          onClick={row.qtdHidrChanged > 0 && this.showMenuActions.bind(this, row)}
          className={classes.tableRow}
        >
          <div className={classes.actionsContainer}>{row.qtdHidrChanged > 0 && <MoreIcon />}</div>
        </TableCell>
      </TableRow>
    ));
  }

  showMenuActions = (row, e) => {
    const payload = {
      anchorEl: e.currentTarget,
      meta: row,
      actions: [
        {
          type: 'performance',
          label: 'Acompanhar performance destas trocas',
          icon: <PerformanceIcon size={16} />,
        },
      ],
    };

    this.props.setMenu(payload);
  };

  onClickMenuAction = (res, meta) => {
    if (res && res.type === 'performance' && meta && meta.changes) {
      const performanceMonthYear = [{ label: getMonthYearFormatted(meta.monthYear), value: meta.monthYear }];

      this.props.setRankingParams({
        capacity: meta.capacity && meta.capacity.length ? meta.capacity : '',
        location: meta.location,
        locationId: meta.locationId,
        path: PERFORMANCE,
        section: !meta.section
          ? ''
          : Array.isArray(meta.section)
          ? meta.section
          : [{ label: meta.section, value: meta.sectionId }],
        performanceMonthYear,
      });
      this.props.history.push({ pathname: PERFORMANCE });
    }
  };

  render() {
    const { classes } = this.props;
    const { page, rowsPerPage, paginatedList, currentList } = this.state;

    return (
      <Fragment>
        <Table>
          <TableHead>{this.getTableHeadRow()}</TableHead>
          <TableBody>{this.getTableRow(paginatedList)}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={currentList.length}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                labelRowsPerPage="Linhas por página:"
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                classes={{
                  caption: classes.paginationCaption,
                  toolbar: classes.paginationToolbar,
                  selectIcon: classes.paginationSelectIcon,
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <MenuActions onClick={(res, meta) => this.onClickMenuAction(res, meta)} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ menu, analysis }) => ({ menu, analysis });

export default compose(withStyles(styles), connect(mapStateToProps, { setMenu, setRankingParams }))(AnalysisList);
