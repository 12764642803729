import { Hub, Auth } from 'aws-amplify';
import { getUserData } from './misc';

export const interceptAuthEvents = () => {
  const listener = async data => {
    switch (data.payload.event) {
      case 'signIn':
        const userData = await getUserData();
        localStorage.setItem('tenant', userData.tenant);
        break;
      case 'signUp':
        console.info('user signed up');
        break;
      case 'signOut':
        console.info('user signed out');
        break;
      case 'signIn_failure':
        console.error('user sign in failed');
        break;
      case 'tokenRefresh':
        console.info('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        console.error('token refresh failed');
        break;
      case 'configured':
        console.info('the Auth module is configured');
        break;
      default:
        break;
    }
  };

  Hub.listen('auth', listener);
};

export async function logout() {
  window.history.pushState({}, null, '/');
  window.location.reload();
  localStorage.clear();
  await Auth.signOut();
}