import { caern } from '../App/theme';

import { drawerWidth  } from '../App/App.styles';

export default (theme) => ({
  drawerPaper: {
    position: 'fixed',
    width: drawerWidth,
    height: '100%',

    color: '#fff',
    fontSize: 12,
    fontWeight: 300,
    padding: '0',

    //TODO: incluir suporte aos outros browsers. deu problema com repeated-keys (varios background) - ver link
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#330060+0,26273b+100 */
    backgroundColor: caern.main, /* Old browsers */
    // background: 'linear-gradient(to bottom, #330060 0%,#26273b 100%)', /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  },

  closeupHead: {
    width: 120,
    marginLeft: 10,
  },

});