import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../constants/actions';
import uuid from 'uuid/v4';

export function showMessage(message) {
  return (dispatch) => {
    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        key: uuid(),
        message,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
    });
  };
}

export const enqueueSnackbar = notification => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: uuid(),
    ...notification,
  },
});

export function closeMessage(key) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_SNACKBAR,
      key
    });
  };
}