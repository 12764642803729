import { defaultCyan, defaultGrey } from '../App/theme';

export default () => ({
  list: {
    backgroundColor: '#fff',
    padding: '1rem',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
  },
  container: {
    marginTop: '2rem',
  },
  locationInput: {
    padding: 12,
    fontSize: 14,
    width: '100%',
  },
  progress: {
    marginLeft: '50%',
    marginTop: '20%',
    color: defaultCyan,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#2C4E77',
  },
  noDataMsg: {
    marginLeft: '9%',
    marginTop: '18%',
    fontSize: '28px',
    color: defaultGrey,
  },
});
