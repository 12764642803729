import classNames from 'classnames';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import React, { Component, Fragment } from 'react';
import styles from './HydrometerList.style';
import { getYieldCurve, initYieldCurve } from '../../actions/yield_curve';
import {
  getCapacityParsed, getTaxParsed,
  getScoreFormatted, getPaybackFormatted, isValidPayback
} from '../../utils/misc';
import {
  withStyles, Chip, TableHead, TableRow, TableCell, Table, TableBody,
  TableFooter, TablePagination, TableSortLabel, Typography, DialogTitle, DialogContent, Grid, Dialog
} from '@material-ui/core';
import AlertCircleIcon from 'react-feather/dist/icons/alert-circle';

class HydrometerList extends Component {

  state = {
    openErrors: false
  }

  getSortLabel = (property, label) => {
    const { order, orderBy } = this.props;
    return (
      <TableSortLabel
        active={orderBy === property}
        direction={order}
        onClick={this.props.handleRequestSort.bind(this, property)}>
        {label}
      </TableSortLabel>
    )
  }

  componentDidMount() {
    const { params } = this.props;

    this.props.initYieldCurve();

    if (params.priorization === 'Standard') {
      this.handleYieldCurve();
    }
  }

  handleDialogErrors() {
    this.setState({ openErrors: !this.state.openErrors });
  }

  getDialogErrors = () => {
    const { openErrors } = this.state;
    const { classes, taxErrorBilled, totalTaxErrorBilled, taxErrorModel, totalTaxErrorModel } = this.props;

    if (!openErrors) {
      return;
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openErrors}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => this.setState({ openErrors: false })}
      >
        <DialogTitle id="simple-dialog-title"><b>Análise de Erros</b></DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" justify="center" direction="column">
            <Grid item>
              <Typography title="Erro de projeção consumo faturado" className={classes.taxErrorLabel}>Erro de consumo faturado: <span className={classes.taxErrorValue}>{taxErrorBilled}</span></Typography>
            </Grid>
            <Grid item>
              <Typography title="Erro total de projeção consumo faturado" className={classes.taxErrorLabel}>Erro total de consumo faturado: <span className={classes.taxErrorValue}>{totalTaxErrorBilled}</span></Typography>
            </Grid>
            <Grid item>
              <Typography title="Erro de estimativa do consumo esperado" className={classes.taxErrorLabel}>Erro modelo: <span className={classes.taxErrorValue}>{taxErrorModel}</span></Typography>
            </Grid>
            <Grid item>
              <Typography title="Erro total de estimativa do consumo esperado" className={classes.taxErrorLabel}>Erro total modelo: <span className={classes.taxErrorValue}>{totalTaxErrorModel}</span></Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  handleYieldCurve(item) {
    const { params, yield_curve } = this.props;
    const perfilId = item && item.perfil_id ? item.perfil_id : null;
    const id = params.priorization === 'Standard' ? params.locationId.toString() : perfilId;
    let query = { id, tipo: params.priorization.toLowerCase() };

    //Caching Strategy
    if (yield_curve && yield_curve.item && yield_curve.item.id === perfilId && params.priorization === 'Perfil') {
      return;
    }

    query = JSON.stringify(query);
    this.props.getYieldCurve({ query });
  }

  getTableHeadRow() {
    const { params, classes } = this.props;

    if (params.rankingBy === 'score') {
      return (
        <TableRow>
          <TableCell padding="none" className={classes.tableCell}>{this.getSortLabel('score', 'Score')}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('imov_id', 'Matrícula')}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableCell} title="Idade (em anos)">{this.getSortLabel('age_year', 'Idade')}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('income_rate', 'Rendimento')}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('capacity', 'Capacidade')}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('reason', 'Motivador')}</TableCell>
          {params.investiment > 0 ? <TableCell padding="none" align="right" className={classes.tableCell} title="Payback (em meses)">{this.getSortLabel('payback', 'Payback')}</TableCell> : null}
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell padding="none" className={classes.tableCell} title="Payback (em meses)">{this.getSortLabel('payback', 'Payback')}</TableCell>
        <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('imov_id', 'Matrícula')}</TableCell>
        <TableCell padding="none" align="right" className={classes.tableCell} title="Idade (em anos)">{this.getSortLabel('age_year', 'Idade')}</TableCell>
        <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('income_rate', 'Rendimento')}</TableCell>
        <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('capacity', 'Capacidade')}</TableCell>
        <TableCell padding="none" align="right" className={classes.tableCell}>{this.getSortLabel('score', 'Score')}</TableCell>
      </TableRow>
    );
  }

  getTableRow(list) {
    const { params, classes } = this.props;
    const tableRowStyle = { cursor: params.priorization === 'Perfil' ? 'pointer' : 'default' };

    if (params.rankingBy === 'score') {
      return (
        list.map((row, i) => (
          <TableRow key={i}
            className={classes.tableRow}
            onClick={params.priorization === 'Perfil' ? this.handleYieldCurve.bind(this, row) : null}
            style={{ ...tableRowStyle, background: i % 2 ? "#F9F9F9" : "white" }}>
            <TableCell padding="none" className={classes.tableRow} component="th" scope="row">
              <Chip
                classes={{
                  root: classes.rootChip,
                }}
                label={getScoreFormatted(row.score)}
                className={classNames(row.score ? classes.chipBlue : classes.chipRed)}>
              </Chip>
            </TableCell>
            <TableCell padding="none" align="right" className={classes.tableRow}>{row.imov_id}</TableCell>
            <TableCell padding="none" align="right" className={classes.tableRow}>{row.age_year}</TableCell>
            <TableCell padding="none" align="right" className={classes.tableRow}>{getTaxParsed(row.income_rate)}</TableCell>
            <TableCell padding="none" align="right" className={classes.tableRow}>{getCapacityParsed(row.capacity)}</TableCell>
            <TableCell padding="none" align="right" className={classes.tableRow}>{row.reason}</TableCell>
            {params.investiment > 0 ? <TableCell padding="none" align="right" className={classes.tableRow}>{getPaybackFormatted(row.payback)}</TableCell> : null}
          </TableRow>
        ))
      )
    }

    return (
      list.map((row, i) => (
        <TableRow key={i}
          onClick={params.priorization === 'Perfil' ? this.handleYieldCurve.bind(this, row) : null}
          style={{ ...tableRowStyle, background: i % 2 ? "#F9F9F9" : "white" }}>
          <TableCell padding="none" className={classes.tableRow} component="th" scope="row">
            <Chip
              classes={{
                root: classes.rootChip,
              }}
              label={getPaybackFormatted(row.payback)}
              className={classNames(isValidPayback(row.payback) && row.payback > 0 ? classes.chipBlue : classes.chipRed)}>
            </Chip>
          </TableCell>
          <TableCell padding="none" align="right" className={classes.tableRow}>{row.imov_id}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableRow}>{row.age_year}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableRow}>{getTaxParsed(row.income_rate)}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableRow}>{getCapacityParsed(row.capacity)}</TableCell>
          <TableCell padding="none" align="right" className={classes.tableRow}>{row.score}</TableCell>
        </TableRow>
      ))
    )
  }

  render() {
    const { openErrors } = this.state;
    const { classes, page, rowsPerPage, paginatedList, currentList } = this.props;

    return (
      <Fragment>
        <Table>
          <TableHead>
            {this.getTableHeadRow()}
          </TableHead>
          <TableBody>
            {this.getTableRow(paginatedList)}
          </TableBody>
          <TableFooter>
            <TableCell
              colSpan="2"
              padding="none"
              className={classes.cellErrorAnalysis}
              onClick={this.handleDialogErrors.bind(this)}>
              <div style={{ display: 'flex' }}>
                <AlertCircleIcon color="gray" size={16} />
                <div style={{ fontSize: '.8rem', paddingLeft: '.2rem' }}>Análise de Erros</div>
              </div>
            </TableCell>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={currentList.length}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              labelRowsPerPage="Linhas por página:"
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.props.handleChangePage}
              onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              classes={{ caption: classes.paginationCaption, toolbar: classes.paginationToolbar, selectIcon: classes.paginationSelectIcon }}
            />

          </TableFooter>
        </Table>
        {openErrors && this.getDialogErrors()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ yield_curve }) => ({ yield_curve });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getYieldCurve, initYieldCurve })
)(HydrometerList);