import styled, { css } from 'styled-components';

const opacity = .4;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2C4E77;
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  
  ${props => props.loading === true && css`
    opacity: ${opacity};
  `}
`;

export const HeaderActionText = styled.div`
  padding-right: 2rem;
  padding-left: .5rem;
  font-weight: 600;

  ${props => props.loading === true && css`
      cursor: default;
      text-decoration: none;
  `}

  ${props => props.loading !== true && css`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
  `}
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 1.2rem;

    ${props => props.loading === true && css`
    opacity: ${opacity};
  `}
`;

export const ParamTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #2C4E77;
  padding-top: 3vh;
  padding-bottom: 2vh;

  ${props => props.loading === true && css`
    opacity: ${opacity};
  `}
`;

export const ParamText = styled.div`
  font-weight: 500;
  padding-top: 1vh;
  padding-right: 2rem;
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ParamTextValue = styled.span`
  font-weight: 600;
  max-width: .1vw;
`;